import React, { useEffect, useRef, useState, useContext } from "react";
import PdfBotContent from "./components/PdfBotContent/PdfBotContent";
import { v4 as uuidv4 } from "uuid";
import { Base_Url } from "../../Utils/urls";
import { AuthContext } from "../../Context/AuthContext";
import { TourContext } from "../../Context/TourContext";

export default function ExpandedView() {
  const { authenticated, user, getToken } = useContext(AuthContext);
  const { setCurrentView, tourOpen } = useContext(TourContext);

  useEffect(() => {
    setCurrentView(localStorage.getItem("tourFromSmView") ? 1 : 2);
    localStorage.removeItem("tourFromSmView");
  }, []);

  return (
    <div
      style={
        tourOpen
          ? { height: "calc(100vh - 0.3rem)", width: "calc(100vw - 0.3rem)" }
          : { height: "100vh", width: "100vw" }
      }
    >
      {
        authenticated &&
        (
          <PdfBotContent />
        )}
    </div>
  );
}
