import { useState, useEffect, useRef } from "react";
import "./FilterOption.css";
import Checkbox from "../Checkbox/Checkbox";

export default function FilterOption({
  filterKey,
  options,
  selectedOptions,
  onOptionChange,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const childRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (optionId) => {
    const selectedOption = options.find((option) => option.id === optionId);
    onOptionChange(filterKey, selectedOption);
  };

  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener("click", closeDropdown);
    }

    const handleResize = () => {
      // Calculate the offset between the viewport's top edge and the parent div
      if (childRef.current) {
        const parentRect = dropdownRef.current.getBoundingClientRect();
        const childRect = childRef.current.getBoundingClientRect();
        const viewportRect = document
          .querySelector(".App")
          .getBoundingClientRect();
        const offset = parentRect.top;

        if (childRect.top < viewportRect.top + 55) {
          childRef.current.style.top = `${viewportRect.top + 55 - offset}px`;
          childRef.current.style.bottom = "unset";
        }
      }
    };
    handleResize();

    return () => {
      window.removeEventListener("click", closeDropdown);
    };
  }, [isOpen]);

  return (
    <div className="filter-option" ref={dropdownRef}>
      <div
        className={
          isOpen
            ? "filter-option-header filter-option-header-selected"
            : "filter-option-header"
        }
        onClick={(e) => {
          handleToggle();
        }}
      >
        <div className="filter-option-text">
          <div>Select {filterKey}</div>
          {selectedOptions?.length !== 0 && (
            <div className="individual-option-no">
              {selectedOptions?.length}
            </div>
          )}
        </div>
        <div className="filter-right-arrow">
          <img src="./assets/rightArrow.svg" alt="" />
        </div>
      </div>
      {isOpen && (
        <div className="filter-options-2" ref={childRef}>
          <div style={{ height: "max-content" }}>
            {options.map((option) => (
              <Checkbox
                key={option.id}
                label={option.value}
                checked={selectedOptions.some(
                  (selected) => selected.id === option.id
                )}
                onChange={() => handleOptionClick(option.id)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
