import React from 'react'
import "./ScenariosTable.css";

export default function ScenariosTable() {
    const headerOptions = ["Name", "Description"];
    const trainings = [
        {
          name: "a",
          description: "ab",
        },
        {
          name: "a",
          description: "ab",
        },
      ];
    
    return (
      <div className="contents-table-box">
        <div className="table-header">
          <span className="header-options w-50">{headerOptions[0]}</span>
          <span className="header-options w-50">{headerOptions[1]}</span>
        </div>
        {trainings.map((item) => {
        return (
          <div className="table-content">
            <span className="content-options w-50">
              {/* <a href={item?.link} target="_blank" className="link-name"> */}
              {item?.name}
              {/* </a> */}
            </span>
            <span className="content-options w-50">{item?.description}</span>
          </div>
        );
      })}
      </div>
    );}
