import React from "react";
import "./Navbar.css";

export default function Navbar() {
  return (
    <div className="navbar">
      <img src="/assets/supertutor-icon2.svg" alt="" className="d-logo"/>
      <span className="super-tutor-logo">Super Tutor</span>
    </div>
  );
}
