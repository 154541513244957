import React, { useContext, useState } from "react";
import "./AddEditUser.css";
import {
  null_entries_text,
  submit_text,
} from "../../../../../../constants/GlobalConstants";
import { message } from "antd";
import { AuthContext } from "../../../../../../Context/AuthContext";
import { Base_Url_Admin } from "../../../../../../Utils/urls";
import AdminModal from "../../../../Components/AdminModal/AdminModal";

export default function AddEditUser({
  selectedUser,
  getUsers,
  setEditViewToggle,
  setDeleteTraining,
}) {
  const [formData, setFormData] = useState({
    username: selectedUser?.name ?? "",
    email: selectedUser?.user_id ?? "",
    team: selectedUser?.team ?? "",
    role:
      selectedUser?.role !== "admin" ? "Admin management" : "Site management",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const { getToken } = useContext(AuthContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleValidation = (e) => {
    e.preventDefault();
    if (isLoading) return;
    else setIsLoading(true);
    let payload = {
      user_id: formData?.email,
      name: formData?.username,
      team: formData?.team,
      role: formData?.role === "Admin management" ? "superadmin" : "admin",
    };
    for (const [key, value] of Object.entries(formData)) {
      if (key === "team") continue;
      if (value.length === 0) {
        message.error(null_entries_text);
        setOpenModal({});
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
        return;
      }
    }
    setOpenModal({
      content: submit_text,
      func: () => handleSubmit(payload),
    });
  };

  const handleSubmit = async (payload) => {
    try {
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/admin_management`, {
        method: selectedUser ? "PATCH" : "POST",
        headers: {
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify(payload),
      });
      let result = await response.json();
      // console.log("res:", result, response);
      if (response.ok) {
        messageApi.open({
          type: "success",
          content: result?.message,
        });
        setTimeout(() => {
          // After the operation is complete, set loading state back to false
          setOpenModal({});
          setIsLoading(false);
          getUsers();
          setEditViewToggle(0);
        }, 1500);
      } else {
        messageApi.open({
          type: "error",
          content: result?.message,
        });
        setOpenModal({});
        setIsLoading(false);
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="upload-heading-box">
        <div className="left-side-heading">
          <span className="upload-content">Admin role management</span>
          <span className="sub-content">
            {selectedUser
              ? "Update the rights available to an administrator through this section."
              : "Assign admin rights to a user through this section"}
          </span>
        </div>
        {selectedUser && (
          <div
            className="add-new-button"
            onClick={() => {
              setDeleteTraining(selectedUser?.user_id);
            }}
          >
            Remove access
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="upload-form-box">
          <div className="form-row">
            <div className="form-group-col-3">
              <label className="center-align">
                <img src="/assets/_.svg" />
                &nbsp;User Name
              </label>
              <input
                type="text"
                // disabled={showContent?.length !== 0 && !isEditContent}
                className="input-box-form"
                name="username"
                value={formData?.username}
                onChange={handleChange}
              />
              <span className="conven-text">
                Comma separated as per official Deloitte convention
              </span>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group-col-1">
              <label className="center-align">
                <img src="/assets/_.svg" />
                &nbsp;Email ID
              </label>
              <input
                type="text"
                // disabled={showTraining && !isEditTraining}
                // placeholder="example"
                className="input-box-form"
                name="email"
                value={formData.email}
                disabled={selectedUser !== null}
                onChange={handleChange}
              />
            </div>
            <div className="form-group-col-1">
              <label className="center-align">
                {/* <img src="/assets/_.svg" /> */}
                Team Name
              </label>
              <input
                type="text"
                // disabled={showTraining && !isEditTraining}
                className="input-box-form link"
                name="team"
                value={formData.team}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group-col-3">
              <div className="center-align">Access</div>
              <div>
                <label
                  className={
                    formData.role === "Site management"
                      ? "radio-label selected"
                      : "radio-label"
                  }
                >
                  <input
                    type="radio"
                    name="role"
                    value="Site management"
                    checked={formData.role === "Site management"}
                    onChange={handleChange}
                  />
                  Site management
                </label>
                <label
                  className={
                    formData.role === "Admin management"
                      ? "radio-label selected"
                      : "radio-label"
                  }
                >
                  <input
                    type="radio"
                    name="role"
                    value="Admin management"
                    checked={formData.role === "Admin management"}
                    onChange={handleChange}
                  />
                  Site and admin management
                </label>
                <div className="radio-circle"></div>{" "}
                {/* This will be styled with CSS */}
              </div>
            </div>
          </div>
        </div>
        <div className="submit-box">
          <button
            type="submit"
            className={isLoading ? "submit-button cursor-n" : "submit-button"}
            onClick={(e) => {
              handleValidation(e);
            }}
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      </form>
      <AdminModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </>
  );
}
