import React from "react";
import ReactPlayer from "react-player";
import "./VideoPlayer.css";
export default function VideoPlayer() {
  const file = "https://www.youtube.com/watch?v=G2fqAlgmoPo";
  return (
    <ReactPlayer
      url={file}
      controls={true}
      width={"100%"}
      height={"100%"}
      config={{
        youtube: {
          playerVars: { showinfo: 1 },
        },
      }}
    />
  );
}
