import React, { useContext } from "react";
import "./PdfSection.css";
import { useEffect, useState, useRef } from "react";
import { Progress, Spin } from "antd";
import * as pdfjs from "pdfjs-dist/build/pdf";
import { Base_Url } from "../../../../Utils/urls";
import { AuthContext } from "../../../../Context/AuthContext";
import EmptyPdfSection from "../EmptyPdfSection/EmptyPdfSection";
import { TourContext } from "../../../../Context/TourContext";
import { pdf_summary_dummy } from "../../../../constants/GlobalConstants";
import { PdfContext } from "../../../../Context/PdfContext";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function PdfSection({ questionsAvailable }) {
  const { getToken } = useContext(AuthContext);

  const {
    currentIndex,
    setCurrentIndex,
    summary,
    file,
    setFile,
    pdfInitialized,
    setPdfInitialized,
    disabledLinks,
    setDisabledLinks,
  } = useContext(PdfContext);

  const { tourOpen, expTourRef2, expTourRef3 } = useContext(TourContext);
  const [numberPages, setNumberPages] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const canvasRef = useRef(null);
  const renderingTaskRef = useRef(null);
  const [pdf, setPdf] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [portraitPage, setPortraitPage] = useState(false);

  const fileUrl = useRef(file);
  const scrollingDivRef = useRef(null);

  const scrollToTop = () => {
    if (scrollingDivRef.current) {
      scrollingDivRef.current.scrollTop = 0;
    }
  };

  function isSasUrlExpired(url) {
    const queryString = url.split("?")[1];
    const queryParams = new URLSearchParams(queryString);

    const requiredParams = ["sv", "srt", "sp", "se", "ss", "sig"];
    for (const param of requiredParams) {
      if (!queryParams.has(param)) {
        return true; // Set to true: Request URL again because it is invalid
      }
    }

    const expirationTimeStr = queryParams.get("se");
    const expirationTime = new Date(expirationTimeStr);

    // Check if the SAS URL has expired
    const currentTime = new Date();
    if (currentTime < expirationTime) {
      return false;
    }

    return true;
  }

  const getPdfUrl = async () => {
    try {
      let payload = {
        url: file,
      };
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url}/get_sas_url`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify(payload),
      });
      if (response.status !== 200) return;
      let jsonData = await response.json();

      localStorage.setItem("pdf", jsonData?.url);
      fileUrl.current = jsonData?.url;
    } catch (e) {
      console.log("error:", e);
    }
  };

  useEffect(() => {
    if (file) {
      fileUrl.current = file;
      setPageNum(currentIndex + 1);
      const renderPdf = async () => {
        try {
          const loadingTask = pdfjs.getDocument(fileUrl.current);
          let pdfTemp = await loadingTask.promise;
          setPdf(pdfTemp);
          setNumberPages(pdfTemp.numPages);
          renderPage(pdfTemp, currentIndex + 1);
        } catch (err) {
          if (err instanceof pdfjs.MissingPDFException) {
            console.log("Error: Missing pdf");
            setDisabledLinks([...disabledLinks, file]);
            setFile(null);
            localStorage.removeItem("filepath");
            localStorage.removeItem("pdf");
          }
        }
      };
      // if (isSasUrlExpired(file)) {
      //   console.log("expired");
      //   getPdfUrl().then((result) => {
      //     renderPdf();
      //   });
      // } else {
      //   console.log("This");
      //   renderPdf();
      // }
      getPdfUrl().then((result) => {
        renderPdf();
      });
    }
    return () => {
      setPdfInitialized(false);
      setPortraitPage(false);
    };
  }, [file]);

  useEffect(() => {
    if (pdfInitialized) {
      renderPage(pdf, pageNum);
    }

    return () => {
      setPortraitPage(false);
      scrollToTop();
    };
  }, [pageNum]);

  useEffect(() => {
    if (pdfInitialized && pageNum !== currentIndex + 1) {
      setPageNum(currentIndex + 1);
    }
  }, [currentIndex]);

  const renderPage = async (pdf, pageNum) => {
    const page = await pdf.getPage(pageNum);
    const scale = 1.5;
    const viewport = page.getViewport({ scale });

    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      if (viewport.width < viewport.height) {
        setPortraitPage(true);
      }

      if (renderingTaskRef.current !== null) {
        try {
          renderingTaskRef.current.cancel();
        } catch (error) {
          console.error("Error while canceling rendering:", error);
        }
      }

      renderingTaskRef.current = page.render({
        canvasContext: context,
        viewport: viewport,
      });
      try {
        await renderingTaskRef.current.promise;
      } catch (error) {
        console.error("Error while rendering:", error);
      }
      setPdfInitialized(true);
    }
  };

  const showPrevPage = () => {
    let currentPage = pageNum;
    if (currentPage <= 1) {
      return;
    }
    setPageNum(currentPage - 1);
    setCurrentIndex(currentPage - 2); // Additional -1 for zero-based indexing
  };
  const showNextPage = () => {
    let currentPage = pageNum;
    if (currentPage >= numberPages) {
      return;
    }
    setPageNum(currentPage + 1);
    setCurrentIndex(currentPage); // Additional -1 for zero-based indexing
  };

  const handleDownload = async () => {
    try {
      setDownloading(true);

      const pdfNameEncoded = fileUrl.current.split("?")[0].split("/").pop();
      const pdfNameDecoded = decodeURIComponent(pdfNameEncoded);
      const response = await fetch(fileUrl.current);
      const totalSize = Number(response.headers.get("content-length"));
      const reader = response.body.getReader();
      let downloaded = 0;
      let chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        downloaded += value.length;
        setDownloadProgress((downloaded / totalSize) * 100);
      }

      const blob = new Blob(chunks);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = pdfNameDecoded; // Change the file name as needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setDownloading(false);
    }
  };

  let isPdfVisible = tourOpen || file;
  let contentInitialized = tourOpen || pdfInitialized;
  let summaryVisible = tourOpen ? pdf_summary_dummy : summary;
  let currentIndexVisible = tourOpen ? 0 : currentIndex;

  return (
    <div className="pdf-section-main-box">
      {isPdfVisible ? (
        <>
          <div className="pdf-view-box">
            {tourOpen && (
              <img
                src="./assets/tour.png"
                alt=""
                style={{ height: "100%", width: "100%" }}
                ref={expTourRef2}
              />
            )}
            <div
              className="pdf-page-wrapper"
              ref={scrollingDivRef}
              style={
                tourOpen
                  ? { height: "0" }
                  : portraitPage
                  ? { height: "100%", overflowY: "auto" }
                  : pdfInitialized
                  ? { height: "100%" }
                  : {}
              }
            >
              <canvas
                id="pdf-canvas"
                ref={canvasRef}
                className="pdf-page"
                style={
                  tourOpen
                    ? { height: "0" }
                    : portraitPage
                    ? { height: "initial" }
                    : pdfInitialized
                    ? { height: "100%" }
                    : {}
                }
              ></canvas>
            </div>
            {!contentInitialized ? (
              <Spin
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            ) : (
              <div className="pdf-action-bar">
                <button
                  className={
                    downloading
                      ? "pdf-download-button cursor-n"
                      : "pdf-download-button"
                  }
                  onClick={handleDownload}
                  disabled={downloading}
                >
                  {!downloading ? (
                    <img src="/assets/download.svg" alt="" />
                  ) : (
                    <Progress
                      type="circle"
                      percent={Math.round(downloadProgress)}
                      size={20}
                      strokeColor="#056B39"
                      trailColor="#F6F6F6"
                    />
                  )}
                </button>
                <div className="pdf-slide-bar">
                  <div
                    className={`navigate-pdf-icon ${
                      pageNum <= 1 ? "cursor-n" : ""
                    }`}
                    onClick={showPrevPage}
                  >
                    <img src="./assets/BackBtn.png" alt="" />
                  </div>
                  <div className="slide-number">
                    {currentIndexVisible + 1} of {tourOpen ? 26 : numberPages}{" "}
                  </div>
                  <div
                    className={`navigate-pdf-icon ${
                      pageNum >= numberPages ? "cursor-n" : ""
                    }`}
                    onClick={showNextPage}
                  >
                    <img src="./assets/NextBtn.png" alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
          {contentInitialized && summaryVisible.length !== 0 && (
            <div className="pdf-summary-section" ref={expTourRef3}>
              <div className="pdf-summary-left-section">
                <img src="./assets/supertutor-icon.png" />
              </div>
              <div className="pdf-summary-right-section">
                <div className="summary-box-filter-top">
                  <div className="summary-box-tip-arrow"></div>
                  <span className="summary-box-filter-heading">
                    Slide Summary:
                  </span>
                </div>
                <div className="summary-box-filter-content">
                  {summaryVisible?.map((item, index) => (
                    <>{currentIndexVisible === index && <span>{item}</span>}</>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <EmptyPdfSection
          text={
            questionsAvailable
              ? "I am always happy to share additional information! Please access the respective resource links to get additional information and context. Click the source link to help me load the document."
              : "I am excited to help you. Follow the instructions on the right to ask me anything about GenAI."
          }
        />
      )}
    </div>
  );
}
