import React, { useContext } from "react";
import "./TrainingTable.css";
import { Tooltip } from "antd";
import { Maximized_View_Url } from "../../../../Utils/urls";
import { TourContext } from "../../../../Context/TourContext";
import { useAppUtilities } from "../../../../Hooks/useAppUtilities";
import { TrainingsContext } from "../../../../Context/TrainingsContext";
import { FilterContext } from "../../../../Context/FilterContext";

export default function TrainingTable({
  data,
  table_heading,
  setFilters,
  retainContext,
  modSearchButtonVisible,
  modSearchButtonDisabled,
  trainingKey,
  index,
}) {
  const { smallTourRef6 } = useContext(TourContext);

  const { filterIdMapping } = useContext(FilterContext);

  const { setRetainContext } = useContext(TrainingsContext);

  const { user_interaction } = useAppUtilities();

  let trainingTypeKey = "Training Type",
    proficiencyKey = "Proficiency",
    regionKey = "Region";

  Object.entries(filterIdMapping).map(([key, value], index) => {
    if (value.hasOwnProperty("isTrainingType")) {
      trainingTypeKey = key;
    } else if (value.hasOwnProperty("isProficiency")) {
      proficiencyKey = key;
    } else if (value.hasOwnProperty("isRegion")) {
      regionKey = key;
    }
  });

  let tableContent = data?.length > 4 ? data.slice(0, 4) : data;
  const handleFilterSetup = () => {
    if (setFilters) {
      localStorage.setItem("trainingkey", trainingKey);
    }
  };

  let trainingOtherInfo = data?.map((item, index) => {
    let trainingOtherInfoTemp = [];
    if (item?.duration.trim().length !== 0) {
      trainingOtherInfoTemp.push(item?.duration);
    }
    if (item?.filters[trainingTypeKey].length > 0) {
      trainingOtherInfoTemp.push(
        item?.filters[trainingTypeKey]?.map((obj) => obj.value).join(", ")
      );
    }
    if (item?.filters[proficiencyKey].length > 0) {
      trainingOtherInfoTemp.push(
        item?.filters[proficiencyKey]?.map((obj) => obj.value).join(", ")
      );
    }
    return trainingOtherInfoTemp;
  });

  return (
    <>
      <div
        className="training-list-container"
        ref={index === 0 ? smallTourRef6 : null}
      >
        <div className="training-list-heading">{table_heading}</div>
        {data && (
          <div className="training-list">
            {tableContent.map((item, index) => (
              <div className="training-list-items">
                <div
                  className="training-item-link-info"
                  style={item?.name.length > 45 ? { maxWidth: "19rem" } : {}}
                >
                  <a
                    href={item?.link}
                    target="_blank"
                    onClick={() => user_interaction(item?.id, {})}
                  >
                    <span className="training-item-link">{item?.name}</span>
                    <Tooltip
                      overlayClassName="training-table-tooltip"
                      title={
                        <div className="tooltip-box-content">
                          <div className="sub-box-tootip">
                            <span className="sub-heading-tooltip">
                              Description:{" "}
                            </span>
                            <span>{item?.description}</span>
                          </div>
                          {item?.filters[regionKey].length > 0 && (
                            <div className="prof-reg-box">
                              <div className="sub-box-tootip-1">
                                <span className="sub-heading-tooltip">
                                  Region:
                                </span>
                                <span>
                                  {item?.filters[regionKey]
                                    ?.map((obj) => obj.value)
                                    .join(", ")}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                      color="#444444"
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        <span>
                          &nbsp;
                          <img src="./assets/info.svg" alt="" />
                          &nbsp;
                        </span>
                      </span>
                    </Tooltip>
                  </a>
                </div>
                <div className="training-item-other-info">
                  {trainingOtherInfo[index]?.map((info, i) => (
                    <>
                      <div>{info}</div>
                      {i !== trainingOtherInfo[index].length - 1 && (
                        <div className="training-items-vertical-separator-expanded"></div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        {data && data.length > 4 && (
          <div className="see-more-pos">
            <Tooltip
              placement="topLeft"
              overlayClassName="header-tooltips-fixed-width-2"
              title={
                <div className="header-tooltips">
                  Click here to view the recommended resources in expanded
                  chatbot view.
                </div>
              }
              color="#444444"
            >
              <a
                style={{ textDecoration: "none" }}
                href={Maximized_View_Url}
                target="_top"
                rel="noreferrer"
              >
                <span className="view-all-button" onClick={handleFilterSetup}>
                  View all
                </span>
              </a>
            </Tooltip>
          </div>
        )}
        {modSearchButtonVisible && (
          <div
            className={
              (retainContext
                ? "retain-context retain-context-selected"
                : "retain-context") +
              (modSearchButtonDisabled ? " mod-search-disabled" : "")
            }
            onClick={() => {
              setRetainContext(!retainContext);
            }}
          >
            Click here to refine the current preferences
          </div>
        )}
      </div>
    </>
  );
}
