import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./DatePickCustom.css";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
let defaultDate = [];
export default function DatePickCustom({
  selectedYear,
  setSelectedDates,
  currentYear,
}) {
  const [status, setStatus] = useState("");
  const disabledDate = (current) => {
    const startDate = dayjs(selectedYear?.start_date);
    const endDate = dayjs(selectedYear?.end_date);
    return (
      current &&
      (current.isBefore(startDate) || current.isAfter(endDate)) &&
      !current.isSame(startDate, "day") &&
      !current.isSame(endDate, "day")
    );
  };
  const handleChange = (e) => {
    if (!e) {
      setSelectedDates({
        start_date: selectedYear?.start_date,
        end_date: selectedYear?.end_date,
      });
      defaultDate = [];
      return;
    }
    setSelectedDates({
      start_date: e[0].format("YYYY-MM-DD"),
      end_date: e[1].format("YYYY-MM-DD"),
    });
    defaultDate = [e[0], e[1]];
    return;
  };
  useEffect(() => {
    if (currentYear !== selectedYear?.value) {
      defaultDate = [];
      currentYear = selectedYear?.value;
    }
  }, [selectedYear]);

  const validateDates = (e) => {
    let [startDate, endDate] = e;
    startDate = dayjs(startDate);
    endDate = dayjs(endDate);

    const currDate = dayjs();
    const fyStartDate = dayjs(selectedYear?.start_date);

    let validDate = true;
    if (
      startDate &&
      (startDate.isBefore(fyStartDate) || startDate.isAfter(currDate))
    ) {
      validDate = false;
      defaultDate = [];
    }

    if (endDate && (endDate.isBefore(startDate) || endDate.isAfter(currDate))) {
      validDate = false;
      defaultDate = [e[0], null];
    }
    if (validDate) {
      setStatus("");
    } else {
      setStatus("error");
    }
  };
  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        value={defaultDate}
        onChange={handleChange}
        disabledDate={disabledDate}
        className="date-range-picker"
        dropdownClassName="date-range-picker"
        disabled={selectedYear === undefined}
        format="YYYY-MM-DD"
        placeholder={["Start Date", "End Date"]}
        allowClear
        style={{
          height: "2.5rem",
        }}
        placement="bottomRight"
        status={status}
        onCalendarChange={(e) => validateDates(e)}
      />
    </Space>
  );
}
