import React, { useState } from "react";
import "./ConfigAdminsTable.css";

export default function ConfigAdminsTable({
  trainings,
  setEditViewToggle,
  setDeleteTraining,
  setSelectedUser,
}) {
  const headerOptions = ["Name", "Email", "Role", "Action"];
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="contents-table-box">
      <div className="table-header">
        <span className="header-options w-30">{headerOptions[0]}</span>
        <span className="header-options w-30">{headerOptions[1]}</span>
        <span className="header-options w-30">{headerOptions[2]}</span>
        <span className="header-options w-10">{headerOptions[3]}</span>
      </div>
      {trainings.map((item) => {
        return (
          <div className="table-content">
            <span className="content-options w-30">{item?.name}</span>
            <span className="content-options w-30 admin-email">
              {item?.user_id}
            </span>
            <span className="content-options w-30">
              <span
                className={
                  item?.role !== "admin" ? "admin-role" : "manage-role"
                }
              >
                {item?.role !== "admin"
                  ? "Site and admin management"
                  : "Site management"}
              </span>
            </span>
            <span className="content-options w-10">
              <img
                src="/assets/Edit.svg"
                className="icon-sizing"
                onClick={() => {
                  setEditViewToggle(2);
                  setSelectedUser(item);
                }}
              />
              <img
                src="/assets/DeleteOutlined.svg"
                className="icon-sizing"
                onClick={() => setDeleteTraining(item?.user_id)}
              />
            </span>
          </div>
        );
      })}
    </div>
  );
}
