import React from "react";
import "./TrendingLearningCard.css";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  cards_description,
  empty_text,
} from "../../../../constants/GlobalConstants";
import { Tooltip } from "antd";
export default function TrendingLearningCard({ data }) {
  return (
    <>
      <div className="filter-usage-header">
        <div className="card-header-box">
          <span className="filter-usage-heading">Trending Learning Areas</span>
          <Tooltip
            placement="bottomLeft"
            title={
              <div className="card-tooltip-container">
                {/* <span className="card-container-heading">
                  Trending Learning Areas:
                </span>
                &nbsp; */}
                {cards_description.trending_learning}
              </div>
            }
            color="#FFFFFF"
          >
            <img
              alt=""
              src="/assets/new-info.svg"
              className="card-info-icon-sizing"
            />
          </Tooltip>
        </div>
      </div>
      <div className="trending-card-content">
        {data.length === 0 ? (
          <div className="empty-data-img-box">
            <img
              src="/assets/trending.svg"
              className="trending-empty-image"
              alt=""
            />
            <span className="empty-text">{empty_text}</span>
          </div>
        ) : (
          <>
            <div className="header-trending-card">
              <span>Keywords</span>
              <span>Count</span>
            </div>
            {data.slice(0,26).map((item) => {
              return (
                <div className="phrase-container">
                  <span className="phrase-content">{item?.phrase}</span>
                  <div className="count-container">
                    <span className="count-text">{item?.total_count}</span>
                    <div className="deviation-container">
                      {item?.deviation < 0 ? (
                        <ArrowDownOutlined className="down-arrow-color" />
                      ) : (
                        <ArrowUpOutlined className="up-arrow-color" />
                      )}
                      <span
                        className={
                          item.deviation < 0
                            ? "down-arrow-color"
                            : "up-arrow-color"
                        }
                      >
                        {Math.abs(item?.deviation)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
}
