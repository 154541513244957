import React, { useContext, useEffect, useRef, useState } from "react";
import "./EditCategory.css";
import {
  CloseOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { AutoComplete, Input, Modal, message } from "antd";
import Item from "antd/es/list/Item";
import { AuthContext } from "../../../../Context/AuthContext";
import { Base_Url_Admin } from "../../../../Utils/urls";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  category_des,
  category_guidelines,
  containsAlphabetsOrNumbers,
  handleValidationSelfAsses,
  null_entries_text,
  option_guidelines,
  options_desc,
  questions_des,
  questions_guidelines,
  resource_links_error_msg,
  submit_text,
} from "../../../../constants/GlobalConstants";
import AdminModal from "../AdminModal/AdminModal";
import SelfAssessTooltip from "../SelfAssessTooltip/SelfAssessTooltip";

export default function EditCategory({
  setEditCategory,
  categoryInfo,
  questionsInfo,
  trainingLinks,
  formatted_list_trainings,
  getAssessmentData,
  setDeleteCategory,
}) {
  const [options, setOptions] = useState([]);
  const [category, setCategory] = useState(categoryInfo);
  const [questions2, setQuestions2] = useState([...questionsInfo?.data]);
  const { getToken } = useContext(AuthContext);
  const [categoryLinkInput, setCategoryLinkInput] = useState("");
  const [linkInput, setLinkInput] = useState({});
  const [isLoading1, setIsLoading1] = useState(false);
  const [openModal, setOpenModal] = useState({});
  const [value, setValue] = useState(
    questionsInfo?.message ? questionsInfo?.message : ""
  );
  const [messageApi, contextHolder] = message.useMessage();
  const handleSearch = (value) => {
    if (value.trim() === "") {
      setOptions([]); // Clear options when there is no text
      return;
    }
    const filteredOptions = trainingLinks
      .filter((entry) => entry.name.toLowerCase().includes(value.toLowerCase()))
      .slice(0, 5);
    let fil_options = filteredOptions.map((entry) => ({
      value: entry.id,
      label: entry.name,
    }));
    setOptions(fil_options);
  };
  const onSelect = (value) => {
    setCategoryLinkInput("");
    let isAdded = false;
    if (category?.cat_trainings.length !== 0) {
      isAdded = category?.cat_trainings.some((item) => item.id === value);
    }
    if (isAdded) {
      messageApi.open({
        type: "error",
        content: resource_links_error_msg,
      });
      return;
    }
    let new_links =
      category?.cat_trainings.length !== 0
        ? [
            ...category.cat_trainings,
            { ...formatted_list_trainings[value], id: value },
          ]
        : [{ ...formatted_list_trainings[value], id: value }];
    setCategory({ ...category, cat_trainings: new_links });
    setOptions([]);
  };
  const handleQuestionText = (index, value) => {
    let new_questions = [...questions2];
    new_questions[index].question = value;
    setQuestions2(new_questions);
  };
  const handleAddQuestions = () => {
    setQuestions2([
      ...questions2,
      { question: "", options: [], question_id: "" },
    ]);
  };
  const handleCategoryName = (value) => {
    setCategory({ ...category, name: value });
  };
  const handleRemoveLink = (value) => {
    let current_links = category.cat_trainings.filter(
      (item) => item.name !== value
    );
    setCategory({ ...category, cat_trainings: current_links });
  };
  const handleAddOptions = (value) => {
    let new_options = [...questions2];
    new_options[value].options.push({ text: "", opt_trainings: [] });
    setQuestions2(new_options);
  };
  const handleOptionName = (index, index2, value) => {
    let new_questions = [...questions2];
    new_questions[index].options[index2].text = value;
    setQuestions2(new_questions);
  };
  const onSelectOptions = (e, index, index2) => {
    let new_options = [...questions2];
    let input = { ...linkInput };
    let x = {};
    x[index2] = "";
    input[index] = x;
    setLinkInput(input);
    let isAdded = false;
    if (new_options[index].options[index2].opt_trainings?.length !== 0) {
      isAdded = new_options[index].options[index2].opt_trainings.some(
        (item) => item.id === e
      );
    }
    if (isAdded) {
      messageApi.open({
        type: "error",
        content: resource_links_error_msg,
      });
      return;
    }
    new_options[index].options[index2].opt_trainings.push({
      ...formatted_list_trainings[e],
      id: e,
    });
    setQuestions2(new_options);
    setOptions([]);
  };
  const handleRemoveOptionsLink = (value, index, index2) => {
    let filterd_item = [...questions2];
    filterd_item[index].options[index2].opt_trainings = filterd_item[
      index
    ].options[index2].opt_trainings.filter((item) => item.name !== value.name);
    setQuestions2(filterd_item);
  };

  const handleSaveEditCategory = async (payload) => {
    try {
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/resource_skill_assessment`,
        {
          method: "PATCH",
          headers: {
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      if (response.ok) {
        messageApi.open({
          type: "success",
          content: result?.message,
        });
        setTimeout(() => {
          setOpenModal({});
          setIsLoading1(false);
          getAssessmentData();
        }, 1500);
      } else {
        messageApi.open({
          type: "error",
          content: result?.errors[0],
        });
        setOpenModal({});
        setIsLoading1(false);
      }
    } catch (e) {
      console.log("error:", e);
    }
  };
  const ValidationCheck = () => {
    if (isLoading1) return;
    else setIsLoading1(true);
    let editedQuestions = questions2.map((item) => {
      let new_options = item.options.map((item) => {
        return {
          option_id: item.id === undefined ? "" : item.id,
          option_text: item.text,
          option_links: item.opt_trainings.map((item) => item.id),
        };
      });
      return {
        question_id: item.question_id,
        name: item.question,
        options: [...new_options],
      };
    });
    let payload = {
      resource_id: category.id,
      name: category.name,
      links: category.cat_trainings.map((item) => item.id),
      questions: editedQuestions,
      readOnly: false,
      description: category?.description,
    };
    let isValid = handleValidationSelfAsses(payload);
    if (isValid === -1 || !containsAlphabetsOrNumbers(category.description)) {
      message.error(null_entries_text);
      setOpenModal({});
      setTimeout(() => {
        setIsLoading1(false);
      }, 3000);
      return;
    }
    setOpenModal({
      content: submit_text,
      func: () => handleSaveEditCategory(payload),
    });
  };

  const handleClose = () => {
    getAssessmentData();
    setQuestions2([]);
    setCategory({});
    setEditCategory("");
  };

  const handleDeleteQuestion = (value) => {
    setOpenModal({});
    let new_questions = [...questions2];
    new_questions = new_questions.filter((item, index) => index !== value);
    setQuestions2(new_questions);
    return;
  };

  const handleDeleteOption = (index, index2) => {
    setOpenModal({});
    const new_questions = [...questions2];
    const edited_options = new_questions[index].options.filter(
      (item, idx) => idx !== index2
    );
    let new_edited_questions = [];
    new_questions.map((item, idk) => {
      if (idk === index) {
        let x = {};
        x.question = item.question;
        x.question_id = item.question_id;
        x.options = edited_options;
        new_edited_questions.push(x);
      } else {
        new_edited_questions.push(item);
      }
    });
    setQuestions2(new_edited_questions);
    return;
  };

  const handleLinkInputUpdate = (value, index, index2) => {
    let input = { ...linkInput };
    let x = {};
    x[index2] = value;
    input[index] = x;
    setLinkInput(input);
    return;
  };
  const handleCategoryDescription = (value) => {
    setCategory({ ...category, description: value });
  };

  const handleSaveTextArea = async () => {
    try {
      if (isLoading1) return;
      else setIsLoading1(true);
      if (
        !containsAlphabetsOrNumbers(value) ||
        value.length === 0 ||
        !containsAlphabetsOrNumbers(category?.description)
      ) {
        message.error(null_entries_text);
        setTimeout(() => {
          setIsLoading1(false);
        }, 2000);
        return;
      }
      let payload = {
        name: category.name,
        links: [],
        questions: [],
        readOnly: true,
        message: value,
        resource_id: category.id,
        description: category?.description,
      };
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/resource_skill_assessment`,
        {
          method: "PATCH",
          headers: {
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      if (response.ok) {
        messageApi.open({
          type: "success",
          content: result?.message,
        });
        setTimeout(() => {
          setOpenModal({});
          setIsLoading1(false);
          getAssessmentData();
        }, 1500);
      } else {
        messageApi.open({
          type: "error",
          content: result?.errors[0],
        });
        setOpenModal({});
        setIsLoading1(false);
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };
  if (questionsInfo.isReadOnly) {
    return (
      <div className="collapse-style">
        {contextHolder}
        <div className="box-9">
          <span className="self-ass-category-heading">{category.name}</span>
          <div className="collapse-option-icons">
            <img src="/assets/Edit_dis.svg" className="icon-sizing" />
            <img
              src="/assets/collapse-up.svg"
              className="icon-sizing"
              onClick={handleClose}
            />
          </div>
        </div>
        <span className="sub-content mt-8 textarea-view-description">
          This is a pre-defined editable category and contains textual
          information/ links to be displayed to PPMDs.
        </span>
        <div className="disclaimer-add-links-box">
          <div className="enter-category-desc-section">
            <span className="section-text">
              <img src="/assets/_.svg" className="imp-logo-pos" alt="" />
              Category Description
            </span>
            <textarea
              className="category-desc-input"
              value={category?.description ? category?.description : ""}
              onChange={(e) => {
                handleCategoryDescription(e.target.value);
              }}
            />
          </div>
          <span className="text-area-desc-content-self-ass">Enter details</span>
          <ReactQuill value={value} onChange={setValue} />
          <div className="category-section-buttons">
            <div className="close-save">
              <div className="add-new-button" onClick={handleClose}>
                Close
              </div>
              <div
                className="add-category-button"
                onClick={() => {
                  setOpenModal({
                    content: `Review the PPMD site post submitting the details to ensure that your changes are reflecting in the required manner on the site. ${submit_text}`,
                    func: () => handleSaveTextArea(),
                  });
                }}
              >
                Save
              </div>
            </div>
          </div>
        </div>
        <AdminModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          isLoading={isLoading1}
          setIsLoading={setIsLoading1}
        />
      </div>
    );
  }
  return (
    <div className="collapse-add-category-section">
      {contextHolder}
      <div className="description-box-self-ass">
        <div className="add-ctaegory-content-box">
          <div className="self-ass-category-heading">
            Enter category details
            <SelfAssessTooltip
              heading="Guidelines:"
              value={category_guidelines}
            />
          </div>
          <div className="collapse-option-icons">
            <img src="/assets/Edit_dis.svg" className="icon-sizing" />
            <img
              src="/assets/DeleteOutlined.svg"
              className="icon-sizing"
              onClick={() => {
                setDeleteCategory(category.id);
              }}
            />
            <img
              src="/assets/collapse-up.svg"
              className="icon-sizing"
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
      <span className="sub-content mt-8">{category_des}</span>
      <div className="add-ctaegory-content-box">
        <div className="enter-category-section">
          <span className="section-text">
            <img src="/assets/_.svg" className="imp-logo-pos" alt="" />
            Category Name
          </span>
          <input
            value={category?.name ? category.name : ""}
            className="category-name-input"
            // placeholder="Enter category name"
            onChange={(e) => {
              handleCategoryName(e.target.value);
            }}
          />
        </div>
        <div className="enter-category-section">
          <span className="section-text">Category Resource Links</span>
          <AutoComplete
            options={options}
            onSelect={onSelect}
            value={categoryLinkInput}
          >
            <Input
              addonBefore={<SearchOutlined />}
              // placeholder="Search resource links"
              className="category-search-bar"
              onChange={(e) => {
                setCategoryLinkInput(e.target.value);
                handleSearch(e.target.value);
              }}
              allowClear
            />
          </AutoComplete>
        </div>
      </div>
      <div className="enter-category-desc-section">
        <span className="section-text">
          <img src="/assets/_.svg" className="imp-logo-pos" alt="" />
          Category Description
        </span>
        <textarea
          className="category-desc-input"
          // placeholder="Enter category name"
          value={category?.description ? category?.description : ""}
          onChange={(e) => {
            handleCategoryDescription(e.target.value);
          }}
        />
      </div>
      {category?.cat_trainings.length !== 0 && (
        <div className="form-group-col-3">
          <span className="section-text">Selected Resource Links</span>
          <div className="keywords-box">
            {category.cat_trainings.map((item) => {
              return (
                <div className="keyword-batch">
                  <span>
                    <a href={item.link} className="resouce-link-color">
                      {item.name}
                    </a>
                  </span>
                  <CloseOutlined
                    className="cursor-p"
                    onClick={() => {
                      handleRemoveLink(item.name);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="category-section-buttons">
        <div className="add-new-button" onClick={handleAddQuestions}>
          Add a question
        </div>
      </div>
      {questions2?.length !== 0 &&
        questions2?.map((item, index) => {
          return (
            <div className="collapse-add-question-section">
              <div className="description-box-self-ass">
                <div className="add-ctaegory-content-box">
                  <div className="self-ass-category-heading">
                    Enter question details
                    <SelfAssessTooltip
                      heading="Guidelines:"
                      value={questions_guidelines}
                    />
                  </div>
                  <div className="collapse-option-icons">
                    <img
                      src="/assets/DeleteOutlined.svg"
                      className="icon-sizing"
                      onClick={() => {
                        setOpenModal({
                          content:
                            "Are you sure you want to delete this question?",
                          func: () => handleDeleteQuestion(index),
                        });
                      }}
                    />
                  </div>
                </div>
                <span className="sub-content mt-8">{questions_des}</span>
              </div>
              <div className="add-question-content-box">
                <div className="enter-question-section">
                  <span className="section-text">
                    <img src="/assets/_.svg" className="imp-logo-pos" alt="" />
                    Question
                  </span>
                  <input
                    value={item?.question ? item.question : ""}
                    className="category-name-input"
                    // placeholder="Enter question"
                    onChange={(e) => {
                      handleQuestionText(index, e.target.value);
                    }}
                  />
                </div>
                <div
                  className="add-new-button"
                  onClick={() => {
                    handleAddOptions(index);
                  }}
                >
                  Add an option
                </div>
              </div>

              {item?.options.length !== 0 && (
                <div className="questions-option-main-box">
                  <div className="description-box-self-ass">
                    <div className="add-ctaegory-content-box">
                      <div className="self-ass-category-heading">
                        Enter option details
                        <SelfAssessTooltip
                          heading="Guidelines:"
                          value={option_guidelines}
                        />
                      </div>
                    </div>
                    <span className="sub-content mt-8">{options_desc}</span>
                  </div>
                  {item?.options.map((item, index2) => {
                    return (
                      <>
                        <div className="options-divider">
                          <div className="options-divider-shade" />
                          <span className="options-text">
                            Option {index2 + 1}
                          </span>
                          <div className="options-divider-shade" />
                          <img
                            src="/assets/DeleteOutlined.svg"
                            className="icon-sizing ml-14"
                            onClick={() => {
                              setOpenModal({
                                content:
                                  "Are you sure you want to delete this option?",
                                func: () => handleDeleteOption(index, index2),
                              });
                            }}
                          />
                        </div>
                        <div className="add-ctaegory-content-box">
                          <div className="enter-category-section">
                            <span className="section-text">
                              <img
                                src="/assets/_.svg"
                                className="imp-logo-pos"
                                alt=""
                              />
                              Option {index2 + 1}
                            </span>
                            <input
                              value={item.text}
                              className="category-name-input"
                              // placeholder={`Enter option ${index2 + 1}`}
                              onChange={(e) => {
                                handleOptionName(index, index2, e.target.value);
                              }}
                            />
                          </div>
                          <div className="enter-category-section">
                            <span className="section-text">
                              Option Resource Links
                            </span>
                            <AutoComplete
                              options={options}
                              onSelect={(e) => {
                                onSelectOptions(e, index, index2);
                              }}
                              value={
                                linkInput[index]
                                  ? linkInput[index][index2]
                                    ? linkInput[index][index2]
                                    : ""
                                  : " "
                              }
                            >
                              <Input
                                addonBefore={<SearchOutlined />}
                                // placeholder="Search resource links"
                                className="category-search-bar"
                                onChange={(e) => {
                                  handleLinkInputUpdate(
                                    e.target.value,
                                    index,
                                    index2
                                  );
                                  handleSearch(e.target.value);
                                }}
                                allowClear
                              />
                            </AutoComplete>
                          </div>
                        </div>

                        {item.opt_trainings.length !== 0 && (
                          <div className="form-group-col-3">
                            <span className="section-text">
                              Selected Resource Links
                            </span>
                            <div className="keywords-box">
                              {item.opt_trainings.map((item) => {
                                return (
                                  <div className="keyword-batch">
                                    <span>
                                      <a
                                        href={item.link}
                                        className="resouce-link-color"
                                      >
                                        {item.name}
                                      </a>
                                    </span>
                                    <CloseOutlined
                                      className="cursor-p"
                                      onClick={() => {
                                        handleRemoveOptionsLink(
                                          item,
                                          index,
                                          index2
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      <div className="category-section-buttons">
        <div className="close-save">
          <div className="add-new-button" onClick={handleClose}>
            Close
          </div>
          <div
            className={
              isLoading1
                ? "add-category-button cursor-n"
                : "add-category-button"
            }
            onClick={() => {
              ValidationCheck();
            }}
          >
            Save
          </div>
        </div>
      </div>
      <AdminModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        isLoading={isLoading1}
        setIsLoading={setIsLoading1}
      />
    </div>
  );
}
