import React, { useContext, useState } from "react";
import "./Navbar.css";
import { Maximized_View_Url } from "../../../../Utils/urls";
import { Tooltip } from "antd";
import { TourContext } from "../../../../Context/TourContext";
import { AppStateContext } from "../../../../Context/AppStateContext";
import { ConversationContext } from "../../../../Context/ConversationContext";
import { FilterContext } from "../../../../Context/FilterContext";
import { useRecommendedTrainings } from "../../../../Hooks/useRecommendedTrainings";

const tabs = [
  "Conversations",
  "Recommended Resources",
  // "Quiz",
  // "ABCCCCC",
  // "YYYYYYYYY",
];

export default function Navbar({ setShowChatBot, sendMsg, user_interaction }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    tourOpen,
    setTourOpen,
    smallTourRef1,
    smallTourRef2,
    smallTourRef3,
    smallTourRef10,
    smallTourRef11,
  } = useContext(TourContext);

  const { toggleTab, setToggleTab, setPrevValue } = useContext(AppStateContext);
  const { disabledQuestion } = useContext(ConversationContext);
  const { selectedFilters } = useContext(FilterContext);

  const { isFilterEmpty } = useRecommendedTrainings();

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <div className="chat-bot-navbar">
      <div className="chat-bot-links-wrapper">
        <div className="chat-bot-nav-links">
          <Tooltip
            placement="bottomRight"
            overlayClassName="header-tooltips-fixed-width-1"
            title={
              <div className="header-tooltips">
                Click here to share your feedback/suggestions for improving
                SuperTutor.
              </div>
            }
            color="#444444"
          >
            <div
              className="feedback-nav-button"
              onClick={() => {
                setPrevValue(toggleTab);
                setToggleTab(3);
                localStorage.setItem("Toggle", 3);
              }}
              ref={smallTourRef3}
            >
              <div className="feedback-nav-img">
                <img src="./assets/feedback-icon.svg" />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            placement="bottomRight"
            overlayClassName="header-tooltips-fixed-width-1"
            title={
              <div className="header-tooltips">
                {disabledQuestion
                  ? "Please take the tour once the response is generated. You may stop the response generation and navigate immediately."
                  : "Click here to take a tour and explore all the features of Super Tutor."}
              </div>
            }
            color="#444444"
          >
            <button
              className={`tour-nav-button ${
                disabledQuestion ? "cursor-n" : ""
              }`}
              disabled={disabledQuestion}
              onClick={() => {
                setToggleTab(1);
                setTourOpen(true);
              }}
            >
              <div className="tour-nav-img">
                <img src="./assets/guide.svg" />
              </div>
            </button>
          </Tooltip>
          <div className="chat-bot-links-right">
            <Tooltip
              placement="bottomRight"
              overlayClassName="header-tooltips-fixed-width-1"
              title={
                <div className="header-tooltips">
                  {disabledQuestion
                    ? "Please navigate once the response is generated. You may stop the response generation and navigate immediately."
                    : "Click here to open chatbot in expanded view."}
                </div>
              }
              color="#444444"
            >
              <button className="expand-button" disabled={disabledQuestion}>
                {disabledQuestion ? (
                  <div className="cursor-n">
                    <img src="./assets/expand.png" />
                  </div>
                ) : (
                  <a
                    href={Maximized_View_Url}
                    target="_top"
                    ref={smallTourRef10}
                  >
                    <img
                      src="./assets/expand.png"
                      onClick={() => {
                        localStorage.setItem("Toggle", toggleTab);
                        localStorage.setItem("pdf", null);
                        if (!isFilterEmpty()) {
                          localStorage.setItem(
                            "filters",
                            JSON.stringify(selectedFilters)
                          );
                        }
                      }}
                    />
                  </a>
                )}
              </button>
            </Tooltip>
            <Tooltip
              placement="bottomRight"
              overlayClassName="header-tooltips-width"
              title={
                <div className="header-tooltips">
                  Click here to close the chatbot.
                </div>
              }
              color="#444444"
            >
              <div className="minimize-button" ref={smallTourRef11}>
                <img
                  src="./assets/minimize.png"
                  onClick={() => {
                    setShowChatBot(false);
                    sendMsg(80, 176);
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="chat-bot-tabs">
        <ul className="chat-bot-tabs-default">
          <Tooltip
            placement="bottomLeft"
            overlayClassName="header-tooltips-width"
            title={
              <div className="header-tooltips">
                Click here to ask Super Tutor any queries about Gen AI.
              </div>
            }
            color="#444444"
          >
            <li
              className={
                toggleTab === 1
                  ? "chat-bot-tab chat-bot-selected-tab"
                  : "chat-bot-tab"
              }
              onClick={() => {
                setToggleTab(1);
                if (!tourOpen) {
                  user_interaction("", {}, {}, { type: "questions_on_genai" });
                }
                localStorage.setItem("Toggle", 1);
              }}
              ref={smallTourRef1}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tabs[0]}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </li>
          </Tooltip>
          <Tooltip
            placement="bottomRight"
            overlayClassName="header-tooltips-fixed-width-2"
            title={
              <div className="header-tooltips">
                Click here to identify trainings based on your learning profile.
              </div>
            }
            color="#444444"
          >
            <li
              className={
                toggleTab === 2
                  ? "chat-bot-tab chat-bot-selected-tab"
                  : "chat-bot-tab"
              }
              onClick={() => {
                setToggleTab(2);
                if (!tourOpen) {
                  user_interaction(
                    "",
                    {},
                    {},
                    { type: "recommended_trainings" }
                  );
                }
                localStorage.setItem("Toggle", 2);
              }}
              ref={smallTourRef2}
            >
              {tabs[1]}
            </li>
          </Tooltip>
        </ul>
        {tabs.length > 2 ? (
          <div className="hidden-tabs">
            <div className="hamburger-icon" onClick={toggleDropdown}>
              &#9776; {/* Hamburger icon */}
            </div>
            {showDropdown && (
              <ul className="dropdown">
                {tabs.slice(2).map((tab, index) => (
                  <li key={index}>{tab}</li>
                ))}
              </ul>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
