import React from "react";
import "./TrainingTable.css";

export default function TrainingTable({
  trainings,
  setShowTraining,
  setEditTraining,
  setViewSection,
  setDeleteTraining,
  typeKey,
}) {
  const headerOptions = ["Name", "Description", "Duration", "Type", "Action"];
  return (
    <div className="contents-table-box">
      <div className="table-header">
        <span className="header-options w-26">{headerOptions[0]}</span>
        <span className="header-options w-40">{headerOptions[1]}</span>
        <span className="header-options w-10">{headerOptions[2]}</span>
        <span className="header-options w-10">{headerOptions[3]}</span>
        <span className="header-options w-15">{headerOptions[4]}</span>
      </div>
      {trainings.map((item) => {
        return (
          <div className="table-content">
            <span className="content-options w-26">
              <a href={item?.link} target="_blank" className="link-name">
                {item?.name}
              </a>
            </span>
            <span className="content-options w-40">{item?.description}</span>
            <span
              className={
                item?.duration.trim().length > 3
                  ? "content-options w-10 p-left-9"
                  : "content-options w-10 pad-left"
              }
            >
              {item?.duration.trim().length !== 0
                ? item?.duration.trim()
                : "---"}
            </span>
            <span className="content-options w-10">
              {/* {item?.filters[typeKey][0]?.value} */}
              {item?.filters[typeKey].map((item) => item.value).join(", ")}
            </span>
            <span className="content-options w-15">
              <img
                src="/assets/file.svg"
                className="icon-sizing"
                onClick={() => {
                  setShowTraining(item);
                  setViewSection(true);
                }}
              />
              <img
                src="/assets/Edit.svg"
                className="icon-sizing"
                onClick={() => {
                  setShowTraining(item);
                  setEditTraining(true);
                  setViewSection(true);
                }}
              />
              <img
                src="/assets/DeleteOutlined.svg"
                className="icon-sizing"
                onClick={() => setDeleteTraining(item?.id)}
              />
            </span>
          </div>
        );
      })}
    </div>
  );
}
