import React, { useContext, useEffect, useRef, useState } from "react";
import "./Feedback.css";
import { CloseOutlined } from "@ant-design/icons";
import FeedbackQuestion from "../FeedbackQuestion/FeedbackQuestion";
import { Base_Url } from "../../../../Utils/urls";
import { Spin } from "antd";
import { AuthContext } from "../../../../Context/AuthContext";
import { v4 as uuidv4 } from "uuid";
import { AppStateContext } from "../../../../Context/AppStateContext";

export default function Feedback() {
  const { setToggleTab, prevValue } = useContext(AppStateContext);

  const [questions, setQuestions] = useState([]);
  const [submitFeedback, setSubmitFeedback] = useState(false);
  const [feedbackResponse, setfeedbackResponse] = useState({});
  const scrollingDivRef = useRef(null);
  const { getToken } = useContext(AuthContext);
  const scrollToBottom = () => {
    const divElement = scrollingDivRef.current;
    if (divElement) {
      const scrollHeight = divElement.scrollHeight;
      const clientHeight = divElement.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      divElement.scrollTo({
        top: maxScrollTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (submitFeedback) scrollToBottom();
  }, [submitFeedback]);

  const getFeedbackQuestions = async () => {
    try {
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url}/feedback_ppmd`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      });
      let result = await response.json();
      setQuestions(result?.data);
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const handleSubmitFeedback = async () => {
    if (submitFeedback) return;

    let payload = [];
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].answer && questions[i].answer.length !== 0)
        payload.push({
          question_id: questions[i].question_id,
          answer: questions[i].answer,
        });
      else if (questions[i].is_required) return;
    }
    const params = new URLSearchParams();
    params.append("form_id", uuidv4());
    let accessToken = await getToken();
    let response = await fetch(`${Base_Url}/feedback_ppmd?${params}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Token": accessToken,
      },
      body: JSON.stringify(payload),
    });
    setSubmitFeedback(true);
    return;
  };
  useEffect(() => {
    getFeedbackQuestions();
  }, []);
  return (
    <div className="feedback-container">
      <div className="feedback-header">
        <div></div>
        <span className="feedback-header-content">
          Feedback for the Super Tutor
        </span>
        <div className="close-icon-box">
          <CloseOutlined
            className="feedback-close-icon"
            onClick={() => {
              setToggleTab(prevValue);
            }}
          />
        </div>
      </div>
      {questions.length === 0 ? (
        <Spin className="spinner-feedback" />
      ) : (
        <div className="feedback-scroll-content" ref={scrollingDivRef}>
          <span className="feedback-ins-text">
            To help me improve and serve you better, please provide your
            response to the questions below:
          </span>
          {questions.map((item, index) => {
            return (
              <FeedbackQuestion
                question={item}
                question_no={index + 1}
                feedbackResponse={feedbackResponse}
                setfeedbackResponse={setfeedbackResponse}
                submitFeedback={submitFeedback}
                questions={questions}
                setQuestions={setQuestions}
              />
            );
          })}
          <div className="submit-feedback-pos">
            <div
              className="submit-feedback-button"
              onClick={handleSubmitFeedback}
            >
              Submit feedback
            </div>
          </div>
          {submitFeedback && (
            <div className="thanks-feedback-pos">
              <div className="thanks-feedback-box">
                Thank you for submitting your feedback!
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
