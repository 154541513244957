import { Tooltip } from "antd";
import React from "react";
import "./SelfAssessTooltip.css";

export default function SelfAssessTooltip({ heading, value }) {
  return (
    <Tooltip
      placement="bottomLeft"
      overlayStyle={{ maxWidth: "40rem" }}
      title={
        <span className="sa-header-content">
          {heading}
          <ul>
            {value.map((item) => {
              return <li>{item}</li>;
            })}
          </ul>
        </span>
      }
      color="#FFFFFF"
    >
      <img
        alt=""
        src="/assets/new-info.svg"
        className="self-assess-info-icon-sizing"
      />
    </Tooltip>
  );
}
