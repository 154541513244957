import "highlight.js/styles/stackoverflow-dark.css";
import MarkdownIt from "markdown-it";
import mdHighlight from "markdown-it-highlightjs";
import React, { useContext } from "react";
import Linkify from "react-linkify";
import "./MessageBox.css";
import mdCodeCopy from "./markdownCopy";
import { TourContext } from "../../../../Context/TourContext";
import { Tooltip } from "antd";
import { PdfContext } from "../../../../Context/PdfContext";

export default function MessageBox({
  mObj,
  file,
  setFile,
  setPathToFiles,
  setCurrentIndex,
  style = {},
}) {
  const { tourOpen, expTourRef1 } = useContext(TourContext);
  const { disabledLinks } = useContext(PdfContext);
  const { user } = mObj;

  const handleLinkSelection = (textContent, link) => {
    setCurrentIndex(0);
    setFile(link);
    setPathToFiles(link);
    localStorage.setItem("pdf", link);
    localStorage.setItem("filepath", link);
  };

  //Sometimes we receive a diff response from backend so to check it off added it

  //Conditonal css is applied on them and will be used if we are using DarkTheme
  //answer box
  const messageBoxStylesDC = {
    backgroundColor: "white",
    color: "black",
    borderRadius: "7px",
  };

  //user question box
  const messageBoxStylesUsr = {
    color: "black",
    backgroundColor: "#B1D177",
    borderRadius: "7px",
  };

  // Function to check of links in response and render accordingly
  const checkForMarkdown = (message) => {
    const splitPattern = "***";
    let sections = [];
    if (message.includes(splitPattern)) {
      sections = message.split(splitPattern);
    } else {
      if (message) {
        sections.push(message);
      }
    }
    let md = MarkdownIt({
      linkify: true,
      breaks: true,
    }).use(mdCodeCopy, {
      iconStyle: "", // Clear default icon style
      buttonStyle: "",
    });
    md = md.use(mdHighlight);

    const renderedText = sections.map((section, index) => {
      if (index % 2 === 0) {
        const data = section.trim();
        const result = md.render(data);
        return (
          <div
            dangerouslySetInnerHTML={{ __html: result }}
            style={{ wordWrap: "break-word" }}
          />
        );
      } else {
        let markdownText = section.trim();
        const linkRegex = /<ins>(.*?)<\/ins>\]\((.*?)\)|<em>(.*?)<\/em>/g;
        let matches;
        const links = [];
        const emphasizedText = [];

        while ((matches = linkRegex.exec(markdownText)) !== null) {
          const [, textContent, link, emphasized] = matches;
          if (textContent && link) {
            links.push({ textContent, link });
          } else if (emphasized) {
            emphasizedText.push(emphasized);
          }
        }
        if (links.length > 0 || emphasizedText.length > 1) {
          return (
            <div style={{ marginBottom: "12px" }}>
              <hr />
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="source-link-header">
                  <em>
                    Source
                    {links.length + (emphasizedText.length - 1) > 1 && `s`}
                    :&nbsp;
                  </em>
                </div>
                <div className="source-link-container">
                  {links.map((item, index) => (
                    <button
                      className={
                        disabledLinks.includes(item.link)
                          ? "source-link-button source-link-missing"
                          : "source-link-button"
                      }
                      ref={tourOpen ? expTourRef1 : null}
                    >
                      <Tooltip
                        placement="bottom"
                        overlayClassName="header-tooltips-width"
                        title={
                          <div className="source-link-tooltips minimize-button-tooltip">
                            {disabledLinks.includes(item.link)
                              ? "The document has been removed"
                              : item.textContent}
                          </div>
                        }
                        color="#444444"
                      >
                        <div
                          className="source-link-button-text"
                          onClick={() => {
                            handleLinkSelection(item.textContent, item.link);
                          }}
                        >
                          {item.textContent}
                        </div>
                      </Tooltip>
                    </button>
                  ))}
                </div>
                <em>
                  {emphasizedText.slice(1).map((item, index) => (
                    <span style={{ whiteSpace: "nowrap" }}>
                      <span
                        style={{
                          whiteSpace: "initial",
                          wordBreak: "break-all",
                        }}
                      >
                        {item}
                      </span>
                      {index !== emphasizedText.length - 1 && (
                        <span>,&nbsp;</span>
                      )}
                    </span>
                  ))}
                </em>
              </div>
            </div>
          );
        } else {
          return (
            <>
              <hr />
              <div
                dangerouslySetInnerHTML={{ __html: markdownText }}
                style={{ wordWrap: "break-word", marginBottom: "0.625rem" }}
              />
            </>
          );
        }
      }
    });

    return renderedText;
  };

  return (
    <div className="box" style={style}>
      <div
        className="message_box"
        style={user === "chatbot" ? messageBoxStylesDC : messageBoxStylesUsr}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <span className="message">{checkForMarkdown(mObj.message)}</span>
        </Linkify>
      </div>
    </div>
  );
}
