import React, { useContext, useEffect } from "react";
import "./TrainingsRecommended.css";
import TrainingTable from "../TrainingTable/TrainingTable";
import { Spin, Tooltip } from "antd";
import { TourContext } from "../../../../Context/TourContext";
import { training_history_dummy } from "../../../../constants/GlobalConstants";
import { TrainingsContext } from "../../../../Context/TrainingsContext";

export default function TrainingsRecommended({
  scrollToBottom,
  scrollingDivRef,
  handleLittleScroll,
  shouldAutoScrollRef,
}) {
  const { tourOpen } = useContext(TourContext);

  const {
    retainContext,
    isLoadingRecTrainings,
    isLoadingFilterTrainings,
    isLoadingTrainingHistory,
    trainingHistory,
    reflectTabOpen,
  } = useContext(TrainingsContext);

  useEffect(() => {
    if (!tourOpen) scrollToBottom();
  }, [retainContext, tourOpen]);

  useEffect(() => {
    if (shouldAutoScrollRef.current && scrollingDivRef.current) {
      scrollToBottom();
    }
  }, [trainingHistory]);

  let isLoading =
    !tourOpen && (isLoadingRecTrainings || isLoadingTrainingHistory);
  let trainingHistoryVisible = tourOpen
    ? training_history_dummy
    : trainingHistory;

  return (
    <div
      className="trainings-part"
      ref={scrollingDivRef}
      onScroll={handleLittleScroll}
      style={{ paddingBottom: reflectTabOpen ? "6.6rem" : "2rem" }}
    >
      {isLoading ? (
        <div className="recommended-trainings-spinner">
          <Spin />
        </div>
      ) : (
        <div className="trainings-container">
          {trainingHistoryVisible?.map((item, i) => {
            if (item.user === "chatbot") {
              if (item.hasOwnProperty("msg")) {
                return <div className="training-message">{item?.msg}</div>;
              } else if (item.hasOwnProperty("readOnlyText")) {
                return (
                  <div
                    className="training-message"
                    dangerouslySetInnerHTML={{ __html: item?.readOnlyText }}
                  ></div>
                );
              } else {
                return (
                  <TrainingTable
                    data={item.hasOwnProperty("data") ? item?.data : null}
                    table_heading={
                      i === 0
                        ? "Tailored to your learning journey, here are some resources recommended for you."
                        : item.hasOwnProperty("isReflectiveAssessment")
                        ? "Here are the resources personalized for you based on your responses to the reflective questions."
                        : item?.message?.length === 0
                        ? "Here are the resources that match your preferences."
                        : item?.message
                    }
                    setFilters={true}
                    retainContext={
                      i !== trainingHistoryVisible.length - 1
                        ? item?.modifySearchStatus
                        : retainContext
                    }
                    modSearchButtonVisible={
                      i !== 0 && !item.invalid_context ? true : false
                    }
                    modSearchButtonDisabled={
                      i !== trainingHistoryVisible.length - 1
                    }
                    trainingKey={item?.key}
                    index={i}
                  />
                );
              }
            } else {
              if (item.hasOwnProperty("filters")) {
                return (
                  <div
                    style={{ justifyContent: "end" }}
                    className="training-message-wrapper"
                  >
                    <div
                      className="training-message"
                      style={{ background: "#B1D177" }}
                    >
                      Filters:&nbsp;
                      {Object.entries(item?.filters)
                        .filter(([key, value]) => value.length !== 0)
                        .map(([key, value], i) => {
                          return (
                            <span>
                              {i !== 0 && ", "}
                              <Tooltip
                                overlayClassName="selected-filters-hover"
                                title={
                                  <div className="selected-filters-hover-text">
                                    <div className="selected-filters-hover-key">
                                      {key}:{" "}
                                    </div>
                                    <div className="selected-filters-hover-value">
                                      {value
                                        .map((filter) => filter?.value)
                                        .join(", ")}
                                    </div>
                                  </div>
                                }
                                color="#444444"
                              >
                                <span className="entered-filter-text">
                                  {key}&nbsp;
                                </span>
                                ({value.length})
                              </Tooltip>
                            </span>
                          );
                        })}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    style={{ justifyContent: "end" }}
                    className="training-message-wrapper"
                  >
                    <div
                      className="training-message"
                      style={{ background: "#B1D177" }}
                    >
                      {item?.message}
                    </div>
                  </div>
                );
              }
            }
          })}
          {!isLoadingFilterTrainings &&
            (retainContext ? (
              <div className="training-message" style={{ color: "#056B39" }}>
                Use the filters or chat field to refine the preferences.
              </div>
            ) : trainingHistoryVisible.length > 1 &&
              (trainingHistoryVisible[
                trainingHistoryVisible.length - 1
              ].hasOwnProperty("data") ||
                trainingHistoryVisible[
                  trainingHistoryVisible.length - 1
                ].hasOwnProperty("readOnlyText")) ? (
              <div className="training-message">
                To initiate a fresh search with new preferences, use the
                filters/ chat field OR answer a few simple questions to get
                personalized learning recommendations.
              </div>
            ) : trainingHistoryVisible.length === 1 &&
              !trainingHistoryVisible[0].hasOwnProperty("msg") ? (
              <div className="training-message">
                Use the filters or the chat field to share your preferences.
              </div>
            ) : null)}
        </div>
      )}
    </div>
  );
}
