import React, { useContext, useEffect, useState } from "react";
import "./ConfigAdmins.css";
import ConfigAdminsTable from "./ConfigAdminsTable/ConfigAdminsTable";
import AddEditUser from "./AddEditUser/AddEditUser";
import { AuthContext } from "../../../../../Context/AuthContext";
import { Base_Url_Admin } from "../../../../../Utils/urls";
import { Spin, message } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  delete_text,
  handleTrainings,
} from "../../../../../constants/GlobalConstants";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";

export default function ConfigAdmins() {
  const [page, setPage] = useState(1);
  const {
    user,
    getToken,
    editViewToggle,
    setEditViewToggle,
    selectedUser,
    setSelectedUser,
  } = useContext(AuthContext);
  const [deleteTaining, setDeleteTraining] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [admins, setAdmins] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Perform the action you want when Enter is pressed
      if (inputValue > admins?.total_pages) {
        setInputValue("");
        return;
      }
      setPage(inputValue);
    }
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  const getUsers = async (value) => {
    try {
      setAdmins(null);
      setSelectedUser(null);
      const params = new URLSearchParams();
      params.append("page", value ?? page);
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/admin_management?${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
        }
      );
      let users = await response.json();
      setAdmins(users);
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const handleDeleteUser = async () => {
    try {
      let payload = {
        user_id: deleteTaining,
      };
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/admin_management`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
        body: JSON.stringify(payload),
      });
      let result = await response.json();
      messageApi.open({
        type: "success",
        content: result?.message,
      });
      setDeleteTraining("");
      if (page !== 1) setPage(1);
      else getUsers(1);
      setEditViewToggle(0);
      setSelectedUser(null);
    } catch (e) {
      console.log("Error:", e);
    }
  };
  useEffect(() => {
    getUsers();
  }, [page]);
  return (
    <div className="sub-page">
      {contextHolder}
      <div className="heading-tab-pad">
        {editViewToggle === 0 ? (
          <span className="content-heading-main">Access Management</span>
        ) : (
          <div className="heading-tab">
            <span
              className="sub-heading"
              onClick={() => {
                setEditViewToggle(0);
                setSelectedUser(null);
              }}
            >
              Access Management
            </span>
            <span className="subbed-heading">
              &nbsp;
              {" > "}&nbsp;
              {!selectedUser ? "Add user" : "Edit user"}
            </span>
          </div>
        )}
      </div>
      {editViewToggle === 0 ? (
        !admins ? (
          <Spin />
        ) : (
          <>
            <span className="sub-content">
              Manage permissions for administrators to maintain data and manage
              users through this section.
            </span>
            <div className="training-heading-box">
              <span className="training-content">Admin list</span>
              <div
                className="create-button"
                onClick={() => {
                  setEditViewToggle(1);
                }}
              >
                Add user
              </div>
            </div>
            <ConfigAdminsTable
              trainings={admins?.data}
              setEditViewToggle={setEditViewToggle}
              setDeleteTraining={setDeleteTraining}
              setSelectedUser={setSelectedUser}
            />
            <div className="pagination-box">
              <div className="centered-box">
                <LeftOutlined
                  className={!admins?.has_previous ? "cursor-n" : ""}
                  onClick={() => {
                    handleTrainings(
                      admins?.has_previous ? "prev" : "",
                      page,
                      setPage
                    );
                  }}
                />
              </div>
              <div className="centered-box">{page}</div>
              {/* {page} of {trainings?.total_pages} */}
              <div className="centered-box">
                <RightOutlined
                  className={!admins?.has_next ? "cursor-n" : ""}
                  onClick={() => {
                    handleTrainings(
                      admins?.has_next ? "next" : "",
                      page,
                      setPage
                    );
                  }}
                />
              </div>
              <div className="centered-box">
                {page}
                {"/"}
                {admins?.total_pages}{" "}
              </div>
              <div className="page-input-box">
                <span className="go-to-text">Go to</span>
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  className={
                    inputValue > admins?.total_pages
                      ? "pagination-input-box error-color"
                      : "pagination-input-box"
                  }
                />
              </div>
            </div>
          </>
        )
      ) : (
        <AddEditUser
          selectedUser={selectedUser}
          getUsers={getUsers}
          setEditViewToggle={setEditViewToggle}
          setDeleteTraining={setDeleteTraining}
        />
      )}
      <DeleteModal
        deleteTaining={deleteTaining}
        setDeleteTraining={setDeleteTraining}
        content={
          "Are you sure that you want to revoke admin access from the user?"
        }
        func={handleDeleteUser}
      />
    </div>
  );
}
