import { createContext, useRef, useState } from "react";

export const FilterContext = createContext();

let fetchedFilters = JSON.parse(localStorage.getItem("filters"));
export const FilterContextProvider = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState({});
  const [selectedFilters, setSelectedFilters] = useState(fetchedFilters ?? {});
  const [prevSelectedFilters, setPrevSelectedFilters] = useState(
    fetchedFilters ?? {}
  );
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [noOfFilters, setNoOfFilters] = useState(0);
  const [isFilterCleared, setIsFilterCleared] = useState(false);
  const selectionAllObj = useRef({});
  const [filterIdMapping, setFilterIdMapping] = useState({});

  const filterValues = {
    filterOptions,
    setFilterOptions,
    selectedFilters,
    setSelectedFilters,
    prevSelectedFilters,
    setPrevSelectedFilters,
    isLoadingFilter,
    setIsLoadingFilter,
    showFilter,
    setShowFilter,
    noOfFilters,
    setNoOfFilters,
    isFilterCleared,
    setIsFilterCleared,
    selectionAllObj,
    filterIdMapping,
    setFilterIdMapping,
  };

  return (
    <FilterContext.Provider value={filterValues}>
      {children}
    </FilterContext.Provider>
  );
};
