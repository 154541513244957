import { Modal } from "antd";
import React from "react";

export default function DeleteModal({
  deleteTaining,
  setDeleteTraining,
  content,
  func,
}) {
  return (
    <Modal
      open={deleteTaining.length !== 0}
      centered
      title={
        <div className="delete-title-box">
          <img src="/assets/ExclamationCircle.svg" />
          <span className="title-content">{content}</span>
        </div>
      }
      width="26rem"
      footer={[
        <div className="delete-modal">
          <div className="no-box" onClick={() => setDeleteTraining("")}>
            No
          </div>
          <div className="yes-box" onClick={func}>
            Yes
          </div>
        </div>,
      ]}
      onCancel={() => setDeleteTraining("")}
    ></Modal>
  );
}
