import React, { useState, useEffect, useContext, useRef } from "react";
import ChatBotContent from "./components/ChatBotContent/ChatBotContent";
import { AuthContext } from "../../Context/AuthContext";
import dayjs from "dayjs";
import { Base_Url } from "../../Utils/urls";
import utc from "dayjs/plugin/utc";
import { TourContext } from "../../Context/TourContext";
import { useAppUtilities } from "../../Hooks/useAppUtilities";
import { AppStateContext } from "../../Context/AppStateContext";

dayjs.extend(utc);

let screenHeight = 1080;
var tourFromExView =
  JSON.parse(localStorage.getItem("tourFromExView")) ?? false;

// Initialize state to true if either value1 or value2 is true, otherwise false
var initialState = tourFromExView;

export default function SmallView() {
  const { authenticated, handleLoginPopup, getToken } = useContext(AuthContext);

  const { setTourOpen, setCurrentView } = useContext(TourContext);

  const { setToggleTab } = useContext(AppStateContext);

  const { user_interaction } = useAppUtilities();

  const [showChatBot, setShowChatBot] = useState(initialState);
  const windowHeight = useRef(1080);
  const [botHeight, setBotHeight] = useState(610);
  const [botWidth, setBotWidth] = useState(455);

  function sendMsg(heightInput, widthInput) {
    var msgData = {
      height: heightInput,
      width: widthInput,
    };
    window.parent.postMessage(msgData, "*");
  }

  useEffect(() => {
    setToggleTab(1);
    localStorage.setItem("Toggle", 1);
    let botHeightTemp = botHeight;
    localStorage.setItem("interactionMethod", "POPUP");
    screenHeight = window.screen.height;
    if (screenHeight <= 720) {
      botHeightTemp = 544;
      setBotHeight(botHeightTemp);
    }
    if (tourFromExView) {
      sendMsg(botHeightTemp, botWidth);
    } else {
      sendMsg(80, 176);
    }
    setCurrentView(0);
    localStorage.removeItem("tourFromExView");
  }, []);

  const ppmdGenerateQuestions = async () => {
    try {
      let accessToken = localStorage.getItem("accessToken") ?? getToken();
      let response = await fetch(`${Base_Url}/ppmd_start_questions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify({}),
        keepalive: true,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      console.log("error:", err);
    }
  };

  //Handle the sessions with user inactivity
  let timeout;
  const [showAlert, setShowAlert] = useState(false);
  const resetTimer = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setShowAlert(true);
      setTourOpen(false);
      if (sessionStorage.getItem("sessionId")) {
        let sessionPayload = {
          end_time: dayjs().utc().format("YYYY-MM-DD HH:mm:ss"),
          session_id: sessionStorage.getItem("sessionId"),
        };
        user_interaction("", {}, sessionPayload, {});
      }
      sessionStorage.clear();
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("scroll", handleActivity);
    }, 15 * 60 * 1000); // 15 minutes in milliseconds
  };

  const handleActivity = () => {
    setShowAlert(false);
    resetTimer();
  };

  useEffect(() => {
    resetTimer();

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("mousedown", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("scroll", handleActivity);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = () => {
    // Detect if the page is being closed (tab close) or refreshed
    if (sessionStorage.getItem("sessionId")) {
      let currentTime = dayjs().utc();
      let sessionPayload = {
        end_time: dayjs().utc().format("YYYY-MM-DD HH:mm:ss"),
        session_id: sessionStorage.getItem("sessionId"),
      };
      localStorage.setItem("lastInteractionTime", currentTime);
      user_interaction("", {}, sessionPayload, {});
      ppmdGenerateQuestions();
    }
    
    localStorage.removeItem("tourFromExView");
  };

  return (
    <>
      {showChatBot ? (
        authenticated && (
          <div>
            <ChatBotContent
              setShowChatBot={setShowChatBot}
              sendMsg={sendMsg}
              screenHeight={screenHeight}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
            />
          </div>
        )
      ) : (
        <div className="chat-bot-button">
          <div>
            <img src="./assets/ask-chat.png" className="ask-chat-tooltip" />
          </div>
          <div
            onClick={() => {
              setShowChatBot(true);
              handleLoginPopup();
              sendMsg(botHeight, botWidth);
              setShowAlert(false);
            }}
          >
            <img src="./assets/supertutor-icon.png" className="chat-bot-icon" />
          </div>
        </div>
      )}
    </>
  );
}
