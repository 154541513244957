import React, { useContext } from "react";
import "./PdfBotHeader.css";
import { Minimized_View_Url } from "../../../../Utils/urls";
import { Tooltip } from "antd";
import { TourContext } from "../../../../Context/TourContext";
import { AppStateContext } from "../../../../Context/AppStateContext";
import { ConversationContext } from "../../../../Context/ConversationContext";
import { useAppUtilities } from "../../../../Hooks/useAppUtilities";
export default function PdfBotHeader() {
  const {
    tourOpen,
    setTourOpen,
    expTourRef5,
    expTourRef6,
    expTourRef8,
    expTourRef15,
    expTourRef16,
  } = useContext(TourContext);

  const { toggleTab, setToggleTab } = useContext(AppStateContext);
  const { disabledQuestion } = useContext(ConversationContext);

  const { user_interaction } = useAppUtilities();

  return (
    <div className="pdf-chat-bot-navbar">
      <div className="pdf-chat-bot-tab-container">
        <Tooltip
          placement="bottomLeft"
          overlayClassName="header-tooltips-width"
          title={
            <div className="header-tooltips">
              Click here to ask Super Tutor any queries about Gen AI.
            </div>
          }
          color="#444444"
        >
          <div
            className={
              toggleTab === 1
                ? "pdf-chat-bot-tab pdf-chat-bot-selected-tab"
                : "pdf-chat-bot-tab"
            }
            onClick={() => {
              setToggleTab(1);
              if (!tourOpen) {
                user_interaction("", {}, {}, { type: "questions_on_genai" });
              }
              localStorage.setItem("Toggle", 1);
            }}
            ref={expTourRef8}
          >
            <div className="toggle-headings">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Conversations&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </Tooltip>
        <Tooltip
          placement="bottomLeft"
          overlayClassName="header-tooltips-width"
          title={
            <div className="header-tooltips">
              Click here to identify trainings based on your learning profile.
            </div>
          }
          color="#444444"
        >
          <div
            className={
              toggleTab === 2
                ? "pdf-chat-bot-tab pdf-chat-bot-selected-tab"
                : "pdf-chat-bot-tab"
            }
            onClick={() => {
              setToggleTab(2);
              if (!tourOpen) {
                user_interaction("", {}, {}, { type: "recommended_trainings" });
              }
              localStorage.setItem("Toggle", 2);
            }}
            ref={expTourRef6}
          >
            <div className="toggle-headings">Recommended Resources</div>
          </div>
        </Tooltip>
      </div>
      <div className="pdf-chat-bot-nav-container">
        <Tooltip
          placement="bottomRight"
          overlayClassName="header-tooltips-fixed-width-2"
          title={
            <div className="header-tooltips">
              Click here to share your feedback/suggestions for improving
              SuperTutor.
            </div>
          }
          color="#444444"
        >
          <div
            className="feedback-nav-button"
            onClick={() => {
              setToggleTab(3);
              localStorage.setItem("Toggle", 3);
            }}
            ref={expTourRef15}
          >
            <div className="feedback-nav-img">
              <img src="./assets/feedback-icon.svg" />
            </div>
          </div>
        </Tooltip>
        <Tooltip
          placement="bottomRight"
          overlayClassName="header-tooltips-fixed-width-2"
          title={
            <div className="header-tooltips">
              {disabledQuestion
                ? "Please take the tour once the response is generated. You may stop the response generation and navigate immediately."
                : "Click here to take a tour and explore all the features of Super Tutor."}
            </div>
          }
          color="#444444"
        >
          <button
            className={`tour-nav-button ${disabledQuestion ? "cursor-n" : ""}`}
            disabled={disabledQuestion}
            onClick={() => {
              setToggleTab(1);
              setTourOpen(true);
            }}
            ref={expTourRef16}
          >
            <div className="tour-nav-img">
              <img src="./assets/guide.svg" />
            </div>
          </button>
        </Tooltip>
        <div className="chat-bot-links-right-exp">
          <Tooltip
            placement="bottomRight"
            overlayClassName="header-tooltips-width"
            title={
              <div className="header-tooltips minimize-button-tooltip">
                {disabledQuestion
                  ? "Please navigate once the response is generated. You may stop the response generation and navigate immediately."
                  : "Click here to close the chatbot."}
              </div>
            }
            color="#444444"
          >
            <button className="minimize-button" disabled={disabledQuestion}>
              {disabledQuestion ? (
                <div className="cursor-n">
                  <img src="./assets/minimize.png" />
                </div>
              ) : (
                <a href={Minimized_View_Url} target="_top" ref={expTourRef5}>
                  <img
                    src="./assets/minimize.png"
                    onClick={() => {
                      localStorage.removeItem("tourFromExView");
                      localStorage.removeItem("tourFromSmView");
                    }}
                  />
                </a>
              )}
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
