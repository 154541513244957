import React, { useState } from "react";
import "./Trainings.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import TrainingTable from "../../../../Components/TrainingTable/TrainingTable";
import CotentsTable from "../../../../Components/CotentsTable/CotentsTable";
import EmptyScreen from "../../../../Components/EmptyScreen/EmptyScreen";
import { handleTrainings } from "../../../../../../constants/GlobalConstants";

export default function Trainings({
  setShowUploadSection,
  SubmitTitle,
  heading,
  trainings,
  page,
  setPage,
  setEditTraining,
  setShowTraining,
  setDeleteTraining,
  Type,
}) {
  const [inputValue, setInputValue] = useState("");

  if (trainings?.data.length === 0) {
    return (
      <EmptyScreen
        setShowUploadSection={setShowUploadSection}
        SubmitTitle={SubmitTitle}
      />
    );
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Perform the action you want when Enter is pressed
      if (inputValue > trainings?.total_pages) {
        setInputValue("");
        return;
      }
      setPage(inputValue);
    }
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  return (
    <>
      <div className="training-heading-box">
        <span className="training-content">{heading}</span>
        <div
          className="create-button"
          onClick={() => {
            setShowUploadSection(true);
          }}
        >
          {SubmitTitle}
        </div>
      </div>
      {heading === "Resource List" ? (
        <TrainingTable
          trainings={trainings?.data}
          setShowTraining={setShowTraining}
          setEditTraining={setEditTraining}
          setViewSection={setShowUploadSection}
          setDeleteTraining={setDeleteTraining}
          typeKey={Type}
        />
      ) : (
        <CotentsTable
          trainings={trainings?.data}
          setShowTraining={setShowTraining}
          setEditTraining={setEditTraining}
          setViewSection={setShowUploadSection}
          setDeleteTraining={setDeleteTraining}
        />
      )}

      <div className="pagination-box">
        <div className="centered-box">
          <LeftOutlined
            className={!trainings?.has_previous ? "cursor-n" : ""}
            onClick={() => {
              handleTrainings(
                trainings?.has_previous ? "prev" : "",
                page,
                setPage
              );
            }}
          />
        </div>
        <div className="centered-box">{page}</div>
        {/* {page} of {trainings?.total_pages} */}
        <div className="centered-box">
          <RightOutlined
            className={!trainings?.has_next ? "cursor-n" : ""}
            onClick={() => {
              handleTrainings(trainings?.has_next ? "next" : "", page, setPage);
            }}
          />
        </div>
        <div className="centered-box">
          {page}
          {"/"}
          {trainings?.total_pages}{" "}
        </div>
        <div className="page-input-box">
          <span className="go-to-text">Go to</span>
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            className={
              inputValue > trainings?.total_pages
                ? "pagination-input-box error-color"
                : "pagination-input-box"
            }
          />
        </div>
      </div>
    </>
  );
}
