import React, { useContext } from "react";
import "./ChatInput.css";
import { Button } from "antd";
import { TourContext } from "../../../../Context/TourContext";
import { AppStateContext } from "../../../../Context/AppStateContext";
import { useAppUtilities } from "../../../../Hooks/useAppUtilities";
import { useConversations } from "../../../../Hooks/useConversations";

export default function ChatInput({ handleSubmit, disabledInput, disabled }) {
  const { tourOpen, currentStep, expTourRef10, expTourRef19 } =
    useContext(TourContext);

  const { toggleTab, inputValue } = useContext(AppStateContext);

  const { handleInputChange } = useAppUtilities();
  const { handleStopGenerate } = useConversations();

  let tourStopButtonVisible = tourOpen ? currentStep === 4 : false;

  return (
    <>
      <form
        className="input-bottom-expanded"
        onSubmit={
          toggleTab === 1 && disabled ? handleStopGenerate : handleSubmit
        }
        ref={expTourRef10}
      >
        <input
          className={disabledInput ? "input-field cursor-n" : "input-field"}
          type="text"
          value={inputValue}
          disabled={disabledInput}
          placeholder="Ask a question"
          onChange={handleInputChange}
        />
        {tourStopButtonVisible || (toggleTab === 1 && disabled) ? (
          <Button className="submit-button-small-view" htmlType="submit">
            <img
              typeof="submit"
              src="./assets/stop.png"
              alt=""
              className="submit-icon"
              ref={expTourRef19}
            />
          </Button>
        ) : (
          <Button
            disabled={inputValue.trim().length === 0 || disabled}
            className={
              inputValue.trim().length === 0 || disabled
                ? "submit-button-small-view cursor-n"
                : "submit-button-small-view"
            }
            htmlType="submit"
          >
            <img
              typeof="submit"
              src="./assets/SubmitBtn.svg"
              alt=""
              className="submit-icon"
            />
          </Button>
        )}
      </form>
    </>
  );
}
