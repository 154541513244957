// Copy-pasted from the markdown-it-code-copy plugin
import _ from "lodash";
import "./MessageBox.css";
import ClipboardJS from "clipboard";


const clipboard = new ClipboardJS(".markdown-it-code-copy");
const defaultOptions = {
  iconClass: "mdi mdi-content-copy",
  buttonStyle: "",
};

function renderCode(origRule, options) {
  options = _.merge(defaultOptions, options);
  return (...args) => {
    const [tokens, idx] = args;
    const content = tokens[idx].content
      .replaceAll('"', "&quot;")
      .replaceAll("'", "&#39;");
    const origRendered = origRule(...args);

    if (content.length === 0) return origRendered;

    return `
<div style="position: relative">
	${origRendered}
	<button class="markdown-it-code-copy ${options.buttonClass}" data-clipboard-text="${content}" style="${options.buttonStyle}" title="Copy">
		<span style="${options.iconStyle}" class="${options.iconClass}"></span>
	</button>
</div>
`;
  };
}

export default function mdCodeCopy(md, options) {
  md.renderer.rules.code_block = renderCode(
    md.renderer.rules.code_block,
    options
  );
  md.renderer.rules.fence = renderCode(md.renderer.rules.fence, options);
}
