import { createContext, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const ConversationContext = createContext();

export const ConversationContextProvider = ({ children }) => {
  const parentId = useRef(localStorage.getItem("ParentId"));
  const quesContext = useRef(parentId.current !== null);
  const conversationId = useRef(
    parentId.current === null
      ? uuidv4()
      : localStorage.getItem("ConversationId")
  );
  localStorage.setItem("ConversationId", conversationId.current);
  const currentMessageId = useRef(null);
  const disabledQuestionRef = useRef(false);
  const [disabledQuestion, setDisabledQuestion] = useState(false);
  const [scrollActive, setScrollActive] = useState(false);
  const prevQuestion = useRef("");
  const [defaultContent, setDefaultContent] = useState(null);
  const [isLoadingDefaultContent, setIsLoadingDefaultContent] = useState(false);
  const [responses, setResponses] = useState([]);
  const [isLoadingChat, setIsLoadingChat] = useState(
    parentId.current ? true : false
  );
  const shouldAutoScrollRef = useRef(false);

  const conversationValues = {
    parentId,
    quesContext,
    conversationId,
    currentMessageId,
    disabledQuestionRef,
    disabledQuestion,
    setDisabledQuestion,
    scrollActive,
    setScrollActive,
    prevQuestion,
    defaultContent,
    setDefaultContent,
    isLoadingDefaultContent,
    setIsLoadingDefaultContent,
    responses,
    setResponses,
    isLoadingChat,
    setIsLoadingChat,
    shouldAutoScrollRef,
  };

  return (
    <ConversationContext.Provider value={conversationValues}>
      {children}
    </ConversationContext.Provider>
  );
};
