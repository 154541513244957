import { useContext } from "react";
import { FilterContext } from "../Context/FilterContext";
import { TrainingsContext } from "../Context/TrainingsContext";
import { AuthContext } from "../Context/AuthContext";
import { Base_Url } from "../Utils/urls";
import { AppStateContext } from "../Context/AppStateContext";
import { useAppUtilities } from "./useAppUtilities";

let fetchedFilters = JSON.parse(localStorage.getItem("filters"));
export function useRecommendedTrainings() {
  const { getToken } = useContext(AuthContext);

  const { setToggleTab, inputValue, setInputValue } =
    useContext(AppStateContext);

  const { user_interaction } = useAppUtilities();

  const {
    filterOptions,
    setFilterOptions,
    selectedFilters,
    setSelectedFilters,
    prevSelectedFilters,
    setPrevSelectedFilters,
    setIsLoadingFilter,
    setShowFilter,
    setNoOfFilters,
    setIsFilterCleared,
    selectionAllObj,
    setFilterIdMapping,
  } = useContext(FilterContext);

  const {
    retainContext,
    setRetainContext,
    setIsLoadingRecTrainings,
    setIsLoadingFilterTrainings,
    setIsLoadingTrainingHistory,
    disabledTraining,
    setDisabledTraining,
    filteredTrainings,
    setFilteredTrainings,
    recTrainings,
    setRecTrainings,
    trainingHistory,
    setTrainingHistory,
    trainingHistId,
    setScrollNearBottom,
    setCurrentTrainings,
    currentSelectedTraining,
    setCurrentSelectedTraining,
    setShowCurrentTrainings,
  } = useContext(TrainingsContext);

  const getAllFilters = async () => {
    try {
      setIsLoadingFilter(true);
      const params = new URLSearchParams();
      params.append("exclude_new", true);
      let accessToken = await getToken();
      let filters = await fetch(`${Base_Url}/get_all_filters?${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      }).then((response) => response.json());
      setFilterOptions(filters?.filters);
      let combinedData = {};
      filters?.data?.map((item, index) => {
        let filterName = item?.name;
        if (filters?.filters.hasOwnProperty(filterName)) {
          combinedData = {
            ...combinedData,
            [filterName]: {
              id: item?.id,
              ...item?.settings,
            },
          };
        }
      });
      setFilterIdMapping(combinedData);
      setIsLoadingFilter(false);
      if (fetchedFilters) {
        updateFilterCount();
        let dummyObj = {};
        Object.keys(filters?.filters).forEach((key) => {
          if (!fetchedFilters.hasOwnProperty(key)) {
            dummyObj[key] = [];
          }
        });
        setSelectedFilters((prevValue) => ({
          ...prevValue,
          ...dummyObj,
        }));
        setPrevSelectedFilters((prevValue) => ({
          ...prevValue,
          ...dummyObj,
        }));
        return;
      }
      // Initialize selectedFilters state with empty values for each filter
      const initialSelectedFilters = {};
      Object.keys(filters?.filters).forEach((key) => {
        initialSelectedFilters[key] = [];
      });
      setSelectedFilters(initialSelectedFilters);
      setPrevSelectedFilters(initialSelectedFilters);
    } catch (e) {
      console.log("Error:", e);
      setIsLoadingFilter(false);
    }
  };

  const getTrainingHistory = async () => {
    try {
      setIsLoadingTrainingHistory(true);
      setShowCurrentTrainings(false);
      const params = new URLSearchParams();
      params.append("id", trainingHistId.current);
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url}/recommendation_history?${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      let trainingData = await response.json();
      setIsLoadingTrainingHistory(false);
      setShowCurrentTrainings(true);
      setScrollNearBottom(true);
      if (trainingData.length === 0) {
        setCurrentSelectedTraining(trainingHistId.current);
        setCurrentTrainings(recTrainings);
        return;
      }
      setTrainingHistory([...trainingHistory, ...trainingData]);
      const currentTrainingsTempObj = [
        ...trainingHistory,
        ...trainingData,
      ].find((obj) => obj.key && obj.key === currentSelectedTraining);
      if (currentTrainingsTempObj)
        setCurrentTrainings(currentTrainingsTempObj?.data);
    } catch (e) {
      console.log("Error:", e);
      setIsLoadingFilter(false);
      setIsLoadingTrainingHistory(false);
      setTrainingHistory([
        {
          user: "chatbot",
          msg: "I'm currently unable to load the information. Please connect to the Deloitte VPN try again.",
        },
      ]);
    }
  };

  const isFilterEmpty = () => {
    if (JSON.stringify(selectedFilters) === JSON.stringify({})) {
      return true;
    }
    const initialSelectedFilters = {};
    Object.keys(filterOptions).forEach((key) => {
      initialSelectedFilters[key] = [];
    });
    if (
      JSON.stringify(selectedFilters) === JSON.stringify(initialSelectedFilters)
    ) {
      return true;
    }
    return false;
  };

  const handleMessage = async (e) => {
    e.preventDefault();
    setIsLoadingFilterTrainings(true);
    setIsFilterCleared(false);
    setScrollNearBottom(true);
    if (disabledTraining) return;
    setShowCurrentTrainings(false);
    let trainingHistoryTemp = trainingHistory;
    if (trainingHistoryTemp.length > 0) {
      let lastObjectIndex = trainingHistoryTemp.length - 1;
      if (
        trainingHistoryTemp[lastObjectIndex].hasOwnProperty(
          "modifySearchStatus"
        )
      )
        trainingHistoryTemp[lastObjectIndex].modifySearchStatus = retainContext;
    }
    trainingHistoryTemp = [
      ...trainingHistoryTemp,
      { user: "user", message: `${inputValue}` },
    ];
    setTrainingHistory([
      ...trainingHistoryTemp,
      { user: "chatbot", msg: "Generating response ..." },
    ]);
    let timer = setTimeout(() => {
      setTrainingHistory([
        ...trainingHistoryTemp,
        {
          user: "chatbot",
          msg: "Almost there ...",
        },
      ]);
    }, 10000);
    let payload = {
      message: inputValue,
      action: retainContext ? "next" : "new",
      trainings: [],
    };
    payload["trainings"] = retainContext
      ? filteredTrainings?.map((item) => item?.id)
      : [];
    setInputValue("");
    setDisabledTraining(true);
    setFilteredTrainings([]);
    if (!retainContext) handleClearAndApply();
    try {
      const params = new URLSearchParams();
      params.append("id", trainingHistId.current);
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url}/list_trainings_chat?${params}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify(payload),
      });
      let trainings = await response.json();
      clearTimeout(timer);
      setRetainContext(false);
      setDisabledTraining(false);
      setShowCurrentTrainings(true);
      if (trainings.hasOwnProperty("message")) {
        setTrainingHistory([
          ...trainingHistoryTemp,
          {
            user: "chatbot",
            message: trainings?.message,
            invalid_context: trainings?.invalid_context,
            modifySearchStatus: false,
          },
        ]);
        setIsLoadingFilterTrainings(false);
        return;
      }
      if (trainings?.data.length === 0 || !trainings?.data) {
        setFilteredTrainings([]);
      }
      setFilteredTrainings(trainings?.data);
      setTrainingHistory([
        ...trainingHistoryTemp,
        {
          user: "chatbot",
          data: trainings?.data,
          message: trainings?.suggestion,
          modifySearchStatus: false,
          key: trainings?.key,
        },
      ]);
      setCurrentSelectedTraining(trainings?.key);
      localStorage.setItem("trainingkey", trainings?.key);
      setCurrentTrainings(trainings?.data);
      setIsLoadingFilterTrainings(false);
    } catch (err) {
      console.log(err);
      clearTimeout(timer);
      setTrainingHistory([
        ...trainingHistoryTemp,
        {
          user: "chatbot",
          msg: "Network error! Please refresh the page and try again.",
        },
      ]);
    }
    return;
  };

  const getFilteredTrainings = async () => {
    if (disabledTraining) return;
    setDisabledTraining(true);
    setIsLoadingFilterTrainings(true);
    setShowCurrentTrainings(false);
    setIsFilterCleared(false);
    setScrollNearBottom(true);
    let trainingHistoryTemp = trainingHistory;
    if (trainingHistoryTemp.length > 0) {
      let lastObjectIndex = trainingHistoryTemp.length - 1;
      if (
        trainingHistoryTemp[lastObjectIndex].hasOwnProperty(
          "modifySearchStatus"
        )
      )
        trainingHistoryTemp[lastObjectIndex].modifySearchStatus = retainContext;
    }
    trainingHistoryTemp = [
      ...trainingHistoryTemp,
      { user: "user", filters: selectedFilters },
    ];
    setTrainingHistory([
      ...trainingHistoryTemp,
      { user: "chatbot", msg: "Generating response ..." },
    ]);
    let timer = setTimeout(() => {
      setTrainingHistory([
        ...trainingHistoryTemp,
        {
          user: "chatbot",
          msg: "Almost there ...",
        },
      ]);
    }, 10000);
    let payload = {
      action: retainContext ? "next" : "new",
      filters: {},
      trainings: [],
    };
    for (const key in selectedFilters) {
      if (selectedFilters.hasOwnProperty(key)) {
        payload["filters"][key] = selectedFilters[key].map((item) => item.id);
      }
    }
    payload["trainings"] = retainContext
      ? filteredTrainings?.map((item) => item?.id)
      : [];
    if (selectionAllObj.current === selectedFilters) {
      payload["action"] = "all";
    }
    user_interaction("", selectedFilters);
    setFilteredTrainings([]);
    const params = new URLSearchParams();
    params.append("id", trainingHistId.current);
    let accessToken = await getToken();
    let response = await fetch(`${Base_Url}/list_trainings?${params}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Token": accessToken,
      },
      body: JSON.stringify(payload),
    });
    const trainings = await response.json();
    clearTimeout(timer);
    setDisabledTraining(false);
    setShowCurrentTrainings(true);
    setRetainContext(false);
    if (trainings.hasOwnProperty("message")) {
      setTrainingHistory([
        ...trainingHistoryTemp,
        {
          user: "chatbot",
          message: trainings?.message,
          invalid_context: trainings?.invalid_context,
          modifySearchStatus: false,
        },
      ]);
      setIsLoadingFilterTrainings(false);
      return;
    }
    setFilteredTrainings(trainings?.data || []);
    setTrainingHistory([
      ...trainingHistoryTemp,
      {
        user: "chatbot",
        data: trainings?.data,
        message: trainings?.suggestion || "",
        invalid_context: trainings?.invalid_context,
        modifySearchStatus: false,
        key: trainings?.key,
      },
    ]);
    setCurrentSelectedTraining(trainings?.key);
    localStorage.setItem("trainingkey", trainings?.key);
    setCurrentTrainings(trainings?.data);
    setIsLoadingFilterTrainings(false);
  };

  const getRecommendedTrainings = async () => {
    setIsLoadingRecTrainings(true);
    setShowCurrentTrainings(false);
    try {
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url}/recommended_trainings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      let jsonData = await response.json();
      setRecTrainings(jsonData.data);
      setTrainingHistory([
        ...trainingHistory,
        {
          user: "chatbot",
          data: jsonData.data,
          message: jsonData?.suggestion || "",
          modifySearchStatus: false,
          key: trainingHistId.current,
        },
      ]);
      setIsLoadingRecTrainings(false);
      localStorage.setItem("trainingkey", trainingHistId.current);
    } catch (e) {
      console.log("Error:", e);
      setIsLoadingRecTrainings(false);
      setIsLoadingTrainingHistory(false);
      setTrainingHistory([
        {
          user: "chatbot",
          msg: "I'm currently unable to load the information. Please connect to the Deloitte VPN try again.",
        },
      ]);
    }
  };

  const handleReflectiveSubmit = async (payload, category) => {
    handleClearAndApply();
    setToggleTab(2);
    if (disabledTraining) return;
    setDisabledTraining(true);
    setShowCurrentTrainings(false);
    let trainingHistoryTemp = trainingHistory;
    trainingHistoryTemp = [
      ...trainingHistoryTemp,
      {
        user: "user",
        message: `Reflective Questions: ${category}`,
      },
    ];
    setTrainingHistory([
      ...trainingHistoryTemp,
      { user: "chatbot", msg: "Generating response ..." },
    ]);
    let timer = setTimeout(() => {
      setTrainingHistory([
        ...trainingHistoryTemp,
        {
          user: "chatbot",
          msg: "Almost there ...",
        },
      ]);
    }, 10000);
    let payloadTemp = {
      ...payload,
      training_history_id: trainingHistId.current,
    };
    let accessToken = await getToken();
    let response = await fetch(`${Base_Url}/skill_assessment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Token": accessToken,
      },
      body: JSON.stringify(payloadTemp),
    });
    const trainings = await response.json();
    clearTimeout(timer);
    setDisabledTraining(false);
    setShowCurrentTrainings(true);
    setScrollNearBottom(true);
    setRetainContext(false);
    let historyObj = { invalid_context: true, isReflectiveAssessment: true };
    if (
      trainings.hasOwnProperty("readOnlyText") &&
      trainings.hasOwnProperty("data")
    ) {
      setTrainingHistory([
        ...trainingHistoryTemp,
        {
          ...historyObj,
          user: "chatbot",
          data: trainings?.data,
          message: trainings?.suggestion || "",
          modifySearchStatus: false,
          key: trainings?.key,
        },
        {
          ...historyObj,
          user: "chatbot",
          readOnlyText: trainings?.readOnlyText,
        },
      ]);
      setCurrentSelectedTraining(trainings?.key);
      localStorage.setItem("trainingkey", trainings?.key);
      setCurrentTrainings(trainings?.data);
      return;
    }
    if (trainings.hasOwnProperty("readOnlyText")) {
      historyObj = {
        ...historyObj,
        user: "chatbot",
        readOnlyText: trainings?.readOnlyText,
      };
      setTrainingHistory([...trainingHistoryTemp, historyObj]);
      return;
    }
    if (trainings.hasOwnProperty("message")) {
      historyObj = {
        ...historyObj,
        user: "chatbot",
        message: trainings?.message,
      };
      setTrainingHistory([...trainingHistoryTemp, historyObj]);
      return;
    }
    historyObj = {
      ...historyObj,
      user: "chatbot",
      data: trainings?.data,
      message: trainings?.suggestion || "",
      modifySearchStatus: false,
      key: trainings?.key,
    };
    setTrainingHistory([...trainingHistoryTemp, historyObj]);
    setCurrentSelectedTraining(trainings?.key);
    localStorage.setItem("trainingkey", trainings?.key);
    setCurrentTrainings(trainings?.data);
  };

  const updateFilterCount = (optionalCount) => {
    let count = 0;
    for (const [key, value] of Object.entries(selectedFilters)) {
      count += value.length;
    }
    setNoOfFilters(optionalCount ?? count);
  };

  const handleApply = () => {
    const initialSelectedFilters = {};
    Object.keys(filterOptions).forEach((key) => {
      initialSelectedFilters[key] = [];
    });
    setShowFilter(false);
    if (
      JSON.stringify(selectedFilters) !== JSON.stringify(initialSelectedFilters)
    ) {
      getFilteredTrainings();
      updateFilterCount();
      setPrevSelectedFilters(selectedFilters);
      localStorage.setItem("filters", JSON.stringify(selectedFilters));
    } else {
      setSelectedFilters(prevSelectedFilters);
    }
  };

  const handleClearOption = () => {
    const initialSelectedFilters = {};
    Object.keys(filterOptions).forEach((key) => {
      initialSelectedFilters[key] = [];
    });
    setSelectedFilters(initialSelectedFilters);
  };

  const handleClearAndApply = () => {
    const initialSelectedFilters = {};
    Object.keys(filterOptions).forEach((key) => {
      initialSelectedFilters[key] = [];
    });
    setSelectedFilters(initialSelectedFilters);
    setPrevSelectedFilters(initialSelectedFilters);
    updateFilterCount(0);
    localStorage.removeItem("filters");
  };

  return {
    getAllFilters,
    getTrainingHistory,
    isFilterEmpty,
    handleMessage,
    getFilteredTrainings,
    getRecommendedTrainings,
    handleReflectiveSubmit,
    updateFilterCount,
    handleApply,
    handleClearOption,
    handleClearAndApply,
  };
}
