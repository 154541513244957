import React from "react";
import "./MetricsLinearCard.css";
import { Line } from "@ant-design/plots";
import { empty_text } from "../../../../constants/GlobalConstants";

export default function MetricsLinearCard({
  graph,
  data,
  averageGraphCount,
  isEmpty,
  imgPath,
}) {
  const getStepSize = () => {
    const dataPoints = data.length / 2;
    const maxTicks = 4;
    return Math.ceil(dataPoints / maxTicks);
  };

  const LineChart = ({ chartData }) => {
    const stepSize = getStepSize();
    const config = {
      data: chartData,
      xField: "period",
      yField: "value",
      seriesField: "category",
      autofit: true,
      smooth: true,
      xAxis: {
        // type: "time",
        range: [0, 1],
        title: {
          text: "Period", // Set the x-axis label to "Month"
        },
        // tickCount: 7,
        label: {
          autoRotate: false,
          autoHide: false,
          formatter: (text, item, index) => {
            // Control when to display the ticks based on index or condition
            if (index % stepSize !== 0) {
              return "";
            }
            return text;
          },
        },
      },
      yAxis: {
        title: {
          text: graph?.yAxis, // Set the x-axis label to "Month"
        },
        // label: {
        //     formatter: (v) => `${v}h`,
        // },
      },
      legend: false,
      color: ["#85A5FF", "#5FCCBF"],
      lineStyle: {
        lineWidth: 2,
        strokeWidth: 2,
        cursor: "pointer",
      },
    };
    return <Line {...config} />;
  };

  return (
    <div className="gradient-graph-box">
      <div className="graph-heading-content-box">
        <div className="graph-count-box-between">
          <div className="graph-count-box">
            <span className="graph-count-less-bold">
              Qtr. -{" "}
              <span className="graph-count">
                {Math.round(averageGraphCount[graph?.graphName]?.quarterly)}
              </span>
              &nbsp;
            </span>
            <span className="graph-count-text">
              {"("}
              {graph?.unit}
              {") "}
            </span>
          </div>
          <div className="graph-count-box">
            <span className="graph-count-less-bold">
              Yr. -{" "}
              <span className="graph-count">
                {Math.round(averageGraphCount[graph?.graphName]?.annual)}
              </span>
              &nbsp;
            </span>
            <span className="graph-count-text">
              {"("}
              {graph?.unit}
              {")"}
            </span>
          </div>
        </div>
        <div className="graph-heading">{graph.graphName}</div>
      </div>
      {isEmpty ? (
        <div className="empty-usage-data-img-box">
          <img src={imgPath} alt="" className="w-h-100" />
          <span className="empty-text">{empty_text}</span>
        </div>
      ) : (
        <div className="area-chart">
          <LineChart chartData={data} />
        </div>
      )}
    </div>
  );
}
