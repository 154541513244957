import { message } from "antd";
import React, { useState } from "react";
import "./ScenarioGen.css";
import AddScenario from "../../../Components/AddScenario/AddScenario";
import ScenariosTable from "../../../Components/ScenariosTable/ScenariosTable";

export default function ScenarioGen() {
  const [addScenario, setAddScenario] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <div className="sub-page">
      {contextHolder}
      <div className="heading-tab-pad">
        {addScenario ? (
          <div className="heading-tab">
            <span
              className="sub-heading"
              onClick={() => {
                setAddScenario(false);
              }}
            >
              Scenario Generation
            </span>
            <span className="subbed-heading">
              &nbsp;
              {" > "}&nbsp;
              {/* {showContent.length !== 0 ? "Content Details" : "Create Content"} */}
              Add Scenario
            </span>
          </div>
        ) : (
          <span className="content-heading-main"> Scenario Generation</span>
        )}
      </div>
      {addScenario ? (
        <AddScenario
          addScenario={addScenario}
          setAddScenario={setAddScenario}
        />
      ) : (<>
        <div className="training-heading-box">
          <span className="training-content">Scenarios</span>
          <div
            className="create-button"
            onClick={() => {
              setAddScenario(true);
            }}
          >
            Add Scenario
          </div>
        </div>
        <ScenariosTable />
        </>
      )}
    </div>
  );
}
