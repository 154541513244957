import React from "react";
import "./MetricsGradientCard.css";
import { Area } from "@ant-design/charts";
import {
  empty_text,
  lightenColor,
} from "../../../../constants/GlobalConstants";

export default function MetricsGradientCard({
  graph,
  data,
  averageGraphCount,
  isEmpty,
  imgPath,
}) {
  const getStepSize = () => {
    const dataPoints = data.length;
    const maxTicks = 4;
    return Math.ceil(dataPoints / maxTicks);
  };

  // Configurations for the area chart

  const AreaChart = ({ chartData }) => {
    const stepSize = getStepSize();
    const config = {
      data: chartData,
      xField: "period",
      yField: "value",
      isStack: true,
      xAxis: {
        // type: "time",
        range: [0, 1],
        title: {
          text: "Period", // Set the x-axis label to "Month"
        },
        // tickCount: 7,
        label: {
          autoRotate: false,
          autoHide: false,
          formatter: (text, item, index) => {
            // Control when to display the ticks based on index or condition
            if (index % stepSize !== 0) {
              return "";
            }
            return text;
          },
        },
      },
      tooltip: {
        customContent: (title, items) => {
          // Customize the tooltip content here
          const titleStyle =
            "font-size: 16px; color: black; font-weight: 400;display: flex; font-family: Open Sans;padding:0.75rem;";
          return `<div style="${titleStyle}">${title}:  
            ${items.map((item) => `${item?.value}`)}
              </div>`;
        },
        style: {
          fontSize: 16, // Set the font size for the entire tooltip
        },
      },
      yAxis: {
        // type: "time",
        title: {
          text: graph?.yAxis, // Set the x-axis label to "Month"
        },
      },
      //   theme :
      color: graph?.color,
      smooth: true,
      autofit: true,
      areaStyle: () => {
        return {
          fill: `l(270) 0:#ffffff 0.5:${lightenColor(graph?.color)} 1:${
            graph?.color
          }`,
        };
      },
    };
    return <Area {...config} />;
  };
  return (
    <div className="gradient-graph-box">
      <div className="graph-heading-content-box">
        <div className="graph-count-box">
          <span className="graph-count">
            {averageGraphCount[graph?.graphName]}&nbsp;
          </span>
          <span className="graph-count-text">
            {"("}
            {graph?.unit}
            {")"}
          </span>
        </div>
        <div className="graph-heading">{graph.graphName}</div>
      </div>
      {isEmpty ? (
        <div className="empty-usage-data-img-box">
          <img src={imgPath} alt="" className="w-h-100" />
          <span className="empty-text">{empty_text}</span>
        </div>
      ) : (
        <div className="area-chart">
          <AreaChart chartData={data} />
        </div>
      )}
    </div>
  );
}
