import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import SmallView from "./features/smallview/SmallView";
import ExpandedView from "./features/expandedview/ExpandedView";
import Main from "./features/admin/Pages/Main/Main";
import Dashboard from "./features/admin/Pages/Main/Dashboard/Dashboard";
import RecTrainings from "./features/admin/Pages/Main/RecTrainings/RecTrainings";
import QuesGenAI from "./features/admin/Pages/Main/QuesGenAI/QuesGenAI";
import AdminFeedback from "./features/admin/Components/AdminFeedback/AdminFeedback";
import SelfAssessment from "./features/admin/Pages/Main/SelfAssessment/SelfAssessment";
import ConfigAdmins from "./features/admin/Pages/Main/ConfigAdmins/ConfigAdmins";
import ScenarioGen from "./features/admin/Pages/Main/ScenarioGen/ScenarioGen";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={""}>
        <Routes>
          <Route path="/smallview" element={<SmallView />} />
          <Route path="/expandedview" element={<ExpandedView />} />
          <Route path="/admin" element={<Main />}>
            <Route index element={<Navigate to="/admin/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="question-gen-ai" element={<QuesGenAI />} />
            <Route path="recommended-resources" element={<RecTrainings />} />
            <Route path="feedback" element={<AdminFeedback />} />
            <Route path="self-assessment" element={<SelfAssessment />} />
            <Route path="config-admins" element={<ConfigAdmins />} />
            <Route path="scenario" element={<ScenarioGen />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
