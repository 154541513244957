import React, { useContext, useEffect, useState } from "react";
import "./PdfBotContent.css";
import PdfBotHeader from "../PdfBotHeader/PdfBotHeader";
import PdfSection from "../PdfSection/PdfSection";
import { v4 as uuidv4 } from "uuid";
import QuestionsGenAI from "../QuestionsGenAI/QuestionsGenAI";
import { Modal, Tour } from "antd";
import TrainingsRecommended from "../TrainingsRecommended/TrainingsRecommended";
import TrainingInputSection from "../TrainingInputSection/TrainingInputSection";
import { Base_Url } from "../../../../Utils/urls";
import { AuthContext } from "../../../../Context/AuthContext";
import Feedback from "../../../smallview/components/Feedback/Feedback";
import EmptyPdfSection from "../EmptyPdfSection/EmptyPdfSection";
import ReflectiveQuestions from "../../../smallview/components/ReflectiveQuestions/ReflectiveQuestions";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { TourContext } from "../../../../Context/TourContext";
import { AppStateContext } from "../../../../Context/AppStateContext";
import { ConversationContext } from "../../../../Context/ConversationContext";
import { FilterContext } from "../../../../Context/FilterContext";
import { TrainingsContext } from "../../../../Context/TrainingsContext";
import { useAppUtilities } from "../../../../Hooks/useAppUtilities";
import { useConversations } from "../../../../Hooks/useConversations";
import { useRecommendedTrainings } from "../../../../Hooks/useRecommendedTrainings";
import { PdfContext } from "../../../../Context/PdfContext";

dayjs.extend(utc);

let fetchedFilters = JSON.parse(localStorage.getItem("filters"));
export default function PdfBotContent() {
  const { getToken } = useContext(AuthContext);
  //Tour of the UI
  const {
    welcomeMsgRef,
    smallViewSteps,
    expandedViewSteps,
    expandedViewSteps2,
    currentStep,
    setCurrentStep,
    tourOpen,
    setTourOpen,
    currentView,
    setCurrentView,
  } = useContext(TourContext);

  const { toggleTab } = useContext(AppStateContext);

  const {
    parentId,
    quesContext,
    conversationId,
    disabledQuestionRef,
    disabledQuestion,
    defaultContent,
    responses,
    setIsLoadingChat,
  } = useContext(ConversationContext);

  const {
    currentIndex,
    progressSummary,
    setProgressSummary,
    progressQuestions,
    setProgressQuestions,
    setQuestions,
    setSummary,
    pathToFiles,
    setCurrentTab,
    setFile,
  } = useContext(PdfContext);

  const { setNoOfFilters } = useContext(FilterContext);

  const { recTrainings, trainingHistId } = useContext(TrainingsContext);

  const { user_interaction } = useAppUtilities();
  const { handleStopGenerate, getDefaultContent, getConversation } =
    useConversations();
  const { getAllFilters, getTrainingHistory, getRecommendedTrainings } =
    useRecommendedTrainings();

  const ppmdGenerateQuestions = async () => {
    try {
      let accessToken = localStorage.getItem("accessToken") ?? getToken();
      let response = await fetch(`${Base_Url}/ppmd_start_questions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify({}),
        keepalive: true,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      console.log("error:", err);
    }
  };

  //Handle the sessions with user inactivity
  let timeout;
  const [showAlert, setShowAlert] = useState(false);
  const resetTimer = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setShowAlert(true);
      setTourOpen(false);
      let sessionPayload = {
        end_time: dayjs().utc().format("YYYY-MM-DD HH:mm:ss"),
        session_id: sessionStorage.getItem("sessionId"),
      };
      user_interaction("", {}, sessionPayload, {});
      sessionStorage.clear();
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("scroll", handleActivity);
    }, 15 * 60 * 1000); // 15 minutes in milliseconds
  };

  const handleActivity = () => {
    setShowAlert(false);
    resetTimer();
  };

  const handleBeforeUnload = () => {
    // Detect if the page is being closed (tab close) or refreshed
    if (sessionStorage.getItem("sessionId")) {
      let currentTime = dayjs().utc();
      let sessionPayload = {
        end_time: dayjs().utc().format("YYYY-MM-DD HH:mm:ss"),
        session_id: sessionStorage.getItem("sessionId"),
      };
      localStorage.setItem("lastInteractionTime", currentTime);
      user_interaction("", {}, sessionPayload, {}, true);
      ppmdGenerateQuestions();
    }

    localStorage.removeItem("tourFromSmView");
  };

  const handlePageHide = () => {
    if (disabledQuestionRef.current) handleStopGenerate();
    user_interaction("", {}, {}, {}, true);
  };

  useEffect(() => {
    resetTimer();

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("mousedown", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("scroll", handleActivity);
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("pagehide", handlePageHide);
    window.addEventListener("storage", (event) => {
      if (event.key === "ConversationId") {
        conversationId.current = event.newValue;
      }
      if (event.key === "ParentId") {
        parentId.current = event.newValue;
        quesContext.current = true;
        getConversation();
      }
    });

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, []);

  const getFormattedData = (value) => {
    const data = value?.map((text) => {
      if (!/[a-zA-Z]/.test(text.toString())) return [];
      return text.toString().split("\n");
    });
    return data;
  };

  useEffect(() => {
    setSummary([]);
    setQuestions([]);

    const checkProgressSummary = async () => {
      // Make your API request to get progress updates
      try {
        const payload = {
          message: {
            content: {
              path_to_files: pathToFiles,
              summary_generation_tracking_id: uuidv4(),
            },
          },
        };
        const url = `${Base_Url}/generate_summary`;
        let accessToken = await getToken();
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify(payload),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (response.status === 202) {
          // API is still in progress, update the progress
          setProgressSummary(progressSummary + 1);
          setTimeout(checkProgressSummary, 15000); // Check progress every 15 second
        } else if (response.status === 200) {
          setSummary(data.summary);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const checkProgressQuestions = async () => {
      // Make your API request to get progress updates
      try {
        const payload = {
          message: {
            content: {
              path_to_files: pathToFiles,
              question_generation_tracking_id: uuidv4(),
            },
          },
        };
        const url = `${Base_Url}/generate_questions`;
        let accessToken = await getToken();
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify(payload),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (response.status === 202) {
          // API is still in progress, update the progress
          setProgressQuestions(progressQuestions + 1);
          setTimeout(checkProgressQuestions, 15000); // Check progress every 15 second
        } else if (response.status === 200) {
          setQuestions(getFormattedData(data.questions));
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (pathToFiles) {
      checkProgressSummary();
      checkProgressQuestions();
    }
  }, [pathToFiles]);

  useEffect(() => {
    setCurrentTab(0);
  }, [currentIndex]);

  useEffect(() => {
    let currentTime = dayjs().utc();
    let lastInteractionTime =
      localStorage.getItem("lastInteractionTime") || currentTime;
    let expirationTime = localStorage.getItem("expirationTime");
    if (
      !sessionStorage.getItem("sessionId") ||
      currentTime.diff(lastInteractionTime, "minute") > 15
    ) {
      let sessionId = uuidv4();
      sessionStorage.setItem("sessionId", sessionId);
      let sessionPayload = {
        start_time: dayjs().utc().format("YYYY-MM-DD HH:mm:ss"),
        session_id: sessionId,
      };
      user_interaction("", {}, sessionPayload, {});
      if (!expirationTime) {
        localStorage.setItem("expirationTime", currentTime);
      } else if (currentTime.diff(expirationTime, "day") > 0) {
        localStorage.setItem("expirationTime", currentTime);
        conversationId.current = uuidv4();
        parentId.current = null;
        quesContext.current = false;
        localStorage.removeItem("ConversationId");
        localStorage.removeItem("ParentId");
        localStorage.removeItem("filepath");
        localStorage.removeItem("pdf");
        setFile(null);

        trainingHistId.current = uuidv4();
        localStorage.removeItem("filters");
        localStorage.removeItem("trainingkey");
        fetchedFilters = null;
        setNoOfFilters(0);
        setIsLoadingChat(false);
      }
    }
    getDefaultContent();
    getAllFilters();
    getRecommendedTrainings();
  }, []);

  useEffect(() => {
    if (recTrainings.length > 0) {
      getTrainingHistory();
    }
  }, [recTrainings]);

  useEffect(() => {
    if (defaultContent && parentId.current) getConversation();
  }, [defaultContent]);

  return (
    <>
      <div className="pdf-bot-main-box">
        <Modal
          open={showAlert}
          width="25rem"
          centered
          onCancel={() => setShowAlert(false)}
          footer={null}
          closable={false}
          maskClosable={false}
        >
          <div className="session-modal">
            <div>This site has expired. Please refresh the site.</div>
            <div>
              <button
                className="session-modal-button"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </button>
            </div>
            <div className="session-modal-info">
              For security reasons the content on this site expires after 15
              mins of inactivity. You must refresh the site to continue.
            </div>
          </div>
        </Modal>
        <PdfBotHeader />

        <div className="pdf-bot-content-box">
          {toggleTab === 1 ? (
            <PdfSection
              questionsAvailable={
                responses.length > 2 ||
                (responses.length > 0 && !disabledQuestion)
              }
            />
          ) : toggleTab === 2 ? (
            <TrainingsRecommended />
          ) : toggleTab === 3 ? (
            <div className="supertutor-feedback-message">
              <EmptyPdfSection
                text={"Share your feedback for me to improve!"}
              />
            </div>
          ) : toggleTab === 4 ? (
            <div className="supertutor-feedback-message">
              <EmptyPdfSection
                text={
                  "Answer reflective questions about your skills and needs to get personalized learning recommendations."
                }
              />
            </div>
          ) : null}
          <div
            className="vertical-separator"
            style={toggleTab === 3 || toggleTab === 4 ? { height: "100%" } : {}}
          ></div>
          <div className="pdf-chat-section-box">
            {toggleTab === 1 ? (
              <>
                <QuestionsGenAI />
              </>
            ) : toggleTab === 2 ? (
              <TrainingInputSection />
            ) : toggleTab === 3 ? (
              <div
                style={{
                  marginTop: "0.6rem",
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                <Feedback />
              </div>
            ) : toggleTab === 4 ? (
              <div
                style={{
                  marginTop: "0.6rem",
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                <ReflectiveQuestions />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Tour
        open={tourOpen}
        onClose={() => {
          welcomeMsgRef.current = false;
          setTourOpen(false);
          setCurrentStep(0);
          setCurrentView(2);
          if (!localStorage.getItem("isFirstTourUser")) {
            user_interaction("", {}, {}, {}, true);
          }
          localStorage.removeItem("tourFromExView");
          localStorage.removeItem("tourFromSmView");
        }}
        disabledInteraction={true}
        steps={currentView === 1 ? expandedViewSteps : expandedViewSteps2}
        rootClassName="antd-tour-modal"
        current={currentStep}
        indicatorsRender={(current, total) => (
          <span className="tour-step-indicator">
            {currentView === 1 ? smallViewSteps.length + current : current + 1}{" "}
            /{" "}
            {currentView === 1
              ? smallViewSteps.length + expandedViewSteps.length - 1
              : expandedViewSteps2.length}
          </span>
        )}
      />
    </>
  );
}
