import React from "react";
import "./Checkbox.css";

const Checkbox = ({ label, checked, onChange }) => {
  return (
    <div className="custom-checkbox-2">
      <input type="checkbox" id={label} checked={checked} onChange={onChange} />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

export default Checkbox;
