import React, { useContext, useEffect, useState } from "react";
import "./AddCategory.css";
import {
  AutoComplete,
  Input,
  Modal,
  Result,
  Spin,
  Tooltip,
  message,
} from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../../Context/AuthContext";
import { Base_Url_Admin } from "../../../../Utils/urls";
import {
  category_des,
  category_guidelines,
  containsAlphabetsOrNumbers,
  handleValidationSelfAsses,
  null_entries_text,
  option_guidelines,
  options_desc,
  questions_des,
  questions_guidelines,
  resource_links_error_msg,
  submit_text,
} from "../../../../constants/GlobalConstants";
import AdminModal from "../AdminModal/AdminModal";
import SelfAssessTooltip from "../SelfAssessTooltip/SelfAssessTooltip";
export default function AddCategory({
  setAddCategory,
  trainingLinks,
  formatted_list_trainings,
  getAssessmentData,
}) {
  const [options, setOptions] = useState([]);
  const [category, setCategory] = useState({});
  const [questions, setQuestions] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryLinkInput, setCategoryLinkInput] = useState("");
  const { getToken } = useContext(AuthContext);
  const [linkInput, setLinkInput] = useState({});
  const [openModal, setOpenModal] = useState({});
  const handleSearch = (value) => {
    if (value.trim() === "") {
      setOptions([]); // Clear options when there is no text
      return;
    }
    const filteredOptions = trainingLinks
      .filter((entry) => entry.name.toLowerCase().includes(value.toLowerCase()))
      .slice(0, 5);
    let fil_options = filteredOptions.map((entry) => ({
      value: entry.id,
      label: entry.name,
    }));
    setOptions(fil_options);
  };
  const onSelect = (value) => {
    setCategoryLinkInput("");
    if (category?.links !== undefined && category?.links.includes(value)) {
      messageApi.open({
        type: "error",
        content: resource_links_error_msg,
      });
      return;
    }

    let new_links = category?.links ? [...category.links, value] : [value];
    setCategory({ ...category, links: new_links });
    setOptions([]);
  };
  const handleCategoryName = (value) => {
    setCategory({ ...category, name: value });
  };
  const handleCategoryDescription = (value) => {
    setCategory({ ...category, description: value });
  };
  const handleRemoveLink = (value) => {
    let current_links = category?.links.filter((item) => item !== value);
    setCategory({ ...category, links: current_links });
  };
  const handleRemoveOptionsLink = (index, index2, value) => {
    let filterd_item = [...questions];
    filterd_item[index].options[index2].option_links = filterd_item[
      index
    ].options[index2].option_links.filter((item) => item !== value);
    setQuestions(filterd_item);
  };
  const handleAddQuestions = () => {
    setQuestions([...questions, { name: "", options: [] }]);
  };
  const handleQuestionText = (index, value) => {
    let new_questions = [...questions];
    new_questions[index].name = value;
    setQuestions(new_questions);
  };
  const handleOptionName = (index, index2, value) => {
    let new_questions = [...questions];
    new_questions[index].options[index2].option_text = value;
    setQuestions(new_questions);
  };

  const handleAddOptions = (value) => {
    let new_options = [...questions];
    new_options[value].options.push({ option_text: "", option_links: [] });
    setQuestions(new_options);
  };
  const onSelectOptions = (e, index, index2) => {
    let new_options = [...questions];
    let input = { ...linkInput };
    let x = {};
    x[index2] = "";
    input[index] = x;
    setLinkInput(input);
    if (new_options[index].options[index2].option_links.includes(e)) {
      messageApi.open({
        type: "error",
        content: resource_links_error_msg,
      });
      return;
    }
    new_options[index].options[index2].option_links.push(e);
    setQuestions(new_options);
    setOptions([]);
  };
  const handleDeleteQuestion = (value) => {
    setOpenModal({});
    let edited_questions = [...questions];
    edited_questions = edited_questions.filter(
      (item, index) => index !== value
    );
    setQuestions(edited_questions);
    return;
  };
  const handleDeleteOptions = (index, index2) => {
    setOpenModal({});
    let edited_result = [...questions];
    edited_result[index].options = edited_result[index].options.filter(
      (item, idk) => idk !== index2
    );
    setQuestions(edited_result);
    return;
  };
  const handleLinkInputUpdate = (value, index, index2) => {
    let input = { ...linkInput };
    let x = {};
    x[index2] = value;
    input[index] = x;
    setLinkInput(input);
  };

  const handleSaveCategory = async (payload) => {
    try {
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/resource_skill_assessment`,
        {
          method: "POST",
          headers: {
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      console.log("result :", result, response);
      if (response.ok) {
        messageApi.open({
          type: "success",
          content: result?.message,
        });
        setTimeout(() => {
          // After the operation is complete, set loading state back to false
          setOpenModal({});
          setCategory({});
          setQuestions([]);
          setIsLoading(false);
          getAssessmentData();
        }, 1500);
      } else {
        messageApi.open({
          type: "error",
          content: result?.errors[0],
        });
        setOpenModal({});
        setIsLoading(false);
      }
    } catch (e) {
      console.log("error:", e);
    }
  };

  const ValidationCheck = () => {
    if (isLoading) return;
    else setIsLoading(true);
    let payload = { ...category };
    payload["links"] = category.links ? category.links : [];
    payload["questions"] = questions;
    payload["description"] = category?.description ? category?.description : "";

    let isValid = handleValidationSelfAsses(payload);
    if (isValid === -1 || category?.description === undefined || !containsAlphabetsOrNumbers(category.description)) {
      message.error(null_entries_text);
      setOpenModal({});
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      return;
    }
    setOpenModal({
      content: submit_text,
      func: () => handleSaveCategory(payload),
    });
  };
  return (
    <div className="collapse-add-category-section">
      {contextHolder}
      <div className="description-box-self-ass">
        <div className="self-ass-category-heading">
          Enter category details
          <SelfAssessTooltip
            heading="Guidelines:"
            value={category_guidelines}
          />
        </div>
        <span className="sub-content mt-8">{category_des}</span>
      </div>
      <div className="add-ctaegory-content-box">
        <div className="enter-category-section">
          <span className="section-text">
            <img src="/assets/_.svg" className="imp-logo-pos" alt="" />
            Category Name
          </span>
          <input
            // value={category?.name ? category.name : ""}
            className="category-name-input"
            // placeholder="Enter category name"
            onChange={(e) => {
              handleCategoryName(e.target.value);
            }}
          />
        </div>
        <div className="enter-category-section">
          <span className="section-text">Category Resource Links</span>
          <AutoComplete
            options={options}
            onSelect={onSelect}
            value={categoryLinkInput}
          >
            <Input
              addonBefore={<SearchOutlined />}
              // placeholder="Search resource link"
              className="category-search-bar"
              onChange={(e) => {
                setCategoryLinkInput(e.target.value);
                handleSearch(e.target.value);
              }}
              allowClear
            />
          </AutoComplete>
        </div>
      </div>
      <div className="enter-category-desc-section">
        <span className="section-text">
          <img src="/assets/_.svg" className="imp-logo-pos" alt="" />
          Category Description
        </span>
        <textarea
          className="category-desc-input"
          // placeholder="Enter category name"
          onChange={(e) => {
            handleCategoryDescription(e.target.value);
          }}
        />
      </div>
      {category?.links && category?.links.length !== 0 && (
        <div className="form-group-col-3">
          <span className="section-text">Selected Resource Link</span>
          <div className="keywords-box">
            {category?.links.map((item) => {
              return (
                <div className="keyword-batch">
                  <span>
                    <a
                      href={formatted_list_trainings[item].link}
                      className="resouce-link-color"
                    >
                      {formatted_list_trainings[item].name}
                    </a>
                  </span>
                  <CloseOutlined
                    className="cursor-p"
                    onClick={() => {
                      handleRemoveLink(item);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="category-section-buttons">
        <div className="add-new-button" onClick={handleAddQuestions}>
          Add a question
        </div>
      </div>
      {questions?.length !== 0 &&
        questions?.map((item, index) => {
          return (
            <div className="collapse-add-question-section">
              <div className="description-box-self-ass">
                <div className="add-ctaegory-content-box">
                  <div className="self-ass-category-heading">
                    Enter question details
                    <SelfAssessTooltip
                      heading="Guidelines:"
                      value={questions_guidelines}
                    />
                  </div>
                  <div className="collapse-option-icons">
                    <img
                      src="/assets/DeleteOutlined.svg"
                      className="icon-sizing"
                      onClick={() => {
                        setOpenModal({
                          content:
                            "Are you sure you want to delete this question?",
                          func: () => handleDeleteQuestion(index),
                        });
                      }}
                    />
                  </div>
                </div>
                <span className="sub-content mt-8">{questions_des}</span>
              </div>
              <div className="add-question-content-box">
                <div className="enter-question-section">
                  <span className="section-text">
                    <img src="/assets/_.svg" className="imp-logo-pos" alt="" />
                    Question
                  </span>
                  <input
                    value={item?.name}
                    className="category-name-input"
                    // placeholder="Enter question"
                    onChange={(e) => {
                      handleQuestionText(index, e.target.value);
                    }}
                  />
                </div>
                <div
                  className="add-new-button"
                  onClick={() => {
                    handleAddOptions(index);
                  }}
                >
                  Add an option
                </div>
              </div>

              {questions[index]?.options.length !== 0 && (
                <div className="questions-option-main-box">
                  <div className="description-box-self-ass">
                    <div className="add-ctaegory-content-box">
                      <div className="self-ass-category-heading">
                        Enter option details
                        <SelfAssessTooltip
                          heading="Guidelines:"
                          value={option_guidelines}
                        />
                      </div>
                    </div>
                    <span className="sub-content mt-8">{options_desc}</span>
                  </div>
                  {questions[index].options.map((item, index2) => {
                    return (
                      <>
                        <div className="options-divider">
                          <div className="options-divider-shade" />
                          <span className="options-text">
                            Option {index2 + 1}
                          </span>
                          <div className="options-divider-shade" />
                          <img
                            src="/assets/DeleteOutlined.svg"
                            className="icon-sizing ml-14"
                            onClick={() => {
                              setOpenModal({
                                content:
                                  "Are you sure you want to delete this option?",
                                func: () => handleDeleteOptions(index, index2),
                              });
                            }}
                          />
                        </div>
                        <div className="add-ctaegory-content-box">
                          <div className="enter-category-section">
                            <span className="section-text">
                              <img
                                src="/assets/_.svg"
                                className="imp-logo-pos"
                                alt=""
                              />
                              Option {index2 + 1}
                            </span>
                            <input
                              value={item?.option_text}
                              className="category-name-input"
                              // placeholder={`Enter option ${index2 + 1}`}
                              onChange={(e) => {
                                handleOptionName(index, index2, e.target.value);
                              }}
                            />
                          </div>
                          <div className="enter-category-section">
                            <span className="section-text">
                              Option Resource Links
                            </span>
                            <AutoComplete
                              options={options}
                              onSelect={(e) => {
                                onSelectOptions(e, index, index2);
                              }}
                              value={
                                linkInput[index]
                                  ? linkInput[index][index2]
                                    ? linkInput[index][index2]
                                    : ""
                                  : " "
                              }
                            >
                              <Input
                                addonBefore={<SearchOutlined />}
                                // placeholder="Search resource link"
                                className="category-search-bar"
                                onChange={(e) => {
                                  handleLinkInputUpdate(
                                    e.target.value,
                                    index,
                                    index2
                                  );
                                  handleSearch(e.target.value);
                                }}
                                allowClear
                              />
                            </AutoComplete>
                          </div>
                        </div>
                        {questions[index].options[index2].option_links
                          .length !== 0 && (
                          <div className="form-group-col-3">
                            <span className="section-text">
                              Selected Resource Links
                            </span>
                            <div className="keywords-box">
                              {questions[index].options[
                                index2
                              ].option_links.map((item) => {
                                return (
                                  <div className="keyword-batch">
                                    <span>
                                      <a
                                        href={
                                          formatted_list_trainings[item].link
                                        }
                                        className="resouce-link-color"
                                      >
                                        {formatted_list_trainings[item].name}
                                      </a>
                                    </span>
                                    <CloseOutlined
                                      className="cursor-p"
                                      onClick={() => {
                                        handleRemoveOptionsLink(
                                          index,
                                          index,
                                          item
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      <div className="category-section-buttons">
        <div className="close-save">
          <div
            className="add-new-button"
            onClick={() => {
              setAddCategory(false);
            }}
          >
            Close
          </div>
          <div
            className={
              isLoading ? "add-category-button cursor-n" : "add-category-button"
            }
            onClick={() => {
              ValidationCheck();
            }}
          >
            Save
          </div>
        </div>
      </div>
      <AdminModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
}
