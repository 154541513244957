import React, { useContext, useEffect, useRef, useState } from "react";
import "./SelfAssessment.css";
import {
  AutoComplete,
  Collapse,
  Input,
  Modal,
  Spin,
  Tooltip,
  message,
} from "antd";
import AddCategory from "../../../Components/AddCategory/AddCategory";
import {
  handleValidationSelfAsses,
  null_entries_text,
  option_guidelines,
  options_desc,
  questions_des,
  questions_guidelines,
  resource_links_error_msg,
  self_asses_guidelines,
  submit_text,
} from "../../../../../constants/GlobalConstants";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../../../Context/AuthContext";
import EditCategory from "../../../Components/EditCategory/EditCategory";
import { Base_Url, Base_Url_Admin } from "../../../../../Utils/urls";
import AdminModal from "../../../Components/AdminModal/AdminModal";
import SelfAssessTooltip from "../../../Components/SelfAssessTooltip/SelfAssessTooltip";
let formatted_list_trainings = {};
export default function SelfAssessment() {
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState({});
  const [editCategory, setEditCategory] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [options, setOptions] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [trainingLinks, setTrainingLinks] = useState([]);
  const [showQuestion, setShowQuestion] = useState(0);
  const [newQuestions, setNewQuestions] = useState([]);
  const [newOptions, setNewOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { getToken } = useContext(AuthContext);
  const [deleteCategory, setDeleteCategory] = useState("");
  const [openModal, setOpenModal] = useState({});
  const scrollingDivRef = useRef(null);
  const [addCategory, setAddCategory] = useState(false);
  const [linkInput, setLinkInput] = useState({});
  const [linkNewInput, setNewLinkInput] = useState({});
  const handleCollapse = (value, isOpen) => {
    if (isOpen) {
      setSelectedItems(value);
      setAddCategory(false);
    } else {
      setSelectedItems("");
      setSelectedItems("");
      setNewQuestions([]);
      setNewOptions({});
      setShowQuestion(0);
    }
  };
  const handleClose = () => {
    setSelectedItems("");
    setShowQuestion(0);
    setNewQuestions([]);
    setNewOptions({});
  };
  const getTrainingLinks = async () => {
    try {
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url}/list_trainings?admin=true`, {
        method: "POST",
        headers: {
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify({
          action: "all",
        }),
      });
      let result = await response.json();
      result?.data.map((item) => {
        formatted_list_trainings[item.id] = {
          name: item.name,
          link: item.link,
        };
      });
      setTrainingLinks(result?.data);
    } catch (e) {
      console.log("error:", e);
    }
  };

  const getAssessmentData = async () => {
    try {
      setCategories([]);
      setQuestions({});
      if (selectedItems.length !== 0) setSelectedItems("");
      if (showQuestion !== 0) setShowQuestion(0);
      if (addCategory) setAddCategory(false);
      if (editCategory.length !== 0) setEditCategory("");
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/resource_skill_assessment`,
        {
          method: "GET",
          headers: {
            "X-Api-Token": accessToken,
          },
        }
      );
      let result = await response.json();
      setCategories(result.resources);
      setQuestions(result?.questions);
      // console.log("result:", result);
    } catch (e) {
      console.log("error:", e);
    }
  };
  const handleSearch = (value) => {
    if (value.trim() === "") {
      setOptions([]); // Clear options when there is no text
      return;
    }
    const filteredOptions = trainingLinks
      .filter((entry) => entry.name.toLowerCase().includes(value.toLowerCase()))
      .slice(0, 5);
    let fil_options = filteredOptions.map((entry) => ({
      value: entry.id,
      label: entry.name,
    }));
    setOptions(fil_options);
  };
  const handleDeleteCategory = async () => {
    try {
      if (isLoading) return;
      else setIsLoading(true);
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/resource_skill_assessment`,
        {
          method: "DELETE",
          headers: {
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify({ resource_id: deleteCategory }),
        }
      );
      const result = await response.json();
      if (response.ok) {
        messageApi.open({
          type: "success",
          content: "Category successfully deleted!",
        });
        setTimeout(() => {
          setDeleteCategory("");
          setEditCategory("");
          getAssessmentData();
          setIsLoading(false);
        }, 1500);
      } else {
        messageApi.open({
          type: "error",
          content: result?.message,
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const handleCollapseQuestion = (value, isOpen) => {
    if (isOpen) setShowQuestion(value);
    else setShowQuestion(0);
  };

  const scrollToBottom = () => {
    const divElement = scrollingDivRef.current;
    if (divElement) {
      const scrollHeight = divElement.scrollHeight;
      const clientHeight = divElement.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      divElement.scrollTo({
        top: maxScrollTop,
        behavior: "smooth",
      });
    }
  };
  const handleViewOptionResLinkText = (value, index2, index) => {
    let input = { ...linkInput };
    let x = {};
    x[index] = value;
    input[index2] = x;
    setLinkInput(input);
  };
  const handleNewQuestionResLink = (value, index2, index) => {
    let input = { ...linkNewInput };
    let x = {};
    x[index] = value;
    input[index2] = x;
    setNewLinkInput(input);
  };
  const handleViewAddOptions = (value) => {
    //adding options entry in viw recieved question
    let currentState = { ...newOptions };

    currentState[value] = currentState[value]
      ? [...currentState[value], { text: "", id: "", opt_trainings: [] }]
      : [{ text: "", id: "", opt_trainings: [] }];
    setNewOptions(currentState);
    if (showQuestion !== value) handleCollapseQuestion(value, true);
    return;
  };
  const handleViewAddOptionsNew = (value) => {
    //adding options in a newly added question
    let current_value = [...newQuestions];
    current_value[value].options.push({
      text: "",
      opt_trainings: [],
    });
    setNewQuestions(current_value);
  };
  const handleNewOptionName = (index, index2, value) => {
    //changing option name in new added question
    let current_value = [...newQuestions];
    current_value[index].options[index2].text = value;
    setNewQuestions(current_value);
  };
  const onSelectNewQuestionOptions = (value, index, index2) => {
    let current_value = [...newQuestions];
    let input = { ...linkNewInput };
    let x = {};
    x[index2] = "";
    input[index] = x;
    setNewLinkInput(input);

    let isAdded = false;
    if (current_value[index].options[index2].opt_trainings?.length !== 0) {
      isAdded = current_value[index].options[index2].opt_trainings.some(
        (item) => item === value
      );
    }
    if (isAdded) {
      messageApi.open({
        type: "error",
        content: resource_links_error_msg,
      });
      return;
    }
    current_value[index].options[index2].opt_trainings = [
      ...current_value[index].options[index2].opt_trainings,
      value,
    ];
    setNewQuestions(current_value);
    return;
  };
  const handleRemoveLinkNewQuestionOptions = (value, index, index2) => {
    let current_value = [...newQuestions];
    current_value[index].options[index2].opt_trainings = current_value[
      index
    ].options[index2].opt_trainings.filter((item) => item !== value);
    setNewQuestions(current_value);
    return;
  };
  const handleViewQuestionTextAdd = (value, index) => {
    //changing question name in new added ques
    let current_value = [...newQuestions];
    current_value[index].question = value;
    setNewQuestions(current_value);
  };
  const handleViewNewOptionName = (index, index2, value) => {
    let currentOptions = { ...newOptions };
    currentOptions[index][index2].text = value;
    setNewOptions(currentOptions);
  };
  const handleViewSelectOptions = (value, index, index2) => {
    let currentOptions = { ...newOptions };
    let input = { ...linkInput };
    let x = {};
    x[index2] = "";
    input[index] = x;
    setLinkInput(input);
    let isAdded = false;
    if (currentOptions[index][index2].opt_trainings.length !== 0) {
      isAdded = currentOptions[index][index2].opt_trainings.some(
        (item) => item === value
      );
    }
    if (isAdded) {
      messageApi.open({
        type: "error",
        content: resource_links_error_msg,
      });
      return;
    }
    currentOptions[index][index2].opt_trainings = [
      ...currentOptions[index][index2].opt_trainings,
      value,
    ];
    setNewOptions(currentOptions);
  };
  const handleRemoveLinkViewQuestionOptions = (value, index, index2) => {
    let currentOptions = { ...newOptions };
    currentOptions[index][index2].opt_trainings = currentOptions[index][
      index2
    ].opt_trainings.filter((item) => item !== value);
    setNewOptions(currentOptions);
  };
  const handleSaveViewForm = async (payload) => {
    try {
      // if (isLoading) return;
      // else setIsLoading(true);
      // const prev_ques_map = questions[selectedItems].data.map((item) => {
      //   let prev_options = item.options.map((item2) => {
      //     return {
      //       option_id: item2.id,
      //       option_text: item2.text,
      //       option_links: item2.opt_trainings.map((item) => item.id),
      //     };
      //   });
      //   let added_options =
      //     newOptions[item.question_id] === undefined
      //       ? []
      //       : newOptions[item.question_id].map((item2) => {
      //           return {
      //             option_id: item2.id,
      //             option_text: item2.text,
      //             option_links: item2.opt_trainings,
      //           };
      //         });
      //   return {
      //     question_id: item.question_id,
      //     name: item.question,
      //     options: [...prev_options, ...added_options],
      //   };
      // });
      // const new_ques_map = newQuestions.map((item) => {
      //   return {
      //     question_id: item.question_id,
      //     name: item.question,
      //     options: item.options.map((item) => {
      //       return {
      //         option_links: item.opt_trainings,
      //         option_text: item.text,
      //         option_id: "",
      //       };
      //     }),
      //   };
      // });
      // let payload = {
      //   resource_id: selectedItems,
      //   name: categories[index].name,
      //   links: categories[index].cat_trainings.map((item) => item.id),
      //   questions: [...prev_ques_map, ...new_ques_map],
      //   readOnly: false,
      // };
      // let isValid = handleValidationSelfAsses(payload);
      // if (isValid === -1) {
      //   message.error(null_entries_text);
      //   setOpenModal({});
      //   setTimeout(() => {
      //     setIsLoading(false);
      //   }, 1000);
      //   return;
      // }
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/resource_skill_assessment`,
        {
          method: "PATCH",
          headers: {
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      if (response.ok) {
        messageApi.open({
          type: "success",
          content: result?.message,
        });
        setTimeout(() => {
          // After the operation is complete, set loading state back to false
          setOpenModal({});
          setIsLoading(false);
          getAssessmentData();
          setNewQuestions([]);
          setNewOptions({});
        }, 1500);
      } else {
        messageApi.open({
          type: "error",
          content: result?.message,
        });
        setOpenModal({});
        setIsLoading(false);
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const ValidationCheck = (index) => {
    if (isLoading) return;
    else setIsLoading(true);
    const prev_ques_map = questions[selectedItems].data.map((item) => {
      let prev_options = item.options.map((item2) => {
        return {
          option_id: item2.id,
          option_text: item2.text,
          option_links: item2.opt_trainings.map((item) => item.id),
        };
      });
      let added_options =
        newOptions[item.question_id] === undefined
          ? []
          : newOptions[item.question_id].map((item2) => {
              return {
                option_id: item2.id,
                option_text: item2.text,
                option_links: item2.opt_trainings,
              };
            });
      return {
        question_id: item.question_id,
        name: item.question,
        options: [...prev_options, ...added_options],
      };
    });
    const new_ques_map = newQuestions.map((item) => {
      return {
        question_id: item.question_id,
        name: item.question,
        options: item.options.map((item) => {
          return {
            option_links: item.opt_trainings,
            option_text: item.text,
            option_id: "",
          };
        }),
      };
    });
    let payload = {
      resource_id: selectedItems,
      name: categories[index].name,
      links: categories[index].cat_trainings.map((item) => item.id),
      questions: [...prev_ques_map, ...new_ques_map],
      readOnly: false,
    };
    let isValid = handleValidationSelfAsses(payload);
    if (isValid === -1) {
      message.error(null_entries_text);
      setOpenModal({});
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      return;
    }
    setOpenModal({
      content: submit_text,
      func: () => handleSaveViewForm(payload),
    });
  };
  const handleDeleteQuestion = (value) => {
    setOpenModal({});
    let edited_questions = [...newQuestions];
    edited_questions = edited_questions.filter(
      (item, index) => index !== value
    );
    setNewQuestions(edited_questions);
    return;
  };
  const handleDeleteOptions = (index, index2) => {
    setOpenModal({});
    const new_questions = [...newQuestions];
    const edited_options = new_questions[index].options.filter(
      (item, idx) => idx !== index2
    );
    let new_edited_questions = [];
    new_questions.map((item, idk) => {
      if (idk === index) {
        let x = {};
        x.question = item.question;
        x.question_id = item.question_id;
        x.options = edited_options;
        new_edited_questions.push(x);
      } else {
        new_edited_questions.push(item);
      }
    });
    setNewQuestions(new_edited_questions);
    return;
  };
  const handleDeleteViewOptions = (index, index2) => {
    setOpenModal({});
    let new_options = { ...newOptions };
    new_options[index] = new_options[index].filter(
      (item, idk) => idk !== index2
    );
    setNewOptions(new_options);
    return;
  };
  useEffect(() => {
    getAssessmentData();
    getTrainingLinks();
  }, []);

  useEffect(() => {
    if (addCategory) scrollToBottom();
  }, [addCategory]);
  if (
    trainingLinks.length === 0 ||
    Object.keys(formatted_list_trainings).length === 0 ||
    Object.keys(categories).length === 0 ||
    Object.keys(questions).length === 0
  ) {
    return (
      <div className="self-asses-loader">
        <Spin />
      </div>
    );
  }
  return (
    <>
      {contextHolder}
      <div className="sub-page" ref={scrollingDivRef}>
        <div className="description-box-self-ass">
          <div className="self-ass-header">
            <div className="sa-header-text-box">
              <span className="sa-header-heading">
                Reflective Questions and Resources
                <SelfAssessTooltip
                  heading="Guidelines for mapping categories, questions and
                      resources:"
                  value={self_asses_guidelines}
                />
              </span>
              <span className="sa-header-content">
                Define the categories, questions, options and map the resources
                for reflective assessments through this section.
              </span>
            </div>
            <div
              className="add-category-button"
              onClick={() => {
                setAddCategory(true);
                setSelectedItems("");
                setEditCategory("");
                setShowQuestion(0);
                setNewQuestions([]);
                setNewOptions({});
              }}
            >
              Add category
            </div>
          </div>
          {/* <span className="sub-content mt-8 mt-8">
          Define the categories, questions, options and map resources for
          reflective assessments through this section. You may add resources for
          a complete category or for specific options. View details of the
          existing categories below and Click on 'Add Category' to define a new
          category.
        </span> */}
        </div>
        <div className="self-ass-content-box">
          {categories.map((item, indexCategory) => {
            return (
              // <div className="collapse-style">
              editCategory === item.id ? (
                <EditCategory
                  setEditCategory={setEditCategory}
                  categoryInfo={item}
                  questionsInfo={questions[item.id]}
                  trainingLinks={trainingLinks}
                  formatted_list_trainings={formatted_list_trainings}
                  getAssessmentData={getAssessmentData}
                  setDeleteCategory={setDeleteCategory}
                  setIsLoading={setIsLoading}
                />
              ) : (
                <div className="collapse-style">
                  <div
                    className={
                      selectedItems === item.id
                        ? "box-9 divider-bottom"
                        : "box-9"
                    }
                  >
                    <span className="self-ass-category-heading">
                      {item.name}
                    </span>
                    <div className="collapse-option-icons">
                      {questions[item.id]["isAllAbove"] === undefined && (
                        <img
                          src="/assets/Edit.svg"
                          className="icon-sizing"
                          onClick={() => {
                            setEditCategory(item.id);
                          }}
                        />
                      )}
                      {questions[item.id]["isAllAbove"] === undefined ? (
                        !questions[item.id]?.isReadOnly && (
                          <img
                            src="/assets/DeleteOutlined.svg"
                            className="icon-sizing"
                            onClick={() => {
                              setDeleteCategory(item.id);
                            }}
                          />
                        )
                      ) : (
                        <></>
                      )}

                      {selectedItems === item.id ? (
                        <img
                          src="/assets/collapse-up.svg"
                          className="icon-sizing"
                          onClick={() => {
                            handleCollapse(item.id, false);
                          }}
                        />
                      ) : (
                        <img
                          src="/assets/collapse-down.svg"
                          className="icon-sizing"
                          onClick={() => {
                            handleCollapse(item.id, true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {selectedItems === item.id &&
                    !questions[selectedItems]?.isReadOnly && (
                      <div className="sa-category-open">
                        {/* {questions[item.id]["isAllAbove"] !== undefined && (
                          <span className="sub-content mt-8">
                            This category contains automatically mapped
                            questions, options and resources for all the other
                            categories.
                          </span>
                        )} */}
                        <span className="sub-content mt-8">
                          {questions[item.id]["isAllAbove"] === undefined ? item?.description  : "This category contains automatically mapped questions, options and resources for all the other categories."}
                        </span>
                        <div className="resouce-link-box">
                          <div className="resource-left-box">
                            <span className="section-text fw-600">
                              Category Resource Links
                            </span>
                            {/* {item.cat_trainings.length !== 0 && } */}
                            <div className="category-link-box">
                              {item.cat_trainings.length !== 0 &&
                                item.cat_trainings.map((item) => {
                                  return (
                                    <span className="category-link-content-box">
                                      <a
                                        href={item.link}
                                        className="resouce-link-color"
                                      >
                                        {item.name}
                                      </a>
                                    </span>
                                  );
                                })}
                            </div>
                          </div>
                          {questions[item.id] &&
                            questions[item.id]["isAllAbove"] === undefined && (
                              <div
                                className="add-new-button"
                                onClick={() => {
                                  setNewQuestions([
                                    ...newQuestions,
                                    {
                                      question: "",
                                      question_id: "",
                                      options: [],
                                    },
                                  ]);
                                }}
                              >
                                Add a question
                              </div>
                            )}
                        </div>
                        {questions[selectedItems]?.data.length !== 0 && (
                          <div className="collapse-add-question-section">
                            {questions[selectedItems]?.data.map(
                              (item, index) => {
                                return (
                                  // <div className="collapse-style">
                                  <>
                                    <div className="self-view-question">
                                      <div className="view-category-question-box">
                                        <span className="section-text fw-600">
                                          Question {index + 1}
                                        </span>
                                        <span className="section-text">
                                          {item.question}
                                        </span>
                                      </div>
                                      <div className="view-category-question-box-1">
                                        <span className="section-text">
                                          Options: {item.options.length}
                                        </span>

                                        <div className="collapse-option-icons">
                                          {questions[selectedItems] &&
                                            questions[selectedItems][
                                              "isAllAbove"
                                            ] === undefined && (
                                              <div
                                                className="add-new-button"
                                                onClick={() => {
                                                  handleViewAddOptions(
                                                    item.question_id
                                                  );
                                                }}
                                              >
                                                Add an option
                                              </div>
                                            )}

                                          {showQuestion === item.question_id ? (
                                            <img
                                              src="/assets/collapse-up.svg"
                                              className="icon-sizing"
                                              onClick={() => {
                                                handleCollapseQuestion(
                                                  item.question_id,
                                                  false
                                                );
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src="/assets/collapse-down.svg"
                                              className="icon-sizing"
                                              onClick={() => {
                                                handleCollapseQuestion(
                                                  item.question_id,
                                                  true
                                                );
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {showQuestion === item.question_id &&
                                      item.options.length !== 0 && (
                                        <div className="view-options-box-bg">
                                          {item.options.map((item, index) => {
                                            return (
                                              <div className="view-options-box">
                                                <div className="view-options-box-left">
                                                  <span className="section-text fw-600">
                                                    Option {index + 1}
                                                  </span>
                                                  <span className="section-text">
                                                    {item.text}
                                                  </span>
                                                </div>
                                                <div className="view-options-box-right">
                                                  <span className="section-text fw-600">
                                                    Option Resource Links
                                                  </span>
                                                  <div className="category-link-box">
                                                    {item.opt_trainings.map(
                                                      (item) => {
                                                        return (
                                                          <div className="category-link-content-box w-45">
                                                            <a
                                                              href={item.link}
                                                              className="resouce-link-color"
                                                            >
                                                              {item.name}
                                                            </a>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                    {item.opt_trainings
                                                      .length == 0 && " - "}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )}
                                    {showQuestion === item.question_id &&
                                      newOptions[item.question_id] !==
                                        undefined &&
                                      newOptions[item.question_id].length !==
                                        0 && (
                                        <div className="options-box-self-ass">
                                          {newOptions[item.question_id].map(
                                            (item2, index) => {
                                              return (
                                                <div className="questions-option-main-box">
                                                  <div className="options-divider">
                                                    <div className="options-divider-shade" />
                                                    <span className="options-text">
                                                      Option{" "}
                                                      {index +
                                                        item.options.length +
                                                        1}
                                                    </span>
                                                    <div className="options-divider-shade" />
                                                    <img
                                                      src="/assets/DeleteOutlined.svg"
                                                      className="icon-sizing ml-14"
                                                      onClick={() => {
                                                        setOpenModal({
                                                          content:
                                                            "Are you sure you want to delete this option?",
                                                          func: () =>
                                                            handleDeleteViewOptions(
                                                              item.question_id,
                                                              index
                                                            ),
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="add-ctaegory-content-box">
                                                    <div className="enter-category-section">
                                                      <span className="section-text">
                                                        <img
                                                          src="/assets/_.svg"
                                                          className="imp-logo-pos"
                                                          alt=""
                                                        />
                                                        Option{" "}
                                                        {index +
                                                          item.options.length +
                                                          1}
                                                      </span>
                                                      <input
                                                        className="category-name-input"
                                                        // placeholder={`Enter option ${
                                                        //   index +
                                                        //   item.options.length +
                                                        //   1
                                                        // }`}
                                                        onChange={(e) => {
                                                          handleViewNewOptionName(
                                                            showQuestion,
                                                            index,
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="enter-category-section">
                                                      <span className="section-text">
                                                        Option Resource Links
                                                      </span>
                                                      <AutoComplete
                                                        options={options}
                                                        onSelect={(e) => {
                                                          handleViewSelectOptions(
                                                            e,
                                                            showQuestion,
                                                            index
                                                          );
                                                        }}
                                                        value={
                                                          linkInput[
                                                            showQuestion
                                                          ]
                                                            ? linkInput[
                                                                showQuestion
                                                              ][index]
                                                              ? linkInput[
                                                                  showQuestion
                                                                ][index]
                                                              : ""
                                                            : ""
                                                        }
                                                      >
                                                        <Input
                                                          addonBefore={
                                                            <SearchOutlined />
                                                          }
                                                          // placeholder="search resource link"
                                                          className="category-search-bar"
                                                          onChange={(e) => {
                                                            handleViewOptionResLinkText(
                                                              e.target.value,
                                                              showQuestion,
                                                              index
                                                            );
                                                            handleSearch(
                                                              e.target.value
                                                            );
                                                          }}
                                                          allowClear
                                                        />
                                                      </AutoComplete>
                                                    </div>
                                                  </div>
                                                  {item2?.opt_trainings
                                                    .length !== 0 && (
                                                    <div className="form-group-col-3">
                                                      <span className="section-text">
                                                        Selected Resource Links
                                                      </span>
                                                      <div className="keywords-box">
                                                        {item2.opt_trainings.map(
                                                          (item) => {
                                                            return (
                                                              <div className="keyword-batch">
                                                                <span>
                                                                  <a
                                                                    href={
                                                                      formatted_list_trainings[
                                                                        item
                                                                      ].link
                                                                    }
                                                                    className="resouce-link-color"
                                                                  >
                                                                    {
                                                                      formatted_list_trainings[
                                                                        item
                                                                      ].name
                                                                    }
                                                                  </a>
                                                                </span>
                                                                <CloseOutlined
                                                                  className="cursor-p"
                                                                  onClick={() => {
                                                                    handleRemoveLinkViewQuestionOptions(
                                                                      item,
                                                                      showQuestion,
                                                                      index
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                  </>
                                  // </div>
                                );
                              }
                            )}
                          </div>
                        )}
                        {/* Mapping New Questions */}
                        {newQuestions.length !== 0 &&
                          newQuestions.map((item, index) => {
                            return (
                              <div className="collapse-add-question-section">
                                <div className="description-box-self-ass">
                                  <div className="add-ctaegory-content-box">
                                    <div className="self-ass-category-heading">
                                      Enter question details
                                      <SelfAssessTooltip
                                        heading="Guidelines:"
                                        value={questions_guidelines}
                                      />
                                    </div>
                                    <div className="collapse-option-icons">
                                      <img
                                        src="/assets/DeleteOutlined.svg"
                                        className="icon-sizing"
                                        onClick={() => {
                                          setOpenModal({
                                            content:
                                              "Are you sure you want to delete this question?",
                                            func: () =>
                                              handleDeleteQuestion(index),
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <span className="sub-content mt-8">
                                    {questions_des}
                                  </span>
                                </div>
                                <div className="add-question-content-box">
                                  <div className="enter-question-section">
                                    <span className="section-text">
                                      <img
                                        src="/assets/_.svg"
                                        className="imp-logo-pos"
                                        alt=""
                                      />
                                      Question
                                    </span>
                                    <input
                                      // value={category?.name ? category.name : ""}
                                      className="category-name-input"
                                      // placeholder="Enter question"
                                      onChange={(e) => {
                                        handleViewQuestionTextAdd(
                                          e.target.value,
                                          index
                                        );
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="add-new-button"
                                    onClick={() => {
                                      handleViewAddOptionsNew(index);
                                    }}
                                  >
                                    Add an option
                                  </div>
                                </div>
                                {item.options.length !== 0 && (
                                  <div className="questions-option-main-box">
                                    <div className="description-box-self-ass">
                                      <div className="self-ass-category-heading">
                                        Enter option details
                                        <SelfAssessTooltip
                                          heading="Guidelines:"
                                          value={option_guidelines}
                                        />
                                      </div>
                                      <span className="sub-content mt-8">
                                        {options_desc}
                                      </span>
                                    </div>
                                    {item.options.map((item, index2) => {
                                      return (
                                        <>
                                          <div className="options-divider">
                                            <div className="options-divider-shade" />
                                            <span className="options-text">
                                              Option {index2 + 1}
                                            </span>
                                            <div className="options-divider-shade" />
                                            <img
                                              src="/assets/DeleteOutlined.svg"
                                              className="icon-sizing ml-14"
                                              onClick={() => {
                                                setOpenModal({
                                                  content:
                                                    "Are you sure you want to delete this option?",
                                                  func: () =>
                                                    handleDeleteOptions(
                                                      index,
                                                      index2
                                                    ),
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="add-ctaegory-content-box">
                                            <div className="enter-category-section">
                                              <span className="section-text">
                                                <img
                                                  src="/assets/_.svg"
                                                  className="imp-logo-pos"
                                                  alt=""
                                                />
                                                Option {index2 + 1}
                                              </span>
                                              <input
                                                // value={category?.name ? category.name : ""}
                                                className="category-name-input"
                                                // placeholder={`Enter option ${
                                                //   index2 + 1
                                                // }`}
                                                onChange={(e) => {
                                                  handleNewOptionName(
                                                    index,
                                                    index2,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="enter-category-section">
                                              <span className="section-text">
                                                Option Resource Links
                                              </span>
                                              <AutoComplete
                                                options={options}
                                                onSelect={(e) => {
                                                  onSelectNewQuestionOptions(
                                                    e,
                                                    index,
                                                    index2
                                                  );
                                                }}
                                                value={
                                                  linkNewInput[index]
                                                    ? linkNewInput[index][
                                                        index2
                                                      ]
                                                      ? linkNewInput[index][
                                                          index2
                                                        ]
                                                      : ""
                                                    : ""
                                                }
                                              >
                                                <Input
                                                  addonBefore={
                                                    <SearchOutlined />
                                                  }
                                                  // placeholder="search resource link"
                                                  className="category-search-bar"
                                                  onChange={(e) => {
                                                    handleNewQuestionResLink(
                                                      e.target.value,
                                                      index,
                                                      index2
                                                    );
                                                    handleSearch(
                                                      e.target.value
                                                    );
                                                  }}
                                                  allowClear
                                                />
                                              </AutoComplete>
                                            </div>
                                          </div>
                                          {item.opt_trainings.length !== 0 && (
                                            <div className="form-group-col-3">
                                              <span className="section-text">
                                                Selected Resource Links
                                              </span>
                                              <div className="keywords-box">
                                                {item.opt_trainings.map(
                                                  (item) => {
                                                    return (
                                                      <div className="keyword-batch">
                                                        <span>
                                                          <a
                                                            href={
                                                              formatted_list_trainings[
                                                                item
                                                              ].link
                                                            }
                                                            className="resouce-link-color"
                                                          >
                                                            {
                                                              formatted_list_trainings[
                                                                item
                                                              ].name
                                                            }
                                                          </a>
                                                        </span>
                                                        <CloseOutlined
                                                          className="cursor-p"
                                                          onClick={() => {
                                                            handleRemoveLinkNewQuestionOptions(
                                                              item
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            );
                          })}

                        {(newQuestions?.length !== 0 ||
                          Object.keys(newOptions).length !== 0) && (
                          <div className="category-section-buttons mt-1">
                            <div className="close-save">
                              <div
                                className="add-new-button"
                                onClick={handleClose}
                              >
                                Close
                              </div>
                              <div
                                className={
                                  isLoading
                                    ? "add-category-button cursor-n"
                                    : "add-category-button"
                                }
                                onClick={() => {
                                  ValidationCheck(indexCategory);
                                }}
                                // onClick={() => {
                                // setOpenModal({
                                //   content: submit_text,
                                //   func: () =>
                                //     handleSaveViewForm(indexCategory),
                                // });
                                // }}
                              >
                                Save
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  {selectedItems === item.id &&
                    questions[selectedItems]?.isReadOnly && (
                      <>
                        {/* <div className="sub-content textarea-view-description">
                          {item?.description}
                        </div> */}
                        <div
                          className="category-disclaimer-box"
                          dangerouslySetInnerHTML={{
                            __html: questions[item.id].message,
                          }}
                        ></div>
                      </>
                    )}
                </div>
              )
            );
          })}
        </div>
        {addCategory && (
          <AddCategory
            setAddCategory={setAddCategory}
            trainingLinks={trainingLinks}
            formatted_list_trainings={formatted_list_trainings}
            getAssessmentData={getAssessmentData}
            messageApi={messageApi}
            contextHolder={contextHolder}
          />
        )}
        <Modal
          open={deleteCategory.length !== 0}
          centered
          title={
            <div className="delete-title-box">
              <img src="/assets/ExclamationCircle.svg" />
              <span className="title-content">
                Are you sure you want to delete this category?
              </span>
            </div>
          }
          width="26rem"
          footer={[
            <div className="delete-modal">
              <div
                className="no-box"
                onClick={() => {
                  setDeleteCategory("");
                  setIsLoading(false);
                }}
              >
                No
              </div>
              <div className="yes-box" onClick={handleDeleteCategory}>
                Yes
              </div>
            </div>,
          ]}
          onCancel={() => {
            setDeleteCategory("");
            setIsLoading(false);
          }}
        ></Modal>
      </div>
      <AdminModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </>
  );
}
