import "./AssessmentStrategy.css";
import React from "react";
import { Pie } from "@ant-design/plots";
import { empty_text } from "../../../../constants/GlobalConstants";

export default function AssessmentStrategy({ isEmpty, data }) {
  const generateDistinctColors = (n) => {
    const goldenRatioConjugate = 0.618033988749895;
    let hue = Math.random();

    const colors = Array.from({ length: n }, (_, index) => {
      hue += goldenRatioConjugate;
      hue %= 1;
      const color = `hsl(${hue * 360}, 70%, 60%)`;
      return color;
    });

    return colors;
  };
  const generateDistinctMixedColors = (n) => {
    const goldenRatioConjugate = 0.618033988749895;
    let hue = Math.random();

    const colors = Array.from({ length: n }, (_, index) => {
      hue += goldenRatioConjugate;
      hue %= 1;

      // Adjust lightness dynamically between 40% and 80%
      const lightness = 40 + Math.random() * 40;

      const color = `hsl(${hue * 360}, 70%, ${lightness}%)`;
      return color;
    });

    return colors;
  };
  const generateDistinctLightColors = (n) => {
    const goldenRatioConjugate = 0.618033988749895;
    let hue = Math.random();

    const colors = Array.from({ length: n }, (_, index) => {
      hue += goldenRatioConjugate;
      hue %= 1;
      const color = `hsl(${hue * 360}, 70%, 80%)`; // Adjusted lightness to 80%
      return color;
    });

    return colors;
  };
  const generateDarkAndLightColors = (n) => {
    const goldenRatioConjugate = 0.618033988749895;
    let hue = Math.random();

    const colors = [];

    for (let i = 0; i < n; i++) {
      hue += goldenRatioConjugate;
      hue %= 1;

      // Alternate between dark and light colors
      const lightness =
        i % 3 === 0 ? 30 + Math.random() * 20 : 70 + Math.random() * 10;

      const color = `hsl(${hue * 360}, 70%, ${lightness}%)`;
      colors.push(color);
    }

    return colors;
  };
  const generatePastelColors = (n) => {
    const goldenRatioConjugate = 0.618033988749895;
    let hue = Math.random();

    const colors = Array.from({ length: n }, (_, index) => {
      hue += goldenRatioConjugate;
      hue %= 1;

      // Lower saturation and higher lightness for pastel colors
      const saturation = 30 + Math.random() * 20;
      const lightness = 70 + Math.random() * 10;

      const color = `hsl(${hue * 360}, ${saturation}%, ${lightness}%)`;
      return color;
    });

    return colors;
  };
  const generateDarkPastelColors = (n) => {
    const goldenRatioConjugate = 0.618033988749895;
    let hue = Math.random();

    const colors = Array.from({ length: n }, (_, index) => {
      hue += goldenRatioConjugate;
      hue %= 1;

      // Lower saturation and moderate lightness for dark pastel colors
      const saturation = 20 + Math.random() * 10;
      const lightness = 40 + Math.random() * 10;

      const color = `hsl(${hue * 360}, ${saturation}%, ${lightness}%)`;
      return color;
    });

    return colors;
  };
  const generateLighterDarkPastelColors = (n) => {
    const goldenRatioConjugate = 0.618033988749895;
    let hue = Math.random();

    const colors = Array.from({ length: n }, (_, index) => {
      hue += goldenRatioConjugate;
      hue %= 1;

      // Lower saturation and slightly higher lightness for lighter dark pastel colors
      const saturation = 20 + Math.random() * 10;
      const lightness = 50 + Math.random() * 10;

      const color = `hsl(${hue * 360}, ${saturation}%, ${lightness}%)`;
      return color;
    });

    return colors;
  };
  const dummyData = [
    { type: "Educational Content", value: 10 },
    { type: "POVs", value: 14 },
    { type: "Deloitte Policies", value: 15 },
    { type: "Go to Market Content", value: 9 },
  ];
  const PieChart = ({ chartData }) => {
    // console.log("Pink shades:",generateCustomShades("#FFC0CB",6),generatePinkShades(6))
    const config = {
      appendPadding: 10,
      data: chartData,
      angleField: "value",
      colorField: "type",
      radius: 1,
      theme: {
        colors10: generateDistinctLightColors(chartData.length),
        colors20: generateDistinctLightColors(chartData.length),
      },
      autofit: true,
      label: false,
      isZero: true,
      // legend: true,
      // legend: {
      //   position: "right", // Change this to 'left', 'bottom', or 'right' as needed
      // },

      interactions: [{ type: "element-selected" }, { type: "element-active" }],
    };
    return <Pie {...config} />;
  };
  return (
    <>
      {isEmpty ? (
        <div className="empty-data-img-box">
          <img
            src="/assets/filter-usage.svg"
            className="trending-empty-image"
            alt=""
          />
          <span className="empty-text">{empty_text}</span>
        </div>
      ) : (
        <div className="pie-chart">
          {/* <div className="filter-graph-filter-heading p-l-0">Category</div> */}
          <div className="pie-chart-graph-box">
            <PieChart chartData={data} />
          </div>
        </div>
      )}
    </>
  );
}
