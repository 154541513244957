import React, { useContext, useEffect, useRef, useState } from "react";
import "./ReflectiveQuestions.css";
import { CloseOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../../Context/AuthContext";
import { Base_Url } from "../../../../Utils/urls";
import { Spin, Tooltip } from "antd";
import { useRecommendedTrainings } from "../../../../Hooks/useRecommendedTrainings";
import { AppStateContext } from "../../../../Context/AppStateContext";

export default function ReflectiveQuestions() {
  const { getToken } = useContext(AuthContext);

  const { setToggleTab } = useContext(AppStateContext);

  const { handleReflectiveSubmit } = useRecommendedTrainings();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [idValueMapping, setIdValueMapping] = useState(null);
  const [categoryQuestions, setCategoryQuestions] = useState(null);
  const [reflectiveQuestions, setReflectiveQuestions] = useState(null);
  const [isLoadingReflective, setIsLoadingReflective] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const categoryPrevId = useRef(null);
  const [submitActive, setSubmitActive] = useState(false);

  const ReflectiveQuestion = () => {
    return (
      <div className="reflective-question-selection-area">
        {currentQuestionIndex !== 0 && (
          <div className="reflective-question-no">
            Question {currentQuestionIndex} of {reflectiveQuestions.length}
          </div>
        )}
        <div className="reflective-question-text">
          {currentQuestionIndex === 0
            ? "What type of resources are you looking for?"
            : reflectiveQuestions[currentQuestionIndex - 1].question}
        </div>
        <div className="reflective-question-choice-items">
          {currentQuestionIndex === 0 &&
            idValueMapping.map((item, index) =>
              item?.description?.length !== 0 ? (
                <Tooltip
                  placement="topLeft"
                  overlayClassName="reflective-category-desc-overlay"
                  title={
                    <div className="reflective-category-desc">
                      {item?.description}
                    </div>
                  }
                  color="#444444"
                >
                  <div
                    key={index}
                    className={
                      categoryId === item?.id
                        ? "reflective-question-choice reflective-question-choice-selected"
                        : "reflective-question-choice"
                    }
                    onClick={() => handleChoiceSelect(item?.id)}
                  >
                    {item?.name}
                  </div>
                </Tooltip>
              ) : (
                <div
                  key={index}
                  className={
                    categoryId === item?.id
                      ? "reflective-question-choice reflective-question-choice-selected"
                      : "reflective-question-choice"
                  }
                  onClick={() => handleChoiceSelect(item?.id)}
                >
                  {item?.name}
                </div>
              )
            )}
          {currentQuestionIndex !== 0 &&
            reflectiveQuestions[currentQuestionIndex - 1].options.map(
              (option, index) => (
                <div
                  key={index}
                  className={
                    selectedQuestions[currentQuestionIndex - 1]?.option_id ===
                    option?.id
                      ? "reflective-question-choice reflective-question-choice-selected"
                      : "reflective-question-choice"
                  }
                  onClick={() => handleChoiceSelect(option?.id)}
                >
                  {option?.text}
                </div>
              )
            )}
        </div>
      </div>
    );
  };

  const getReflectiveQuestions = async () => {
    try {
      setIsLoadingReflective(true);
      let accessToken = await getToken();
      let reflectiveQuesData = await fetch(`${Base_Url}/skill_assessment`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      }).then((response) => response.json());

      setIdValueMapping(reflectiveQuesData?.resources);

      let combinedData = {};

      reflectiveQuesData?.resources.forEach((resource) => {
        let categoryId = resource.id.toString();
        if (reflectiveQuesData?.questions.hasOwnProperty(categoryId)) {
          combinedData = {
            ...combinedData,
            [categoryId]: {
              category: resource.name,
              ...reflectiveQuesData.questions[categoryId],
            },
          };
        }
      });

      setCategoryQuestions(combinedData);
      setIsLoadingReflective(false);
    } catch (e) {
      console.log("Error:", e);
      setIsLoadingReflective(false);
    }
  };

  const handleChoiceSelect = (id) => {
    if (currentQuestionIndex === 0) {
      if (categoryQuestions[id]?.isOptionLev) {
        setSubmitActive(false);
        let reflectiveQuestionsTemp = categoryQuestions[id]?.data;
        setCategoryId(id);
        setReflectiveQuestions(reflectiveQuestionsTemp);
      } else {
        setCategoryId(id);
        setSubmitActive(true);
      }
    } else {
      let selectedQuestionsTemp = [...selectedQuestions];
      selectedQuestionsTemp[currentQuestionIndex - 1] = {
        question_id: reflectiveQuestions[currentQuestionIndex - 1].question_id,
        option_id: id,
      };
      setSelectedQuestions(selectedQuestionsTemp);
    }
  };

  const handleSubmit = (categoryTempId) => {
    let tempId = categoryTempId ?? categoryId;
    let payload = {
      resource_id: tempId,
      isAllAbove: categoryQuestions[tempId].isAllAbove,
      question_data: selectedQuestions,
    };
    handleReflectiveSubmit(payload, categoryQuestions[tempId].category);
  };

  const handlePrevQuestion = () => {
    // Move to the previous question
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex === 0 && categoryPrevId.current !== categoryId) {
      let reflectiveQuestionsTemp = categoryQuestions[categoryId]?.data;
      let initiliazedTempArr = new Array(reflectiveQuestionsTemp.length).fill(
        {}
      );
      setSelectedQuestions(initiliazedTempArr);
      categoryPrevId.current = categoryId;
    }
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  useEffect(() => {
    getReflectiveQuestions();
  }, []);

  return (
    <div className="reflective-questions-container">
      <div className="reflective-questions-header">
        <div className="reflective-questions-icon">
          <img src="./assets/reflectiveGreen.svg" alt="" />
        </div>
        <span className="reflective-questions-header-content">
          Reflective Questions
        </span>
        <div className="reflective-close-icon-box">
          <CloseOutlined
            className="reflective-questions-close-icon"
            onClick={() => {
              setToggleTab(2);
            }}
          />
        </div>
      </div>
      {isLoadingReflective || !categoryQuestions ? (
        <Spin
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        <div className="reflective-questions-content">
          <div className="reflective-ins-text">
            For an enhanced learning experience, please respond to the questions
            below. Select any one option and click Next/Submit.
          </div>
          <ReflectiveQuestion />
          <div className="reflective-nav-buttons">
            {currentQuestionIndex > 0 && (
              <div
                className="reflective-nav-button"
                onClick={() => handlePrevQuestion()}
              >
                Back
              </div>
            )}
            {reflectiveQuestions &&
              !submitActive &&
              JSON.stringify(selectedQuestions[currentQuestionIndex - 1]) !==
                JSON.stringify({}) &&
              currentQuestionIndex !== reflectiveQuestions.length && (
                <div
                  className="reflective-nav-button"
                  onClick={() => handleNextQuestion()}
                >
                  Next
                </div>
              )}
            {(submitActive ||
              (reflectiveQuestions &&
                JSON.stringify(
                  selectedQuestions[reflectiveQuestions.length - 1]
                ) !== JSON.stringify({}) &&
                currentQuestionIndex === reflectiveQuestions.length)) && (
              <div
                className="reflective-nav-button"
                onClick={() => handleSubmit()}
              >
                Submit
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
