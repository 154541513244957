import React from "react";
import "./CustomTooltip.css";

export default function CustomTooltip({
  children,
  title,
  visible,
  onClick,
  maxWidth = "max-content",
  pos = "top",
  height = "max-content",
  width = "max-content",
  posHorizontal = "left",
}) {
  if (!visible) return <>{children}</>;
  let tooltipStyle = {
    maxWidth: maxWidth,
  };
  tooltipStyle =
    pos === "top"
      ? { ...tooltipStyle, bottom: "calc(100% + 5px)" }
      : { ...tooltipStyle, top: "calc(100% + 5px)" };
  tooltipStyle =
    posHorizontal === "left"
      ? { ...tooltipStyle, left: "0" }
      : { ...tooltipStyle, right: "0" };
  return (
    <div
      class="custom-tooltip"
      onClick={onClick}
      style={{ height: height, width: width }}
    >
      {children}
      <div class="custom-tooltip-content" style={tooltipStyle}>
        {title}
      </div>
    </div>
  );
}
