// import { createContext, useReducer, useEffect } from 'react';
import React, { createContext, useState, useEffect, useRef } from 'react';
import { PublicClientApplication, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';

export const AuthContext = createContext();

let msalApp = null;

export const AuthContextProvider = ({ children }) => {
    const msalConfig = {
        auth: {
            clientId: process.env.REACT_APP_CLIENT_ID || "", // Replace with your Azure AD application's client ID
            authority:
                "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID, // Replace with your Azure AD tenant ID
            redirectUri: '/', // Replace with your redirect URI
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
        system: {
            allowNativeBroker: false,
        },
    };

    const [msalInstance, setMsalInstance] = useState(null);
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    const authenticated = user !== null && accessToken !== null;

    const interactionMethod = useRef(localStorage.getItem("interactionMethod") ?? "");

    useEffect(() => {
        const initializeMsal = async () => {
            let msalAppTemp = new PublicClientApplication(msalConfig);
            await msalAppTemp.initialize();
            msalApp = msalAppTemp;
            setMsalInstance(msalAppTemp);

            msalApp.addEventCallback((message) => {
                if (message.eventType === EventType.LOGIN_SUCCESS && message.payload.account) {
                    setUser(message.payload.account);
                }
            });

            try {
                const accounts = await msalApp.getAllAccounts();
                if (accounts.length > 0) {
                    setUser(accounts[0]);
                    const token = await getToken(accounts[0]);
                    setAccessToken(token);
                }
            } catch (error) {
                console.error('Error fetching accounts:', error);
            }
        };

        if (!msalInstance) {
            initializeMsal();
        } else {
            handleLoginPopup();
        }
    }, [msalInstance]);

    const handleLoginPopup = async () => {
        localStorage.setItem("interactionMethod", "POPUP");
        interactionMethod.current = "POPUP";
        if (!msalApp) return;
        try {
            if (!user) {
                const loginRequest = {
                    scopes: ["user.read"],
                    // popupWindowAttributes: {
                    //     popupPosition: {
                    //         left: 100,
                    //         top: 200,
                    //     },
                    //     // popupSize: {
                    //     //     width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                    //     //     height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
                    //     // },
                    // }
                };
                const loginResponse = await msalApp.loginPopup(loginRequest);
                setUser(loginResponse.account);
                const token = await getToken(loginResponse.account);
                setAccessToken(token);
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    async function handleResponse(response) {
        if (response !== null) {
            setUser(response.account);
            // const tokenResponse = await msalApp.acquireTokenSilent({
            //     scopes: ["user.read"],
            //     account: response.account,
            // });
            // if (tokenResponse && tokenResponse.accessToken) {
            //     setAccessToken(tokenResponse.accessToken);
            // }
            const token = await getToken(response.account);
            setAccessToken(token);
            console.log("User details: ", response.account)
            console.log("Token: ", token)
            // Display signed-in user content, call API, etc.
        } else {
            // In case multiple accounts exist, you can select
            const currentAccounts = msalApp.getAllAccounts();

            if (currentAccounts.length === 0) {
                // no accounts signed-in, attempt to sign a user in
                msalApp.loginRedirect({});
            } else if (currentAccounts.length > 0) {
                setUser(currentAccounts[0]);
            }
        }
    }

    const handleLoginRedirect = async () => {
        localStorage.setItem("interactionMethod", "REDIRECT");
        interactionMethod.current = "REDIRECT";
        if (!msalInstance) return;
        try {
            if (!user) {
                msalApp.handleRedirectPromise().then(handleResponse);
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    const handleLogout = () => {
        if (!msalInstance) return;

        sessionStorage.clear(); //removing session storage, unexpected behaviour seen sometimes
        msalApp.logoutPopup();
        setUser(null);
    };

    const getToken = async (account = user, method = "POPUP") => {
        if (!msalApp || !account) return;
        try {
            // console.log("Msal instance: ", msalApp)
            const tokenResponse = await msalApp.acquireTokenSilent({
                scopes: ["user.read"],
                account: account,
            });
            // console.log("This first")
            localStorage.setItem("accessToken", tokenResponse.accessToken);
            return tokenResponse.accessToken;
        } catch (error) {
            console.log("Error: ", error)
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                if (method === "POPUP") {
                    const tokenResponse = await msalApp.acquireTokenPopup({
                        scopes: ["user.read"],
                        account: account,
                    });
                    localStorage.setItem("accessToken", tokenResponse.accessToken);
                    return tokenResponse.accessToken;
                } else {
                    msalApp.acquireTokenRedirect({
                        scopes: ["user.read"],
                        account: account,
                    });
                    return null;
                }
            }
        }
    }

    const [isViewSection, setViewSection] = useState(false);
    const [showContent, setShowContent] = useState("");
    const [isEditContent, setEditContent] = useState(false);
    const [showTraining, setShowTraining] = useState(null);
    const [editViewToggle, setEditViewToggle] = useState(0);
    const [selectedUser, setSelectedUser] = useState(null);
  
    const authValues = {
      authenticated,
      user,
      accessToken,
      handleLoginPopup,
      getToken,
      handleLoginRedirect,
      handleLogout,
      isViewSection,
      setViewSection,
      showContent,
      setShowContent,
      isEditContent,
      setEditContent,
      showTraining,
      setShowTraining,
      editViewToggle,setEditViewToggle,
      selectedUser,setSelectedUser
    };
    return (
        <AuthContext.Provider value={authValues}>
            {children}
        </AuthContext.Provider>
    );
};
