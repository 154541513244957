import React, { useContext, useEffect, useRef, useState } from "react";
import "./UploadContent.css";
import { message, Modal, Upload } from "antd";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  acceptedFormats,
  containsAlphabetsOrNumbers,
  submit_text,
} from "../../../../constants/GlobalConstants";
import { Base_Url, Base_Url_Admin } from "../../../../Utils/urls";
import { AuthContext } from "../../../../Context/AuthContext";
import { v4 as uuidv4 } from "uuid";

export default function UploadContent({
  showContent,
  isEditContent,
  setEditContent,
  isViewSection,
  setViewSection,
  setDeleteContent,
  getContents,
  setShowContent,
  page,
}) {
  const { user, getToken } = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [openModal, setOpenModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const textareaRef = useRef(null);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    keywords: [],
    // fileName:"",
    // link:"",
  });
  const [fileList, setFileList] = useState([]);
  let defaultContentValue = {};
  if (showContent.length !== 0) {
    defaultContentValue = {
      title: showContent?.title,
      description: showContent?.description,
      keywords: showContent?.keywords,
    };
  }
  const [currentInput, setCurrentInput] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChangeKeyword = (e) => {
    setCurrentInput(e.target.value);
    return;
  };
  const addValue = (e) => {
    if (currentInput.length === 0) return;
    let newKeywords = formData.keywords;
    if (newKeywords.includes(currentInput)) {
      message.error(`The keyword already exists.`);
      setCurrentInput("");
      return;
    }
    newKeywords.push(currentInput);
    setCurrentInput("");
    setFormData({ ...formData, ["keywords"]: newKeywords });
    return;
  };
  const handleRemove = (value) => {
    if (showContent.length !== 0 && !isEditContent) return;
    const filteredKeywords = formData?.keywords.filter((str) => str !== value);
    setFormData({ ...formData, ["keywords"]: filteredKeywords });
  };
  const props = {
    name: "file",
    // action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    // headers: {
    //   authorization: "authorization-text",
    // },
    beforeUpload: (file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!acceptedFormats.includes(fileExtension || "")) {
        message.error(`Only ${acceptedFormats.join(", ")} files are allowed.`);
        return false;
      }
      setFileList([file]);
      message.success(`${file.name} file uploaded successfully`);
    },
    // async onChange(info) {
    //   console.log(info.file.status);
    //   if (info.file.status !== "uploading") {
    //     console.log(info.file, info.fileList);
    //   }
    //   if (info.file.status === "done") {
    //     message.success(`${info.file.name} file uploaded successfully`);
    //   } else if (info.file.status === "error") {
    //     message.error(`${info.file.name} file upload failed.`);
    //   }
    // },
  };
  const getContentDetails = async (value) => {
    const params = new URLSearchParams();
    params.append("id", value);
    let accessToken = await getToken();
    let response = await fetch(`${Base_Url_Admin}/content_details?${params}`, {
      method: "GET",
      // body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + token,
        "X-Api-Token": accessToken,
        // Authorization: "Bearer " + deloitteChatToken,
      },
    });
    let details = await response.json();
    let defaultValue = {
      title: details?.title,
      description: details?.description,
      keywords: details?.keywords === "" ? [] : details?.keywords,
      file_name: details?.file_name,
      links: details?.links,
    };
    setFormData(defaultValue);
    return;
  };
  useEffect(() => {
    autoExpandTextarea();
    if (disable) setDisable(false);
  }, [formData, fileList]);
  useEffect(() => {
    if (showContent.length !== 0) getContentDetails(showContent);
  }, [showContent]);

  const handleValidation = async (e) => {
    e.preventDefault();
    let errorStatus = [];

    if (showContent.length !== 0 && !isEditContent) return;
    for (const [key, value] of Object.entries(formData)) {
      if (key === "keywords") break;
      if (value.length === 0) {
        message.error(`Please add details for all the fields.`);
        errorStatus.push(`Please add details for all the fields.`);
        setDisable(true);
        setTimeout(() => {
          setDisable(false);
        }, 3500);
        return;
      }
    }
    if (!containsAlphabetsOrNumbers(formData?.title)) {
      message.error(`Invalid title`);
      errorStatus.push(`Invalid title!!`);
    }
    if (!containsAlphabetsOrNumbers(formData?.description)) {
      message.error(`Invalid Description`);
      errorStatus.push(`Invalid Description`);
    }
    if (fileList.length === 0 && showContent.length === 0) {
      message.error(`Please upload a document to enable submission.`);
      errorStatus.push(`Please upload a document to enable submission.`);
    }
    // if (!containsAlphabetsOrNumbers(formData?.duration)) {
    //   message.error(`Invalid Duration`);
    //   errorStatus.push(`Invalid Duration`);
    // }
    if (errorStatus.length !== 0) {
      setDisable(true);
      setTimeout(() => {
        setDisable(false);
      }, 3500);
      return;
    }
    setOpenModal(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    try {
      setIsLoading(true);
      let payload = [];
      let fileName = fileList[0].name;
      let metaData = {
        file_name: fileName,
        document_title: formData?.title,
        description: formData?.description,
        keywords: formData?.keywords,
        link: "https://www.makemytrip.com/",
      };
      payload.push(metaData);
      const combinedFormData = new FormData();
      combinedFormData.append("file", fileList[0]);
      combinedFormData.append("form_data", JSON.stringify(payload));
      console.log("PP: ", combinedFormData, metaData, fileList[0]);
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url}/upload_blob_doc`, {
        method: "POST",
        body: combinedFormData,
        headers: {
          // "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
      });
      let blob_url = await response.json();
      fileName = Object.keys(blob_url)[0];
      if (blob_url?.message === "Title already exist in DB") {
        messageApi.open({ type: "error", content: blob_url?.message });
        setIsLoading(false);
        setOpenModal(false);

        return;
      }
      let embeddings_payload = {
        file_name: `${blob_url[fileName]}/${fileName}`,
      };
      const gen_embeddings = await fetch(`${Base_Url}/generate_embeddings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + user["access_token"],
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify(embeddings_payload),
      });
      let sas_payload = {
        blob_name: `${blob_url[fileName]}/${fileName}`,
      };
      const get_saas_url = await fetch(`${Base_Url}/get_sas_url`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify(sas_payload),
      });

      const sas_url = await get_saas_url.json();
      const payload_summary = {
        message: {
          content: {
            path_to_files: sas_url.url,
            summary_generation_tracking_id: uuidv4(),
          },
        },
      };
      const payload_question = {
        message: {
          content: {
            path_to_files: sas_url.url,
            question_generation_tracking_id: uuidv4(),
          },
        },
      };
      const gen_summary = await fetch(`${Base_Url}/generate_summary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify(payload_summary),
      });
      const gen_ques = await fetch(`${Base_Url}/generate_questions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + user["access_token"],
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify(payload_question),
      });
      messageApi.open({
        type: "success",
        content: "Content added successfully!!",
      });
      setTimeout(() => {
        // After the operation is complete, set loading state back to false
        setIsLoading(false);
        setOpenModal(false);
        getContents(page);
        setViewSection(false);
        setEditContent(false);
        setShowContent("");
      }, 1000);
    } catch (e) {
      console.log("ERROR:", e);
      setIsLoading(false);
      setOpenModal(false);
    }
    return;
  };
  const handleEdit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    try {
      setIsLoading(true);
      let payload = {
        id: showContent,
        document_title: formData?.title,
        description: formData?.description,
        keywords: formData?.keywords,
      };
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/content_details`, {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
      });
      let message = response.json();
      if (message?.message === "Title already exist in DB") {
        messageApi.open({ type: "error", content: message?.message });
        return;
      }
      messageApi.open({
        type: "success",
        content: "Content successfully edited!",
      });
      setTimeout(() => {
        // After the operation is complete, set loading state back to false
        setIsLoading(false);
        setOpenModal(false);
        getContents(1);
        setViewSection(false);
        setEditContent(false);
        setShowContent("");
      }, 1000);
      return;
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const autoExpandTextarea = (event) => {
    const textarea = event ? event.target : textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };
  return (
    <>
      {contextHolder}
      <span className="sub-content">
        {showContent.length !== 0
          ? isEditContent
            ? "Manage the details for an existing document in Super Tutor's Knowledge Base through this section."
            : "Access the details of an existing document in Super Tutor's Knowledge Base through this section."
          : "Add content to be referred to by Super Tutor for responding to questions of PPMDs through this section."}
      </span>
      <div className="upload-heading-box">
        <div className="left-side-heading">
          <span className="upload-content">
            {showContent.length !== 0
              ? isEditContent
                ? "Edit Content"
                : "View Content"
              : "Add Content"}
          </span>
        </div>
        <div className="right-side-button-box">
          {showContent.length !== 0 ? (
            isEditContent ? (
              <div
                className="delete-button"
                onClick={() => setDeleteContent(showContent)}
              >
                Delete
              </div>
            ) : (
              <>
                <div
                  className="delete-button"
                  onClick={() => setDeleteContent(showContent)}
                >
                  Delete
                </div>
                <div
                  className="edit-button"
                  onClick={() => setEditContent(true)}
                >
                  Edit
                </div>
              </>
            )
          ) : (
            <div className="bulk-upload-box"></div>
          )}
        </div>
      </div>
      <form>
        <div className="upload-form-box">
          <div className="form-row">
            <div className="form-group-col-3">
              <label className="center-align">
                <img src="/assets/_.svg" />
                &nbsp;Title
              </label>
              <input
                type="text"
                disabled={showContent?.length !== 0 && !isEditContent}
                className="input-box-form"
                name="title"
                value={formData?.title}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group-col-3">
              <label className="center-align">
                <img src="/assets/_.svg" />
                &nbsp;Description
              </label>
              <textarea
                name="description"
                ref={textareaRef}
                value={formData.description}
                onChange={handleChange}
                disabled={showContent.length !== 0 && !isEditContent}
                className="textarea-box-form"
                onInput={autoExpandTextarea}
              />
            </div>
          </div>
          {/* <div className="form-group-col-3 gap-5">
            {showContent.length !== 0 && !isEditContent ? (
              <span className="center-align">Keywords Added</span>
            ) : (
              <div className="form-row">
                <div className="form-group-col-2">
                  <label className="center-align">Keywords</label>
                  <input
                    type="text"
                    disabled={showContent.length !== 0 && !isEditContent}
                    className="input-box-form"
                    name="keywords"
                    value={currentInput}
                    onChange={handleChangeKeyword}
                  />
                </div>
                <div className="button-box">
                  <div className="add-button" onClick={addValue}>
                    Add
                  </div>
                </div>
              </div>
            )}
            {formData?.keywords?.length !== 0 && (
              <div className="keywords-box">
                {formData?.keywords.map((item) => {
                  return (
                    <div className="keyword-batch">
                      <span>{item}</span>
                      <CloseOutlined
                        className="cursor-p"
                        onClick={() => {
                          handleRemove(item);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div> */}
          {showContent.length !== 0 ? (
            <div className="form-group-col-2">
              <label className="center-align">
                <img src="/assets/_.svg" />
                &nbsp;Uploaded content
              </label>
              <span className="content-options-1 c-30">
                <img src="/assets/icon-attachment.svg" />
                <a href={formData?.links} target="_blank" className="link-name">
                  {formData?.file_name}
                </a>
              </span>
            </div>
          ) : (
            <div className="upload-file-box">
              <label className="center-align">
                <img src="/assets/_.svg" />
                &nbsp;Upload learning content
              </label>

              {/* <img  src="/assets/Inbox.svg"/> */}
              <Upload
                {...props}
                fileList={fileList}
                onRemove={() => {
                  setFileList([]);
                }}
              >
                <div className="upload-box">
                  {/* <InboxOutlined className="icon-color-inbox" /> */}
                  <img src="/assets/Inbox2.svg" />
                  <span>Click or drag file to this area to upload</span>
                  <span className="file-limit-content">
                    Support for a single or bulk upload. Strictly prohibited
                    from uploading company data or other banned files.
                  </span>
                </div>
              </Upload>
              <div className="upload-info-statement">
                <InfoCircleOutlined className="info-upload-icon" />
                <span>
                  Before uploading, please make sure to remove any sensitive
                  client information from the file to ensure confidentiality.
                </span>
              </div>
            </div>
          )}
        </div>
      </form>
      {showContent.length !== 0 && !isEditContent ? (
        <></>
      ) : (
        <div className="submit-box">
          <button onClick={handleValidation} className="submit-button">
            Submit
          </button>
        </div>
      )}
      <Modal
        open={openModal}
        title={
          <div className="delete-title-box">
            <img src="/assets/ExclamationCircle.svg" />
            <span className="title-content">{submit_text}</span>
          </div>
        }
        centered
        width="26rem"
        footer={[
          <div className="delete-modal">
            <div className="no-box" onClick={() => setOpenModal(false)}>
              No
            </div>
            <div
              className="yes-box"
              onClick={isEditContent ? handleEdit : handleSubmit}
            >
              {isLoading ? "Loading..." : "Yes"}
            </div>
          </div>,
        ]}
        onCancel={() => setOpenModal(false)}
      ></Modal>
    </>
  );
}
