import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import FilterUsageCard from "../../../Components/FilterUsageCard/FilterUsageCard";
import { Base_Url_Admin } from "../../../../../Utils/urls";
import { Spin, Tooltip } from "antd";
import Select from "react-select";
import UseCaseCard from "../../../Components/UseCaseCard/UseCaseCard";
import DatePickCustom from "../../../Components/DatePickCustom/DatePickCustom";
import Item from "antd/es/list/Item";
import MetricsGradientCard from "../../../Components/MetricsGradientCard/MetricsGradientCard";
import TrendingLearningCard from "../../../Components/TrendingLearningCard/TrendingLearningCard";
import {
  cards_description,
  customStyles,
  empty_text,
  graph_details,
  usage_metrics,
} from "../../../../../constants/GlobalConstants";
import MetricsLinearCard from "../../../Components/MetricsLinearCard/MetricsLinearCard";
import { AuthContext } from "../../../../../Context/AuthContext";
import { InfoCircleOutlined } from "@ant-design/icons";
import AssessmentStrategy from "../../../Components/AssessmentStrategy/AssessmentStrategy";

let currentYear = 0;
export default function Dashboard() {
  const { getToken } = useContext(AuthContext);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filtersItemList, setFiltersItemList] = useState(null);
  const [useUsageData, setUseUsageData] = useState(null);
  const [selectedDates, setSelectedDates] = useState({});
  const [selectedYear, setSelectedYear] = useState();
  const [periodList, setPeriodList] = useState([]);
  const [usageMetricsData, setUsageMetricsData] = useState(null);
  const [trendingData, setTrendingData] = useState(null);
  const [averageGraphCount, setAverageGraphCount] = useState({});
  const [isEmptyData, setEmptyData] = useState({});
  const [isEmptyFilterData, setEmptyFilterData] = useState(false);
  const [isEmptyUsageData, setEmptyUsageData] = useState(false);
  const [isAllEmpty, setAllEmpty] = useState(false);
  const [isSingleRow, setIsSingleRow] = useState(true);
  const [categoryData, setCategoryData] = useState(null);
  const handleChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    setSelectedDates({
      start_date: selectedOption.start_date,
      end_date: selectedOption.end_date,
    });
  };
  const getFilterUsageData = async (params) => {
    try {
      setFilterOptions([]);
      setFiltersItemList(null);
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/filter_usage_chart?${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
        }
      );
      let filters = await response.json();
      if (filters?.Status === "Unexpected exception occurred") return;
      let filtersList = [];
      let filtersOptionsList = {};
      for (const [key, value] of Object.entries(filters)) {
        filtersList.push({
          value: key.toLowerCase().replace(/\s/g, ""),
          label: key,
        });
        filtersOptionsList[key.toLowerCase().replace(/\s/g, "")] = value;
      }
      setFilterOptions(filtersList);
      setFiltersItemList(filtersOptionsList);
    } catch (e) {
      console.log("Error:", e);
    }
    return;
  };
  const getUseCaseData = async (params) => {
    try {
      params.append("fiscal_year_id", selectedYear.id);
      setUseUsageData(null);
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/use_case_usage?${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      });
      let usages = await response.json();
      let new_usage = {};
      for (const [key, value] of Object.entries(usages)) {
        let y = [];
        if (key === "data_format") continue;
        for (const [date, dateValue] of Object.entries(value)) {
          y.push({
            month: date,
            value: dateValue,
            category:
              key === "questions_on_genai"
                ? "Conversations"
                : key === "recommended_trainings"
                ? "Recommended Resources"
                : "Reflective Assessments",
          });
        }
        new_usage[
          key === "questions_on_genai"
            ? "questions"
            : key === "recommended_trainings"
            ? "trainings"
            : "assessments"
        ] = y;
      }
      setUseUsageData(new_usage);
      return;
    } catch (e) {
      console.log("Error:", e);
    }
    return;
  };
  const getFiscalYear = async () => {
    try {
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/get_all_fiscal_years`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      });
      let years_list = await response.json();
      let new_years_list = years_list.data.map((item) => {
        return {
          start_date: item.start_date,
          end_date: item.end_date,
          value: item.year,
          label: "FY" + item.year.toString().slice(-2),
          id: item.id,
        };
      });
      setPeriodList(new_years_list);
      setSelectedYear(new_years_list[0]);
      currentYear = new_years_list[0].value;
      setSelectedDates({
        start_date: new_years_list[0].start_date,
        end_date: new_years_list[0].end_date,
      });
      return;
    } catch (e) {
      console.log("Error:", e);
    }
    return;
  };

  const getUsageMetrics = async (params) => {
    try {
      setUsageMetricsData(null);
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/usage_metrics?${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      });
      response = await response.json();
      let usage_metrics = {};
      let tempArr = [];
      let count = 0,
        sum = 0;
      let average_usage_count = 0;
      //Modify the average_usage_monthly data according to our needs
      for (const [date, dateValue] of Object.entries(response?.average_usage)) {
        tempArr.push({
          period: date,
          value: dateValue?.average,
        });
        count++;
        sum += dateValue?.average;
      }
      average_usage_count = (sum / count).toFixed(2);
      usage_metrics = {
        ...usage_metrics,
        average_usage: tempArr,
      };

      //Modify the unique_user data according to our needs
      tempArr = [];
      count = 0;
      sum = 0;
      let unique_user_count = 0;
      for (const [date, dateValue] of Object.entries(response?.unique_user)) {
        tempArr.push({
          period: date,
          value: dateValue?.total_user_count,
        });
        count++;
        sum += dateValue?.total_user_count;
      }
      unique_user_count = (sum / count).toFixed(2);
      usage_metrics = {
        ...usage_metrics,
        unique_user: tempArr,
      };

      //Modify the session_usage data according to our needs
      tempArr = [];
      count = 0;
      sum = 0;
      let session_usage_count = 0;
      for (const [date, dateValue] of Object.entries(response?.session_usage)) {
        tempArr.push({
          period: date,
          value: dateValue?.total_duration_hours,
        });
        count++;
        sum += dateValue?.total_duration_hours;
      }
      session_usage_count = (sum / count).toFixed(2);
      usage_metrics = {
        ...usage_metrics,
        session_usage: tempArr,
      };

      //Modify the retention_rate data according to our needs
      let quarterlyArr = [];
      let annualArr = [];
      count = 0;
      sum = 0;
      let retention_rate_count = 0;
      let sum_quarterly = 0;
      let sum_annualy = 0;
      for (const [date, dateValue] of Object.entries(
        response?.retention_rate
      )) {
        quarterlyArr.push({
          period: date,
          value: dateValue?.quarterly,
          category: "quarterly",
        });
        annualArr.push({
          period: date,
          value: dateValue?.annual,
          category: "annual",
        });
        count++;
        sum_quarterly += dateValue?.quarterly;
        sum_annualy += dateValue?.annual;
      }
      retention_rate_count = {
        quarterly: (sum_quarterly / count).toFixed(2),
        annual: (sum_annualy / count).toFixed(2),
      };
      usage_metrics = {
        ...usage_metrics,
        retention_rate: quarterlyArr.concat(annualArr),
      };
      setAverageGraphCount((prevState) => {
        return {
          ...prevState,
          [graph_details.frequency_graph.graphName]: average_usage_count,
          [graph_details.unique_user_graph.graphName]:
            Math.round(unique_user_count),
          [graph_details.session_graph.graphName]: session_usage_count,
          [graph_details.retention_rate.graphName]: retention_rate_count,
        };
      });

      //Update the state, maintaining the data for all the 4 usage metrics charts
      setUsageMetricsData(usage_metrics);
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const getTrendingCard = async (params) => {
    try {
      setTrendingData(null);
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/trending_keywords?${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
        }
      );
      response = await response.json();
      setTrendingData(response?.data);
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const getAllEntries = async () => {
    try {
      if (Object.keys(selectedDates).length === 0) return;
      const params = new URLSearchParams();
      params.append("start_date", selectedDates.start_date);
      params.append("end_date", selectedDates.end_date);

      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/generate_excel?${params}`, {
        method: "GET",
        headers: {
          "X-Api-Token": accessToken,
        },
      });
      if (response.ok) {
        const blob = await response.blob();

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Super Tutor Usage Report - ${selectedDates.start_date} to ${selectedDates.end_date}.xlsx`;
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link element
        document.body.removeChild(link);
      } else {
        // Handle error if needed
        console.error("Error downloading file");
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const getAssessmentData = async (params) => {
    try {
      setCategoryData(null);
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/category_usage_chart?${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
        }
      );
      response = await response.json();
      setCategoryData(response);
    } catch (e) {
      console.log("error:", e);
    }
  };
  useEffect(() => {
    getFiscalYear();
  }, []);
  useEffect(() => {
    if (Object.keys(selectedDates).length === 0) return;
    const params = new URLSearchParams();
    params.append("start_date", selectedDates.start_date);
    params.append("end_date", selectedDates.end_date);
    getFilterUsageData(params);
    getUseCaseData(params);
    getUsageMetrics(params);
    getTrendingCard(params);
    getAssessmentData(params);
  }, [selectedDates]);

  useEffect(() => {
    if (!usageMetricsData || !trendingData || !categoryData) return;
    let empty_list = {};
    for (const [key, value] of Object.entries(usageMetricsData)) {
      let isEmpty = true;
      for (let i = 0; i < value.length; i++) {
        if (value[i].value !== 0) {
          isEmpty = false;
          break;
        }
      }
      empty_list[key] = isEmpty;
    }
    empty_list["trendingData"] = trendingData.length === 0;
    let isEmpty = true;
    for (let i = 0; i < categoryData.length; i++) {
      if (categoryData[i].value > 0) {
        isEmpty = false;
        break;
      }
    }
    empty_list["categoryData"] = isEmpty;
    setEmptyData({ ...isEmptyData, ...empty_list });
  }, [usageMetricsData, trendingData, categoryData]);

  useEffect(() => {
    let isEmptyUsageMetrics = true;
    if (!isEmptyFilterData || !isEmptyUsageData) {
      setAllEmpty(false);
      return;
    }
    for (const [key, value] of Object.entries(isEmptyData)) {
      if (value === false) {
        setAllEmpty(false);
        return;
      }
    }
    if (isEmptyUsageData && isEmptyFilterData && isEmptyUsageMetrics)
      setAllEmpty(true);
  }, [isEmptyData, isEmptyFilterData, isEmptyUsageData]);

  useEffect(() => {
    const checkRowStatus = () => {
      const container = document.getElementById("usage-metric-all-graph-box");
      const containerWidth = container ? container.clientWidth : 0;
      const childDivs = document.querySelectorAll(".flex-graph");

      let totalChildWidth = 0;
      childDivs.forEach((div) => {
        totalChildWidth += div.clientWidth;
      });

      setIsSingleRow(totalChildWidth <= containerWidth);
    };

    checkRowStatus();
    window.addEventListener("resize", checkRowStatus);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkRowStatus);
    };
  }, []);

  if (
    !filtersItemList ||
    !useUsageData ||
    !usageMetricsData ||
    periodList.length === 0 ||
    !categoryData ||
    !trendingData
  ) {
    return <Spin className="dashboard-loader" />;
  }
  return (
    <div className="sub-page">
      <div className="heading-tab-dashboard">
        <span className="content-heading-main">Dashboard</span>
        <div className="right-section-header">
          <div className="calender-section">
            <span>Period:</span>
            <DatePickCustom
              selectedYear={selectedYear}
              setSelectedDates={setSelectedDates}
              currentYear={currentYear}
            />
            <Select
              value={selectedYear}
              onChange={handleChange}
              options={periodList}
              // className="fiscal-year-select"
              className="custom-select"
              styles={customStyles}
            />
          </div>
          <div className="edit-question-button" onClick={getAllEntries}>
            Download
          </div>
        </div>
      </div>
      {isAllEmpty ? (
        <div className="empty-data-img-box">
          <img
            src="/assets/all-empty.svg"
            className="trending-empty-image-full-page"
            alt=""
          />
          <span className="empty-text">{empty_text}</span>
        </div>
      ) : (
        <>
          <div className="usage-metrics-card">
            <div className="metric-usage-header">
              <div className="card-header-box">
                <span className="filter-usage-heading">
                  User Engagement Metrics
                </span>
                <Tooltip
                  placement="bottomLeft"
                  overlayStyle={{ maxWidth: "40rem" }}
                  title={
                    <div className="card-tooltip-container">
                      {/* <span className="card-container-heading">
                        User Engagement Metrics:
                      </span> */}
                      {cards_description.usage}
                      <ul>
                        {usage_metrics.map((item) => {
                          return (
                            <li className="aligned-li">
                              <span className="card-container-heading">
                                {item.heading}:
                              </span>
                              {item.text}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  }
                  color="#FFFFFF"
                >
                  <img
                    alt=""
                    src="/assets/new-info.svg"
                    className="card-info-icon-sizing"
                  />
                </Tooltip>
              </div>
            </div>
            <div
              className="usage-metric-all-graph-box"
              id="usage-metric-all-graph-box"
            >
              {!isEmptyData.average_usage ||
              !isEmptyData.retention_rate ||
              !isEmptyData.unique_user ||
              !isEmptyData.session_usage ? (
                <>
                  <div className="flex-graph">
                    <MetricsGradientCard
                      graph={graph_details.frequency_graph}
                      data={usageMetricsData.average_usage}
                      averageGraphCount={averageGraphCount}
                      isEmpty={isEmptyData.average_usage}
                      imgPath="/assets/empty-1.svg"
                    />
                    <div className="usage-metrics-divider" />
                    <MetricsLinearCard
                      graph={graph_details.retention_rate}
                      data={usageMetricsData.retention_rate}
                      averageGraphCount={averageGraphCount}
                      isEmpty={isEmptyData.retention_rate}
                      imgPath="/assets/empty-2.svg"
                    />
                  </div>
                  {isSingleRow && <div className="usage-metrics-divider" />}
                  <div className="flex-graph">
                    <MetricsGradientCard
                      graph={graph_details.unique_user_graph}
                      data={usageMetricsData.unique_user}
                      averageGraphCount={averageGraphCount}
                      isEmpty={isEmptyData.unique_user}
                      imgPath="/assets/empty-3.svg"
                    />
                    <div className="usage-metrics-divider" />
                    <MetricsGradientCard
                      graph={graph_details.session_graph}
                      data={usageMetricsData.session_usage}
                      averageGraphCount={averageGraphCount}
                      isEmpty={isEmptyData.session_usage}
                      imgPath="/assets/empty-4.svg"
                    />
                  </div>
                </>
              ) : (
                <div className="empty-data-img-box">
                  <img
                    src="/assets/usage.svg"
                    className="trending-empty-image"
                    alt=""
                  />
                  <span className="empty-text">{empty_text}</span>
                </div>
              )}
            </div>
          </div>
          <div className="bottom-dashboard-section">
            <div className="use-case-filter">
              <div className="use-case-card">
                {useUsageData && (
                  <UseCaseCard
                    data_show={useUsageData}
                    isEmptyData={isEmptyUsageData}
                    setEmptyData={setEmptyUsageData}
                  />
                )}
              </div>
              <div className="filter-card">
                {filterOptions.length !== 0 && (
                  <FilterUsageCard
                    filterOptions={filterOptions}
                    filtersItemList={filtersItemList}
                    isEmptyData={isEmptyFilterData}
                    setEmptyData={setEmptyFilterData}
                  />
                )}
              </div>
              <div className="assesment-strategy-card">
                <div className="metric-usage-header">
                  <div className="card-header-box">
                    <span className="filter-usage-heading">
                      Category Usage Frequency
                    </span>
                    <Tooltip
                      placement="bottomLeft"
                      overlayStyle={{ maxWidth: "40rem" }}
                      title={
                        <div className="card-tooltip-container">
                          {cards_description.category_usgae}
                        </div>
                      }
                      color="#FFFFFF"
                    >
                      <img
                        alt=""
                        src="/assets/new-info.svg"
                        className="card-info-icon-sizing"
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className="assessment-graph-box">
                  <AssessmentStrategy
                    data={categoryData}
                    isEmpty={isEmptyData["categoryData"]}
                  />
                </div>
              </div>
            </div>
            <div className="trending-learning-card">
              <TrendingLearningCard data={trendingData} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
