import React from "react";
import "./CotentsTable.css";

export default function CotentsTable({
  trainings,
  setShowTraining,
  setEditTraining,
  setViewSection,
  setDeleteTraining,
}) {
  const headerOptions = ["Name", "Description", "Duration", "Action"];
  return (
    <div className="contents-table-box">
      <div className="table-header">
        <span className="header-options c-30">{headerOptions[0]}</span>
        <span className="header-options c-50">{headerOptions[1]}</span>
        <span className="header-options c-10">{headerOptions[3]}</span>
      </div>
      {trainings.map((item) => {
        return (
          <div className="table-content">
            <span className="content-options c-30">
              <a href={item?.link} target="_blank" className="link-name">
                {item?.title}
              </a>
            </span>
            <span className="content-options c-50">{item?.description}</span>
            <span className="content-options c-10">
              <img
                src="/assets/file.svg"
                className="icon-sizing"
                onClick={() => {
                  setShowTraining(item?.id);
                  setViewSection(true);
                }}
              />
              <img
                src="/assets/Edit.svg"
                className="icon-sizing"
                onClick={() => {
                  setShowTraining(item?.id);
                  setEditTraining(true);
                  setViewSection(true);
                }}
              />
              <img
                src="/assets/DeleteOutlined.svg"
                className="icon-sizing"
                onClick={() => setDeleteTraining(item?.id)}
              />
            </span>
          </div>
        );
      })}
    </div>
  );
}
