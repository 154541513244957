import React, { useState } from "react";
import "./EmptyPdfSection.css";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

export default function EmptyPdfSection({ text, video = false }) {
  const [show, setShow] = useState(false);
  const showVideoPlayer = () => {
    if (video) setShow(true);
  };
  return show ? (
    <VideoPlayer />
  ) : (
    <div className="no-pdf-content">
      <div className="no-pdf-content-left">
        <img
          src="./assets/supertutor-icon.png"
          alt=""
          onClick={showVideoPlayer}
        />
      </div>
      <div className="no-pdf-content-right">
        <div className="supertutor-tip-arrow"></div>
        <div className="supertutor-tip-content">
          <div>
            <img src="./assets/supertutor-tip.png" alt="" />
          </div>
          <div className="tip-text-separator"></div>
          <div className="supertutor-tip-text">{text}</div>
        </div>
      </div>
    </div>
  );
}
