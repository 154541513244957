import React, { useEffect, useRef, useContext } from "react";
import "./ChatBotContent.css";
import TrainingsRecommended from "../TrainingsRecommended/TrainingsRecommended";
import QuestionsGenAI from "../QuestionsGenAI/QuestionsGenAI";
import ChatInput from "../ChatInput/ChatInput";
import { v4 as uuidv4 } from "uuid";
import FilterRow from "../FilterRow/FilterRow";
import Feedback from "../Feedback/Feedback";
import { Modal, Tour } from "antd";
import Navbar from "../Navbar/Navbar";
import ReflectiveQuestions from "../ReflectiveQuestions/ReflectiveQuestions";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { TourContext } from "../../../../Context/TourContext";
import { ConversationContext } from "../../../../Context/ConversationContext";
import { AppStateContext } from "../../../../Context/AppStateContext";
import { FilterContext } from "../../../../Context/FilterContext";
import { TrainingsContext } from "../../../../Context/TrainingsContext";
import { useAppUtilities } from "../../../../Hooks/useAppUtilities";
import { useConversations } from "../../../../Hooks/useConversations";
import { useRecommendedTrainings } from "../../../../Hooks/useRecommendedTrainings";

dayjs.extend(utc);

let fetchedFilters = JSON.parse(localStorage.getItem("filters"));
export default function ChatBotContent({
  setShowChatBot,
  sendMsg,
  screenHeight,
  showAlert,
  setShowAlert,
}) {
  const { toggleTab, setToggleTab } = useContext(AppStateContext);

  const {
    parentId,
    quesContext,
    conversationId,
    disabledQuestionRef,
    disabledQuestion,
    defaultContent,
    isLoadingDefaultContent,
    isLoadingChat,
    setIsLoadingChat,
  } = useContext(ConversationContext);

  const { setNoOfFilters } = useContext(FilterContext);

  const {
    isLoadingRecTrainings,
    isLoadingTrainingHistory,
    disabledTraining,
    recTrainings,
    trainingHistId,
    reflectTabOpen,
    setReflectTabOpen,
    scrollNearBottom,
    setScrollNearBottom,
  } = useContext(TrainingsContext);

  const { user_interaction } = useAppUtilities();
  const {
    handleSubmit,
    handleStopGenerate,
    getDefaultContent,
    getConversation,
  } = useConversations();
  const {
    getAllFilters,
    getTrainingHistory,
    handleMessage,
    getRecommendedTrainings,
  } = useRecommendedTrainings();

  const scrollingDivRef = useRef(null);

  const shouldAutoScrollRef = useRef(false);

  //Tour of the UI
  const {
    welcomeMsgRef,
    smallViewSteps,
    expandedViewSteps,
    currentStep,
    setCurrentStep,
    tourOpen,
    setTourOpen,
    smallTourRef8,
    smallTourRef9,
  } = useContext(TourContext);

  const handlePageHide = () => {
    if (disabledQuestionRef.current) handleStopGenerate();
    user_interaction("", {}, {}, {}, true);
  };

  useEffect(() => {
    let currentTime = dayjs().utc();
    let lastInteractionTime =
      localStorage.getItem("lastInteractionTime") || currentTime;
    let expirationTime = localStorage.getItem("expirationTime");
    if (
      !sessionStorage.getItem("sessionId") ||
      currentTime.diff(lastInteractionTime, "minute") > 15
    ) {
      let sessionId = uuidv4();
      sessionStorage.setItem("sessionId", sessionId);
      let sessionPayload = {
        start_time: dayjs().utc().format("YYYY-MM-DD HH:mm:ss"),
        session_id: sessionId,
      };
      user_interaction("", {}, sessionPayload, {});
      if (!expirationTime) {
        localStorage.setItem("expirationTime", currentTime);
      } else if (currentTime.diff(expirationTime, "day") > 0) {
        localStorage.setItem("expirationTime", currentTime);
        conversationId.current = uuidv4();
        parentId.current = null;
        quesContext.current = false;
        localStorage.removeItem("ConversationId");
        localStorage.removeItem("ParentId");
        localStorage.removeItem("filepath");
        localStorage.removeItem("pdf");

        trainingHistId.current = uuidv4();
        localStorage.removeItem("filters");
        localStorage.removeItem("trainingkey");
        fetchedFilters = null;
        setNoOfFilters(0);
        setIsLoadingChat(false);
      }
    }
    getDefaultContent();
    getAllFilters();
    getRecommendedTrainings();
    window.addEventListener("pagehide", handlePageHide);
    window.addEventListener("storage", (event) => {
      if (event.key === "ConversationId") {
        conversationId.current = event.newValue;
      }
      if (event.key === "ParentId") {
        parentId.current = event.newValue;
        quesContext.current = true;
        getConversation();
      }
    });

    return () => {
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, []);

  useEffect(() => {
    if (recTrainings.length > 0) {
      getTrainingHistory();
    }
  }, [recTrainings]);

  useEffect(() => {
    if (defaultContent && parentId.current) getConversation();
  }, [defaultContent]);

  const scrollToBottom = () => {
    const divElement = scrollingDivRef.current;
    if (divElement) {
      const scrollHeight = divElement.scrollHeight;
      const clientHeight = divElement.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      divElement.scrollTo({
        top: maxScrollTop,
        behavior: "smooth",
      });
    }
    setScrollNearBottom(false);
  };

  const handleLittleScroll = () => {
    const scrollableDiv = scrollingDivRef.current;
    if (scrollableDiv) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;
      if (scrollHeight - scrollTop < clientHeight + 150) {
        shouldAutoScrollRef.current = true;
      } else {
        shouldAutoScrollRef.current = false;
      }
    }
  };

  useEffect(() => {
    if (scrollNearBottom) {
      scrollToBottom();
    }
  }, [scrollNearBottom]);

  return (
    <>
      <div className="chat-bot-container">
        <Modal
          open={showAlert}
          width="25rem"
          centered
          onCancel={() => setShowAlert(false)}
          footer={null}
          closable={false}
          maskClosable={false}
        >
          <div className="session-modal">
            <div>This site has expired. Please refresh the site.</div>
            <div>
              <button
                className="session-modal-button"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </button>
            </div>
            <div className="session-modal-info">
              For security reasons the content on this site expires after 15
              mins of inactivity. You must refresh the site to continue.
            </div>
          </div>
        </Modal>
        <div
          className="chat-bot-content-box"
          style={screenHeight <= 720 ? { height: "34rem" } : {}}
        >
          <Navbar
            setShowChatBot={setShowChatBot}
            sendMsg={sendMsg}
            user_interaction={user_interaction}
          />
          <div
            className={
              toggleTab === 3 || toggleTab === 4
                ? "chat-bot-stretched-area"
                : "chat-bot-activity-area"
            }
          >
            {toggleTab === 1 ? (
              <QuestionsGenAI />
            ) : toggleTab === 2 ? (
              <TrainingsRecommended
                scrollToBottom={scrollToBottom}
                scrollingDivRef={scrollingDivRef}
                handleLittleScroll={handleLittleScroll}
                shouldAutoScrollRef={shouldAutoScrollRef}
              />
            ) : toggleTab === 3 ? (
              <Feedback />
            ) : toggleTab === 4 ? (
              <ReflectiveQuestions />
            ) : null}
          </div>

          {toggleTab === 1 ? (
            <ChatInput
              handleSubmit={handleSubmit}
              disabledInput={isLoadingChat || isLoadingDefaultContent}
              disabled={disabledQuestion}
            />
          ) : toggleTab === 2 ? (
            <div className="input-bottom-recom">
              {(tourOpen || !isLoadingRecTrainings) && (
                <div className="reflective-ques-bottom" ref={smallTourRef8}>
                  <div
                    className="reflective-ques-tab"
                    onClick={() => {
                      setReflectTabOpen(!reflectTabOpen);
                      handleLittleScroll();
                      if (shouldAutoScrollRef) setScrollNearBottom(true);
                    }}
                  >
                    <div className="reflective-icon">
                      <img src="./assets/reflective.svg" alt="" />
                    </div>
                    <div>Personalize Your Recommendations</div>
                    <div className="reflective-open-icon">
                      {reflectTabOpen ? (
                        <img src="./assets/openDark.svg" alt="" />
                      ) : (
                        <img src="./assets/closeDark.svg" alt="" />
                      )}
                    </div>
                  </div>
                  {reflectTabOpen && (
                    <div className="reflective-ques-instructions">
                      <div className="reflective-ques-instructions-text">
                        Answer reflective questions about your current skills
                        and needs to get personalized learning recommendations.
                        Click Begin to proceed.
                      </div>
                      <div
                        className="reflective-button"
                        onClick={() => {
                          setToggleTab(4);
                        }}
                        ref={smallTourRef9}
                      >
                        Begin
                      </div>
                    </div>
                  )}
                </div>
              )}
              <FilterRow />
              <ChatInput
                handleSubmit={handleMessage}
                disabledInput={isLoadingTrainingHistory}
                disabled={disabledTraining}
              />
            </div>
          ) : null}
        </div>
      </div>
      <Tour
        open={tourOpen}
        onClose={() => {
          welcomeMsgRef.current = false;
          setTourOpen(false);
          setCurrentStep(0);
          if (!localStorage.getItem("isFirstTourUser")) {
            user_interaction("", {}, {}, {}, true);
          }
          localStorage.removeItem("tourFromExView");
          localStorage.removeItem("tourFromSmView");
        }}
        disabledInteraction={true}
        steps={smallViewSteps}
        rootClassName="antd-tour-modal"
        current={currentStep}
        indicatorsRender={(current, total) => (
          <span className="tour-step-indicator">
            {current + 1} /{" "}
            {smallViewSteps.length + expandedViewSteps.length - 1}
          </span>
        )}
      />
    </>
  );
}
