import { createContext, useState } from "react";

export const AppStateContext = createContext();

let toggleValue = localStorage.getItem("Toggle");
if (!toggleValue || toggleValue === "0") toggleValue = 1;
export const AppStateContextProvider = ({ children }) => {
  const [toggleTab, setToggleTab] = useState(+toggleValue);
  const [prevValue, setPrevValue] = useState(1);
  const [inputValue, setInputValue] = useState("");

  const globalValues = {
    toggleTab,
    setToggleTab,
    prevValue,
    setPrevValue,
    inputValue,
    setInputValue,
  };

  return (
    <AppStateContext.Provider value={globalValues}>
      {children}
    </AppStateContext.Provider>
  );
};
