import React, { useContext } from "react";
import "./TrainingsRecommended.css";
import TrainingTable from "../TrainingTable/TrainingTable";
import { TourContext } from "../../../../Context/TourContext";
import { training_history_dummy2 } from "../../../../constants/GlobalConstants";
import { TrainingsContext } from "../../../../Context/TrainingsContext";

export default function TrainingsRecommended() {
  const { tourOpen } = useContext(TourContext);

  const {
    isLoadingRecTrainings,
    isLoadingFilterTrainings,
    currentTrainings,
    showCurrentTrainings,
  } = useContext(TrainingsContext);

  let isLoading =
    !tourOpen &&
    !showCurrentTrainings &&
    (isLoadingRecTrainings || isLoadingFilterTrainings || !currentTrainings);
  let currentTrainingsVisible = tourOpen
    ? training_history_dummy2[0].data
    : currentTrainings;

  return (
    <div className="training-section-box">
      {!isLoading && (
        <>
          <div className="training-section-instructions">
            Refer to the section below for a list of trainings and their
            details. Follow the instructions on the right to filter training
            recommendations based on your preferences.
          </div>
          <TrainingTable data={currentTrainingsVisible} />
        </>
      )}
    </div>
  );
}
