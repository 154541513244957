import React, { useContext, useEffect, useState } from "react";
import "./AdminFeedback.css";
import { Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Base_Url, Base_Url_Admin } from "../../../../Utils/urls";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import ExcelJS from "exceljs";
import ResponsesTable from "../ResponsesTable/ResponsesTable";
import { user_responses } from "../../../../constants/GlobalConstants";
import { AuthContext } from "../../../../Context/AuthContext";
let headerOptions = [];
let defaultOptions = [];
let allFeedbacks = [];
export default function AdminFeedback() {
  const { getToken } = useContext(AuthContext);
  const [feedbacks, setFeedback] = useState([]);
  const [page, setPage] = useState(1);
  const [isForm, setForm] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [questions, setQuestions] = useState(null);
  const [editQuestion, setEditQuestion] = useState(false);
  const getUserFeedback = async (value) => {
    try {
      const params = new URLSearchParams();
      params.append("page", value ?? page);
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/feedback_admin?${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
      });
      let feedback_response = await response.json();
      accessToken = await getToken();
      let response2 = await fetch(`${Base_Url_Admin}/feedback_admin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
      });
      allFeedbacks = await response2.json();
      if (feedback_response.data.length === 0) {
        setFeedback(feedback_response);
        return;
      }
      // const tableColumms = Object.keys(feedback_response?.data[0]);
      let headers=[]
      for (const [key, value] of Object.entries(feedback_response?.data[0])) {
        // filterCols.push(key);
        headers.push({header:key,is_expanded:!value.is_rating})
      }
      headerOptions = headers;
      setFeedback(feedback_response);
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const getFeedbackQuestions = async () => {
    setQuestions(null);
    try {
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url}/feedback_ppmd`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
      });
      let result = await response.json();
      setQuestions(result?.data);
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const handleFeedbacks = (value) => {
    if (value.length === 0) return;
    if (value === "next") {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
    return;
  };

  const handleEditOption = () => {
    if (isForm) {
      setEditQuestion(true);
    }
    return;
  };
  const handleExport = async () => {
    if (allFeedbacks?.data?.length === 0) return;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Add headers
    worksheet.addRow(headerOptions.map((item)=>item.header));

    Array.from({ length: allFeedbacks?.data.length }, (_, index) => {
      let new_row = [];
      Object.keys(allFeedbacks?.data[index]).map((item) => {
        new_row.push(allFeedbacks?.data[index][item].answer);
      });
      worksheet.addRow(new_row);
    });

    // Create a Blob from workbook
    const blob = await workbook.xlsx.writeBuffer();

    // Create download link
    const url = URL.createObjectURL(new Blob([blob]));
    const a = document.createElement("a");
    a.href = url;
    a.download = "Super Tutor_User Feedback.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Perform the action you want when Enter is pressed
      if (inputValue > feedbacks?.total_pages) {
        setInputValue("");
        return;
      }
      setPage(inputValue);
    }
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  useEffect(() => {
    getFeedbackQuestions();
  }, []);
  useEffect(() => {
    getUserFeedback(page);
  }, [page]);
  return (
    <div className="sub-page">
      <div className="heading-tab-pad">
        <span className="content-heading-main">Feedback</span>
      </div>
      <span className="sub-content">
        Manage the Super Tutor feedback form and review user responses within
        this section.
      </span>
      <div className="navigation-tab">
        <span
          className={isForm ? "data-selected-tab" : "data-tab"}
          onClick={() => {
            setForm(true);
          }}
        >
          Form
        </span>
        <span
          className={isForm ? "config-tab" : "config-selected-tab"}
          onClick={() => {
            setForm(false);
          }}
        >
          Responses
        </span>
      </div>
      <div className="admin-feedback-nav-heading">
        {isForm ? "Questions" : "View Responses"}
        {/* <div
          className="edit-question-button"
          onClick={isForm ? handleEditOption : handleExport}
        >
          {isForm ? "Edit" : "Download"}
        </div> */}
        {isForm ? (
          <div
            className={
              editQuestion
                ? "edit-question-button button-faded"
                : "edit-question-button"
            }
            onClick={handleEditOption}
          >
            Edit
          </div>
        ) : (
          <div className="edit-question-button" onClick={handleExport}>
            Download
          </div>
        )}
      </div>
      {isForm ? (
        !questions ? (
          <Spin />
        ) : (
          <FeedbackForm
            questions={questions}
            setQuestions={setQuestions}
            getFeedbackQuestions={getFeedbackQuestions}
            getUserFeedback={getUserFeedback}
            editQuestion={editQuestion}
            setEditQuestion={setEditQuestion}
          />
        )
      ) : feedbacks.length === 0 ? (
        <Spin />
      ) : (
        <>
          <ResponsesTable
            data={feedbacks?.data}
            header_options={headerOptions}
          />
          <div className="pagination-box">
            <div className="centered-box">
              <LeftOutlined
                className={!feedbacks?.has_previous ? "cursor-n" : ""}
                onClick={() => {
                  handleFeedbacks(feedbacks?.has_previous ? "prev" : "");
                }}
              />
            </div>
            <div className="centered-box">{page}</div>
            {/* {page} of {trainings?.total_pages} */}
            <div className="centered-box">
              <RightOutlined
                className={!feedbacks?.has_next ? "cursor-n" : ""}
                onClick={() => {
                  handleFeedbacks(feedbacks?.has_next ? "next" : "");
                }}
              />
            </div>
            <div className="centered-box">
              {page}
              {"/"}
              {feedbacks?.total_pages}{" "}
            </div>
            <div className="page-input-box">
              <span className="go-to-text">Go to</span>
              <input
                type="text"
                value={inputValue}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                className={
                  inputValue > feedbacks?.total_pages
                    ? "pagination-input-box error-color"
                    : "pagination-input-box"
                }
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
