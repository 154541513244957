import { createContext, useState } from "react";

export const PdfContext = createContext();

export const PdfContextProvider = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progressSummary, setProgressSummary] = useState(0);
  const [progressQuestions, setProgressQuestions] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [summary, setSummary] = useState([]);
  const [file, setFile] = useState(
    localStorage.getItem("pdf") === "null" ? null : localStorage.getItem("pdf")
  );
  const [pathToFiles, setPathToFiles] = useState(
    localStorage.getItem("filepath") === "null"
      ? null
      : localStorage.getItem("filepath")
  );
  const [pdfInitialized, setPdfInitialized] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [disabledLinks, setDisabledLinks] = useState([]);

  const pdfValues = {
    currentIndex,
    setCurrentIndex,
    progressSummary,
    setProgressSummary,
    progressQuestions,
    setProgressQuestions,
    questions,
    setQuestions,
    summary,
    setSummary,
    file,
    setFile,
    pathToFiles,
    setPathToFiles,
    pdfInitialized,
    setPdfInitialized,
    currentTab,
    setCurrentTab,
    disabledLinks,
    setDisabledLinks,
  };

  return (
    <PdfContext.Provider value={pdfValues}>{children}</PdfContext.Provider>
  );
};
