import React, { useContext, useEffect, useRef, useState } from "react";
import "./QuestionsGenAI.css";
import MessageBox from "../MessageBox/MessageBox";
import { Spin, Tooltip } from "antd";
import {
  default_content_dummy,
  pdf_questions_dummy,
  responses_dummy,
} from "../../../../constants/GlobalConstants";
import ChatInput from "../ChatInput/ChatInput";
import { TourContext } from "../../../../Context/TourContext";
import { AppStateContext } from "../../../../Context/AppStateContext";
import { ConversationContext } from "../../../../Context/ConversationContext";
import { PdfContext } from "../../../../Context/PdfContext";
import { useConversations } from "../../../../Hooks/useConversations";

export default function QuestionsGenAI() {
  const { tourOpen, currentStep, expTourRef4, expTourRef9 } =
    useContext(TourContext);

  const { toggleTab } = useContext(AppStateContext);

  const {
    disabledQuestion,
    scrollActive,
    setScrollActive,
    defaultContent,
    isLoadingDefaultContent,
    responses,
    isLoadingChat,
    shouldAutoScrollRef,
  } = useContext(ConversationContext);

  const {
    currentIndex,
    setCurrentIndex,
    questions,
    file,
    setFile,
    setPathToFiles,
    pdfInitialized,
    currentTab,
    setCurrentTab,
  } = useContext(PdfContext);

  const { handleSubmit } = useConversations();

  const scrollingDivRef = useRef(null);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  useEffect(() => {
    if (!tourOpen && (scrollActive || toggleTab)) {
      scrollToBottom();
    }
  }, [scrollActive, toggleTab, tourOpen]);

  useEffect(() => {
    if (
      !scrollActive &&
      shouldAutoScrollRef.current &&
      scrollingDivRef.current
    ) {
      scrollingDivRef.current.scrollTop =
        scrollingDivRef.current.scrollHeight -
        scrollingDivRef.current.clientHeight;
    }
  }, [responses, currentIndex, pdfInitialized]);

  useEffect(() => {
    if (!shouldAutoScrollRef.current) shouldAutoScrollRef.current = true;
    return () => {
      if (!disabledQuestion) setScrollActive(true);
    };
  }, []);

  useEffect(() => {
    if (currentStep === 4) scrollToBottom();
  }, [currentStep]);

  const scrollToBottom = () => {
    const divElement = scrollingDivRef.current;
    if (divElement) {
      const scrollHeight = divElement.scrollHeight;
      const clientHeight = divElement.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      divElement.scrollTo({
        top: maxScrollTop,
        behavior: "smooth",
      });
      setScrollActive(false);
    }
  };

  const handleScroll = () => {
    if (scrollingDivRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = scrollingDivRef.current;
      shouldAutoScrollRef.current =
        scrollHeight - clientHeight <= scrollTop + 22;
    }
  };

  const changeQuestion = (item, e, defaultQuestion = false, index) => {
    if (disabledQuestion) {
      return;
    }
    setSelectedQuestion(index);
    handleSubmit(e, item, defaultQuestion);
  };

  let isLoading = !tourOpen && (isLoadingChat || isLoadingDefaultContent);
  let isPdfVisible = tourOpen || file;
  let contentInitialized = tourOpen || pdfInitialized;
  let defaultContentVisible = tourOpen
    ? default_content_dummy
    : defaultContent ?? {};
  let responsesVisible = tourOpen ? responses_dummy : responses;
  let questionsVisible = tourOpen ? pdf_questions_dummy : questions;
  let currentIndexVisible = tourOpen ? 0 : currentIndex;

  return (
    <>
      {isLoading ? (
        <div className="loading-chat">
          <Spin />
        </div>
      ) : (
        <div
          className="responses-section"
          ref={scrollingDivRef}
          onScroll={handleScroll}
          style={tourOpen ? { flexDirection: "column-reverse" } : {}}
        >
          <div className="questions-gen-ai-container-expanded">
            {defaultContentVisible.hasOwnProperty("message") && (
              <div className="generative-ai-questions">
                <MessageBox
                  mObj={{
                    user: "chatbot",
                    message: defaultContentVisible?.message,
                  }}
                  style={{ marginTop: "0" }}
                />
                <div className="default-questions-container" ref={expTourRef9}>
                  {defaultContentVisible?.questions?.map((item, index) => {
                    return (
                      <div
                        className={`default-question ${
                          selectedQuestion === index + 1
                            ? "default-question-selected"
                            : ""
                        } ${disabledQuestion ? "cursor-n" : ""}`}
                        onClick={(e) => {
                          changeQuestion(item, e, false, index + 1);
                        }}
                        key={index}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {responsesVisible.map((mObj) => (
              <div
                style={mObj.user === "user" ? { justifyContent: "end" } : {}}
                className="aligning-message"
              >
                <MessageBox
                  mObj={mObj}
                  file={file}
                  setFile={setFile}
                  setPathToFiles={setPathToFiles}
                  setCurrentIndex={setCurrentIndex}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div>
        {isPdfVisible && contentInitialized && (
          <>
            {questionsVisible?.length !== 0 &&
              questionsVisible?.map((value, index) => (
                <>
                  {currentIndexVisible === index &&
                    (value.length !== 0 ? (
                      <div
                        className="suggestive-question-section"
                        ref={expTourRef4}
                      >
                        <div className="user-question-no-filter">
                          <div className="suggestive-text-filter">
                            Suggested Questions:
                          </div>
                          &nbsp;
                          <div className="suggestive-question-no-container">
                            <div
                              className="question-arrow-filter"
                              disabled={value.length > 1}
                              onClick={() => {
                                currentTab === 0
                                  ? setCurrentTab(value.length - 1)
                                  : setCurrentTab(currentTab - 1);
                              }}
                            >
                              <img src="./assets/BackBtn.png" alt="" />
                            </div>
                            <div>
                              {currentTab + 1} of {value?.length}
                            </div>
                            <div
                              className="question-arrow-filter"
                              disabled={value.length > 1}
                              onClick={() => {
                                currentTab === value.length - 1
                                  ? setCurrentTab(0)
                                  : setCurrentTab(currentTab + 1);
                              }}
                            >
                              <img src="./assets/NextBtn.png" alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="user-question-text-filter">
                          <Tooltip
                            title={
                              <span className="question-box-tooltip-filter">
                                Click to ask question
                              </span>
                            }
                            placement="topLeft"
                            color="#444444"
                          >
                            <div
                              className={
                                disabledQuestion
                                  ? "user-question-filter cursor-n"
                                  : "user-question-filter cursor-p"
                              }
                              onClick={(e) =>
                                changeQuestion(value[currentTab], e)
                              }
                            >
                              {value[currentTab]}
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    ) : null)}
                </>
              ))}
          </>
        )}
        <ChatInput
          handleSubmit={handleSubmit}
          disabledInput={isLoadingChat || isLoadingDefaultContent}
          disabled={disabledQuestion}
        />
      </div>
    </>
  );
}
