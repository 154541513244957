import React, { useContext, useEffect, useRef, useState } from "react";
import "./FilterRow.css";
import { Spin } from "antd";
import FilterOption from "../FilterOption/FilterOption";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { TourContext } from "../../../../Context/TourContext";
import {
  filter_options_dummy,
  selected_filters_dummy,
} from "../../../../constants/GlobalConstants";
import { FilterContext } from "../../../../Context/FilterContext";
import { TrainingsContext } from "../../../../Context/TrainingsContext";
import { useRecommendedTrainings } from "../../../../Hooks/useRecommendedTrainings";
export default function FilterRow() {
  const { tourOpen, currentStep, expTourRef12, expTourRef18 } =
    useContext(TourContext);

  const {
    filterOptions,
    selectedFilters,
    setSelectedFilters,
    prevSelectedFilters,
    isLoadingFilter,
    showFilter,
    setShowFilter,
    noOfFilters,
    isFilterCleared,
    setIsFilterCleared,
    selectionAllObj,
  } = useContext(FilterContext);

  const { retainContext, isLoadingFilterTrainings } =
    useContext(TrainingsContext);

  const { handleApply, handleClearOption } = useRecommendedTrainings();

  const [currentSlice, setCurrentSlice] = useState(1);
  const totalSlices = useRef(0);
  const sliceLength = useRef(10);
  const [currentFilterList, setCurrentFilterList] = useState([]);

  useEffect(() => {
    let listLength = Object.keys(filterOptions).length;
    if (listLength > 0) {
      totalSlices.current = Math.ceil(listLength / sliceLength.current);
      let startIndex = (currentSlice - 1) * sliceLength.current;
      let endIndex = Math.min(startIndex + sliceLength.current, listLength);
      setCurrentFilterList(
        Object.keys(filterOptions).slice(startIndex, endIndex)
      );
    }
  }, [filterOptions, currentSlice]);

  const handleNextSlice = () => {
    if (currentSlice < totalSlices.current) {
      setCurrentSlice((prevSlice) => prevSlice + 1);
    }
  };

  const handlePrevSlice = () => {
    if (currentSlice > 1) {
      setCurrentSlice((prevSlice) => prevSlice - 1);
    }
  };

  const toggleFilterDisplay = () => {
    if (!retainContext && !showFilter) {
      handleClearOption();
    }
    if (!isFilterCleared) setIsFilterCleared(true);
    setShowFilter(!showFilter);
  };

  const handleOptionChange = (filterKey, selectedOption) => {
    const currentSelectedOptions = [...selectedFilters[filterKey]];

    const isOptionSelected = currentSelectedOptions.some(
      (option) => option.id === selectedOption.id
    );

    let updatedOptions;

    if (isOptionSelected) {
      // Deselect the option
      updatedOptions = currentSelectedOptions.filter(
        (option) => option.id !== selectedOption.id
      );
    } else {
      // Select the option
      updatedOptions = [...currentSelectedOptions, selectedOption];
    }

    setSelectedFilters({
      ...selectedFilters,
      [filterKey]: updatedOptions,
    });
  };

  const handleAllFilterOption = () => {
    selectionAllObj.current = {};
    for (const key in filterOptions) {
      if (filterOptions.hasOwnProperty(key)) {
        selectionAllObj.current[key] = filterOptions[key].map((item) => item);
      }
    }
    setSelectedFilters(selectionAllObj.current);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowFilter(false);
        setSelectedFilters(prevSelectedFilters);
      }
    };

    if (showFilter) {
      window.addEventListener("click", closeDropdown);
    }

    return () => {
      window.removeEventListener("click", closeDropdown);
    };
  }, [showFilter, selectedFilters]);

  let isLoading = !tourOpen && isLoadingFilter;
  let currentFilterListVisible = tourOpen
    ? Object.keys(filter_options_dummy)
    : currentFilterList;
  let tourFilterVisible = tourOpen ? currentStep === 12 : false;
  let selectedFiltersVisible = tourOpen
    ? selected_filters_dummy
    : selectedFilters;

  return (
    <div className="filter-wrapper" ref={dropdownRef}>
      <CustomTooltip
        title={"Click the filter icon to reset and select new filters"}
        visible={!retainContext && noOfFilters > 0}
        height="100%"
        width="100%"
      >
        <button
          className={
            isLoadingFilterTrainings
              ? "filter-button filter-button-disabled"
              : "filter-button"
          }
          onClick={toggleFilterDisplay}
          disabled={isLoadingFilterTrainings}
          ref={expTourRef12}
        >
          <img src="./assets/filter-icon.svg" alt="" />
          {!tourOpen &&
            ((!isFilterCleared && noOfFilters > 0) ||
              (noOfFilters > 0 && retainContext)) && (
              <div
                className="selected-number"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFilterDisplay();
                }}
              >
                {noOfFilters}
              </div>
            )}
        </button>
      </CustomTooltip>
      {(tourFilterVisible || showFilter) && (
        <div className="filter-box-container" ref={expTourRef18}>
          {isLoading ? (
            <Spin />
          ) : (
            <>
              {Object.keys(filterOptions).length > sliceLength.current && (
                <div className="filter-option-nav">
                  <div className="filter-option-left-nav">
                    <div
                      className="cursor-p"
                      onClick={handlePrevSlice}
                      style={
                        currentSlice > 1
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                    >
                      <img src="/assets/leftArr.svg" alt="" />
                    </div>
                  </div>
                  <div className="filter-option-right-nav">
                    <div
                      className="cursor-p"
                      onClick={handleNextSlice}
                      style={
                        currentSlice < totalSlices.current
                          ? { display: "inline-block" }
                          : { display: "none" }
                      }
                    >
                      <img src="/assets/rightArr.svg" alt="" />
                    </div>
                  </div>
                </div>
              )}
              {Object.keys(filterOptions).length !== 0 && (
                <div
                  className={
                    selectionAllObj.current === selectedFiltersVisible
                      ? "filter-all-option-box-selected filter-option-header"
                      : "filter-option-header"
                  }
                  onClick={() => {
                    handleAllFilterOption();
                  }}
                >
                  All Resources
                </div>
              )}
              {currentFilterListVisible.map((filterKey) => (
                <FilterOption
                  key={filterKey}
                  filterKey={filterKey}
                  options={filterOptions[filterKey]}
                  selectedOptions={selectedFiltersVisible[filterKey]}
                  onOptionChange={handleOptionChange}
                />
              ))}
              {Object.keys(filterOptions).length !== 0 && (
                <div className="apply-clear-container">
                  <div
                    className="clear-all-option"
                    onClick={() => {
                      handleClearOption();
                    }}
                  >
                    Clear all
                  </div>
                  <div className="apply-option" onClick={handleApply}>
                    Apply
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
