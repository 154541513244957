import React, { useState } from "react";
import "./ResponsesTable.css";
import { Modal } from "antd";
export default function ResponsesTable({ data, header_options }) {
  const [showModal, setShowModal] = useState(null);
  const handleShowModal = (ques, ans) => {
    let data = {};
    data.question = ques;
    data.answer = ans;
    setShowModal(data);
    return;
  };
  console.log("headers now:", header_options);
  return (
    <div className="responses-table-box">
      <div className="response-details-columm">
        <div className="user-feedback-header">
          {header_options.map((item) => {
            return (
              <span
                className={
                  item.header === "Date"
                    ? "header-options-1 width-10"
                    : item.header === "Email ID"
                    ? "header-options-1 width-20"
                    : item.is_expanded
                    ? "header-options-1 width-30"
                    : "header-options-1 width-15"
                }
              >
                {item.header}
              </span>
            );
          })}
        </div>
        {data.map((item, index) => {
          return (
            <div className="user-feedback-row">
              {header_options.map((item) => {
                return item.is_expanded ? (
                  <div className="modal-item width-30">
                    <span className="response-content-options width-90 ">
                      {data[index][`${item.header}`]?.answer !== undefined
                        ? data[index][`${item.header}`].answer.length < 100
                          ? data[index][`${item.header}`].answer
                          : data[index][`${item.header}`].answer.slice(0, 97) +
                            `...`
                        : "-"}
                    </span>
                    {
                      // item.header !== "User Name" &&
                      //   item.header !== "Email ID" &&
                      data[index][`${item.header}`]?.answer !== undefined &&
                        data[index][`${item.header}`].answer.length > 100 && (
                          <img
                            alt=""
                            src="/assets/Vector.svg"
                            className="cursor-p h-max"
                            onClick={() => {
                              handleShowModal(
                                item.header,
                                data[index][`${item.header}`].answer
                              );
                            }}
                          />
                        )
                    }
                  </div>
                ) : (
                  <span
                    className={
                      item.header === "Email ID"
                        ? "response-content-options-not-exp link-color width-20"
                        : item.header === "Date"
                        ? "response-content-options-not-exp width-10"
                        : "response-content-options-not-exp width-15"
                    }
                  >
                    {data[index][`${item.header}`]?.answer !== undefined
                      ? data[index][`${item.header}`].answer.length < 100
                        ? data[index][`${item.header}`].answer
                        : data[index][`${item.header}`].answer.slice(0, 97) +
                          `...`
                      : "-"}
                  </span>
                );
              })}
            </div>
          );
        })}
      </div>
      <Modal
        open={showModal}
        centered
        title={<span className="title-modal-css">{showModal?.question}</span>}
        onCancel={() => {
          setShowModal(null);
        }}
        closable={false}
        width={400}
        footer={[
          <div className="setting-end">
            <div
              className="edit-question-button"
              onClick={() => {
                setShowModal(null);
              }}
            >
              Ok
            </div>
          </div>,
        ]}
      >
        <div className="text-modal-css">{showModal?.answer}</div>
      </Modal>
    </div>
  );
}
