import React, { useContext, useState } from "react";
import "./BulkUpload.css";
import ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import { message, Upload } from "antd";
import {
  bulk_upload_instructions,
  containsAlphabetsOrNumbers,
  submit_text,
  token,
} from "../../../../constants/GlobalConstants";
import { Base_Url_Admin } from "../../../../Utils/urls";
import { AuthContext } from "../../../../Context/AuthContext";
import { InfoCircleOutlined } from "@ant-design/icons";
import AdminModal from "../AdminModal/AdminModal";

export default function BulkUpload({
  filterOptions,
  setViewSection,
  getRecommendedTrainings,
  page,
}) {
  const { user, getToken } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let filterCols = ["Resource Name", "Duration", "Description", "Link"];
  for (const [key, value] of Object.entries(filterOptions)) {
    if (value.length !== 0) filterCols.push(key);
  }

  const [fileList, setFileList] = useState([]);
  const [trainings, setTrainings] = useState([]);

  const props = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      const { name } = file;
      const fileType = name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2);
      // console.log("file:",file,fileType)

      if (fileType === "csv") {
        // parseCSV(file);
      } else if (fileType === "xlsx") {
        // parseXLSX(file);
      } else {
        message.error(
          "Unsupported file type. Please upload CSV or XLSX files."
        );
      }
      setFileList([file]);
    },
    async onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        let sheetError = [];
        const workbook = await readExcelFile(info.file.originFileObj);
        console.log("worknoook jk:", workbook);
        if (workbook.hasNullValue) {
          message.error(
            `The uploaded file has null entries. Please re-check and upload again!`
          );
          sheetError.push(
            "The uploaded file has null entries. Please re-check and upload again!"
          );
        }
        const data = workbook?.sheetData;
        if (data.length < 2) {
          message.error("No entries are present !!");
          sheetError.push("No entries are present");
        }
        let feilds = data[0];
        filterCols.some((item, index) => {
          if (
            item?.toLowerCase().trim() !== feilds[index]?.toLowerCase().trim()
          ) {
            message.error(
              "There is a mismatch in the template format. Please update the file correctly as per the guidelines and re-upload."
            );
            sheetError.push(
              "There is a mismatch in the template format. Please update the file correctly as per the guidelines and re-upload."
            );
            return true; // Stop the iteration
          }
          return false; // Continue the iteration
        });
        if (sheetError.length === 0) {
          setTrainings(data);
          message.success(`${info.file.name} file successfully uploaded!`);
        } else setFileList([]);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const sheetContent = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: null,
        });
        let durationColumnIndex = -1;

        // Check for the "duration" column in the first row only
        const firstRow = sheetContent[0];
        for (const [columnIndex, cell] of firstRow.entries()) {
          if (cell !== null && cell.toLowerCase() === "duration") {
            durationColumnIndex = columnIndex;
            break;
          }
        }
        const filteredSheetContent = sheetContent.filter((row) =>
          row.some((cell) => cell !== null)
        );

        const hasNullValue = filteredSheetContent.some((row) => {
          for (let index = 0; index < row.length; index++) {
            const cell = row[index];
            if (index !== durationColumnIndex) {
              if (cell === null || !containsAlphabetsOrNumbers(cell)) {
                return true;
              }
            }
          }
        });
        const result = {
          sheetData: filteredSheetContent,
          hasNullValue: hasNullValue,
        };
        resolve(result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Resource data");

    // Add headers
    worksheet.addRow(filterCols);

    // Add data
    let data_temp = [];
    for (let i = 0; i < filterCols.length; i++) {
      data_temp.push(`<${filterCols[i]}>`);
    }
    worksheet.addRow(data_temp);

    const worksheet2 = workbook.addWorksheet("Filters & values");

    let filters = filterCols.slice(4, filterCols.length);
    worksheet2.addRow(filters);
    for (const [key, value] of Object.entries(filterOptions)) {
    }
    for (let k = 0; k < filters.length; k++) {
      let filter_options = filterOptions[filters[k]];
      let col_index = k + 1;
      for (let j = 0; j < filter_options.length; j++) {
        worksheet2.getCell(j + 2, col_index).value = filter_options[j]?.value;
      }
    }
    // Create a Blob from workbook
    const blob = await workbook.xlsx.writeBuffer();

    // Create download link
    const url = URL.createObjectURL(new Blob([blob]));
    const a = document.createElement("a");
    a.href = url;
    a.download = "Resource upload template.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleSubmit = async () => {
    if (trainings.length < 2) return;
    let final = [];
    for (let i = 1; i < trainings.length; i++) {
      let basicTemp = {
        name: trainings[i][0],
        duration: trainings[i][1],
        description: trainings[i][2],
        link: trainings[i][3],
        is_default: false,
        is_active: true,
      };
      let currentIndex = 4;
      for (const [key, value] of Object.entries(filterOptions)) {
        // filterCols.push(key);
        basicTemp[key] = trainings[i][currentIndex];
        currentIndex += 1;
      }

      final.push(basicTemp);
    }
    let accessToken = await getToken();
    let response = await fetch(`${Base_Url_Admin}/store_bulk_trainings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + token,
        "X-Api-Token": accessToken,
        // Authorization: "Bearer " + deloitteChatToken,
      },
      body: JSON.stringify(final),
    });
    let result = await response.json();
    if (response.status === 200) {
      message.success(`${result?.message}`);
      getRecommendedTrainings(page);
      setViewSection(false);
      return;
    }
    setFileList([]);
    setOpenModal({});
    message.error(`${result?.message}`);
    setTrainings([]);
  };
  return (
    <div className="bulk-section">
      <div className="guidelines-box">
        <div className="bulk-upload-info-statement">
          <InfoCircleOutlined className="info-upload-icon" />
          <span>Guidelines:</span>
        </div>
        <div className="ins-content-box">
          {bulk_upload_instructions.map((item) => {
            return <li>{item}</li>;
          })}
        </div>
      </div>
      <div className="template-box">
        <span className="template-content">Resource data upload template</span>
        <img
          src="/assets/Link.svg"
          onClick={handleExport}
          className="download-button"
        />
      </div>
      <div className="upload-file-box-bulk">
        <label className="center-align">
          <img src="/assets/_.svg" />
          &nbsp;Upload Data
        </label>

        <Upload
          {...props}
          fileList={fileList}
          onRemove={() => {
            setFileList([]);
            setTrainings([]);
          }}
        >
          <div className="upload-box">
            <img src="/assets/Inbox2.svg" />
            <span>Click or drag file to this area to upload</span>
            <span className="file-limit-content">
              Support for a single file upload. Strictly prohibited from
              uploading company data or other banned files.
            </span>
          </div>
        </Upload>
      </div>
      <div className="submit-box">
        <button
          type="submit"
          className="submit-button"
          onClick={() => {
            trainings.length < 2 ? (
              <></>
            ) : (
              setOpenModal({
                content: submit_text,
                func: () => handleSubmit(),
              })
            );
          }}
          disabled={trainings.length < 2}
        >
          Submit
        </button>
      </div>
      <AdminModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
}
