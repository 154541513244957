import React, { useEffect, useState } from "react";
import "./UseCaseCard.css";
import Select from "react-select";
import { Line } from "@ant-design/plots";
import {
  cards_description,
  customStyles,
  empty_text,
} from "../../../../constants/GlobalConstants";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
export default function UseCaseCard({ data_show, isEmptyData, setEmptyData }) {
  const optionsFilter = [
    { value: "questions", label: "Conversations" },
    { value: "trainings", label: "Recommended Resources" },
    { value: "assessments", label: "Reflective Assessments" },
  ];
  const [dataGraph, setDataGraph] = useState(
    data_show?.questions.concat(data_show?.trainings, data_show?.assessments)
  );
  const [selectedValue, setSelectedValue] = useState("");
  const handleShowUsage = (e) => {
    if (e) {
      if (e.value === "questions") setDataGraph(data_show?.questions);
      else {
        if (e.value === "trainings") setDataGraph(data_show?.trainings);
        else setDataGraph(data_show?.assessments);
      }
      setSelectedValue(e.value);
    } else {
      setDataGraph(
        data_show?.questions.concat(data_show?.trainings, data_show?.assessments)
      );
      setSelectedValue("");
    }
    return;
  };

  const getStepSize = () => {
    const dataPoints = data_show["questions"].length;
    const maxTicks = 10;
    return Math.ceil(dataPoints / maxTicks);
  };

  const LineChart = ({ chartData }) => {
    const stepSize = getStepSize();
    const config = {
      data: chartData,
      xField: "month",
      yField: "value",
      seriesField: "category",
      autofit: true,
      // scale={{ value: { min: 1 } }} ,
      legend: {
        position: "top",
      },
      smooth: true,

      xAxis: {
        // type: "time",
        range: [0, 1],
        title: {
          text: "Period", // Set the x-axis label to "Month"
        },
        // tickCount: 10,
        label: {
          autoRotate: false,
          autoHide: false,
          formatter: (text, item, index) => {
            // Control when to display the ticks based on index or condition
            if (index % stepSize !== 0) {
              return "";
            }
            return text;
          },
        },
      },
      // tooltip: {
      //   customContent: (title, items) => {
      //     // Customize the tooltip content here
      //     const titleStyle =
      //       "font-size: 16px; color: black; font-weight: 400;display: flex; font-family: Open Sans;padding:1rem;";
      //     const itemStyle = "font-size: 16px; color: black; font-weight: 400;";
      //     const valueStyle =
      //       "display:flex;gap:1.5rem;padding:0.5rem;font-family: Open Sans;";
      //     return (
      //       `<div style="${titleStyle}">${title}</div>` +
      //       items
      //         .map(
      //           (item) =>
      //             `<div style="${valueStyle}">${item.name}:<span style="${itemStyle}"> ${item.value}</span></div>`
      //         )
      //         .join("")
      //     );
      //   },
      //   style: {
      //     fontSize: 16, // Set the font size for the entire tooltip
      //   },
      // },
      yAxis: {
        // type: "time",
        title: {
          text: "Count", // Set the x-axis label to "Month"
        },
      },
      color:
        selectedValue?.length === 0
          ? ["#6C60FF", "#CE2A96", "#0071B0"]
          : selectedValue === "questions"
          ? ["#6C60FF"]
          : selectedValue === "trainings"
          ? ["#CE2A96"]
          : ["#0071B0"],
      lineStyle: {
        lineWidth: 2,
        strokeWidth: 2,
        cursor: "pointer",
      },
    };
    return <Line {...config} className="line-graph-sizing" />;
  };

  useEffect(() => {
    let isEmpty = true;
    for (let i = 0; i < dataGraph.length; i++) {
      if (dataGraph[i].value !== 0) {
        isEmpty = false;
        break;
      }
    }
    setEmptyData(isEmpty);
  }, [dataGraph]);

  return (
    <>
      <div className="filter-usage-header">
        <div className="card-header-box">
          <span className="filter-usage-heading">Use Case Utilization</span>
          <Tooltip
            placement="bottomLeft"
            title={
              <div className="card-tooltip-container">
                {/* <span className="card-container-heading">
                  Use Case Utilization:
                </span> */}
                {cards_description.use_case}
              </div>
            }
            color="#FFFFFF"
          >
            <img
              alt=""
              src="/assets/new-info.svg"
              className="card-info-icon-sizing"
            />{" "}
          </Tooltip>
        </div>
        <Select
          options={optionsFilter}
          // defaultValue={optionsFilter[0]}
          name="filter"
          onChange={handleShowUsage}
          className="select-filter-box-use-case"
          isClearable
          styles={customStyles}
        />
      </div>
      {isEmptyData ? (
        <div className="empty-data-img-box mt--2">
          <img
            src="/assets/use-case-usage.svg"
            className="trending-empty-image"
            alt=""
          />
          <span className="empty-text">{empty_text}</span>
        </div>
      ) : (
        <div className="line-graph-box">
          <LineChart chartData={dataGraph} />
        </div>
      )}
    </>
  );
}
