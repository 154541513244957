import { Modal } from "antd";
import React from "react";

export default function AdminModal({
  openModal,
  setOpenModal,
  isLoading,
  setIsLoading,
}) {
  return (
    <Modal
      open={Object.keys(openModal).length !== 0}
      title={
        <div className="delete-title-box">
          <img src="/assets/ExclamationCircle.svg" />
          <span className="title-content">{openModal?.content}</span>
        </div>
      }
      width="26rem"
      centered
      footer={[
        <div className="delete-modal">
          <div
            className="no-box"
            onClick={() => {
              setOpenModal({});
              setIsLoading(false);
            }}
          >
            No
          </div>
          <div className="yes-box" onClick={openModal?.func}>
            {/* {isLoading ? "Loading..." : "Yes"} */}
            Yes
          </div>
        </div>,
      ]}
      onCancel={() => {
        setOpenModal({});
        setIsLoading(false);
      }}
    ></Modal>
  );
}
