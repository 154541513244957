import React, { useContext, useEffect } from "react";
import "./Sidebar.css";
import { NavLink, useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import { AuthContext } from "../../../../Context/AuthContext";
import { ProfileOutlined } from "@ant-design/icons";

export default function Sidebar({ isSuperUser }) {
  const location = useLocation();
  let currentUrl = location.pathname.substring(1);
  const {
    setViewSection,
    setShowContent,
    setEditContent,
    setShowTraining,
    setEditViewToggle,
    setSelectedUser,
  } = useContext(AuthContext);
  return (
    <div className="sidebar">
      <img src="/assets/deloitte.svg" className="deloitte-logo" />
      <div className="sidebar-icons">
        <div
          className={
            currentUrl === "admin/dashboard"
              ? "icon-selected"
              : "icon-not-selected"
          }
        >
          <NavLink to="/admin/dashboard">
            <Tooltip title="Dashboard" placement="right">
              <img src="/assets/Dasboard.svg" className="pos-sidebar-icon" />
            </Tooltip>
          </NavLink>
        </div>
        <div
          className={
            currentUrl === "admin/question-gen-ai"
              ? "icon-selected"
              : "icon-not-selected"
          }
        >
          <NavLink to="/admin/question-gen-ai">
            <Tooltip title="Conversations" placement="right">
              <img
                src="/assets/questions.svg"
                className="pos-sidebar-icon"
                onClick={() => {
                  setViewSection(false);
                  setEditContent(false);
                  setShowContent("");
                }}
              />
            </Tooltip>
          </NavLink>
        </div>
        <div
          className={
            currentUrl === "admin/recommended-resources"
              ? "icon-selected"
              : "icon-not-selected"
          }
        >
          <NavLink to="/admin/recommended-resources">
            <Tooltip title="Recommended Resources" placement="right">
              <img
                src="/assets/tranings.svg"
                className="pos-sidebar-icon"
                onClick={() => {
                  setViewSection(false);
                  setEditContent(false);
                  setShowTraining(null);
                }}
              />
            </Tooltip>
          </NavLink>
        </div>
        <div
          className={
            currentUrl === "admin/self-assessment"
              ? "icon-selected"
              : "icon-not-selected"
          }
        >
          <NavLink to="/admin/self-assessment">
            <Tooltip title="Reflective Questions" placement="right">
              <img
                src="/assets/self-ass-icon.svg"
                className="pos-sidebar-icon w-2-6"
              />
            </Tooltip>
          </NavLink>
        </div>
        <div
          className={
            currentUrl === "admin/feedback"
              ? "icon-selected"
              : "icon-not-selected"
          }
        >
          <NavLink to="/admin/feedback">
            <Tooltip title="Feedback" placement="right">
              <img src="/assets/feedback.svg" className="pos-sidebar-icon" />
            </Tooltip>
          </NavLink>
        </div>
        {isSuperUser && (
          <div
            className={
              currentUrl === "admin/config-admins"
                ? "icon-selected"
                : "icon-not-selected"
            }
          >
            <NavLink to="/admin/config-admins">
              <Tooltip title="Access Management" placement="right">
                <img
                  src="/assets/config-admin.svg"
                  className="pos-sidebar-icon w-2-6"
                  onClick={() => {
                    setEditViewToggle(0);
                    setSelectedUser(null);
                  }}
                />
              </Tooltip>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}
