import React from "react";
import "./EmptyScreen.css";

export default function EmptyScreen({ setShowUploadSection, SubmitTitle }) {
  return (
    <div className="empty">
      <img src="/assets/Attachassignment.svg" />
      <span className="content-empty">
        Initiate the creation of your instructional course materials, commencing
        with the development of your curriculum content
      </span>
      <div
        className="create-button-empty-page"
        onClick={() => {
          setShowUploadSection(true);
        }}
      >
        {SubmitTitle}
      </div>
    </div>
  );
}
