import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { AppStateContext } from "../Context/AppStateContext";
import { Base_Url } from "../Utils/urls";
import { FilterContext } from "../Context/FilterContext";

export function useAppUtilities() {
  const { getToken } = useContext(AuthContext);
  const { setInputValue } = useContext(AppStateContext);
  const { filterIdMapping } = useContext(FilterContext);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const user_interaction = async (
    id = "",
    filterPayload = {},
    sessionPayload = {},
    usecaseData = {},
    firstTimeUser = false
  ) => {
    try {
      let filterArr = [];
      for (const [key, value] of Object.entries(filterPayload)) {
        filterArr.push({
          filter_id: filterIdMapping[key]?.id,
          option_ids: filterPayload[key].map((item) => item.id),
        });
      }
      const interaction_payload = {
        interaction_data: filterArr,
        training_id: id,
        session_data: sessionPayload,
        usecase_data: usecaseData,
        is_tour_enabled_smallview: firstTimeUser,
      };
      let accessToken = localStorage.getItem("accessToken") ?? getToken();
      let response = await fetch(`${Base_Url}/user_interaction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify(interaction_payload),
        keepalive: true,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      localStorage.setItem("isFirstTourUser", true);
    } catch (e) {
      console.log("Error:", e);
    }
  };

  return {
    handleInputChange,
    user_interaction,
  };
}
