import React, { useEffect, useState } from "react";
import "./FilterUsageCard.css";
import { Pie } from "@ant-design/plots";
import Select from "react-select";
import {
  cards_description,
  customStyles,
  empty_text,
  filter_graph_color_code,
} from "../../../../constants/GlobalConstants";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
let color_code = 0;
export default function FilterUsageCard({
  filterOptions,
  filtersItemList,
  isEmptyData,
  setEmptyData,
}) {
  const [showFilter, setShowFilter] = useState(filterOptions[0]);
  const generatePinkShades = (numShades) => {
    // Generate shades of pink dynamically
    const shades = [];
    for (let i = 0; i < numShades; i++) {
      const shade = `#${(255 - i * 10).toString(16)}${(192 - i * 10).toString(
        16
      )}${(224 - i * 5).toString(16)}`;

      shades.push(shade);
    }
    console.log("genrating shades:", shades);
    return shades;
  };
  // const generateCustomShades = (baseColor, numShades) => {
  //   const shades = [];

  //   // Convert the base color to RGB components
  //   const baseRGB = baseColor.match(/\w\w/g).map(component => parseInt(component, 16));

  //   for (let i = 0; i < numShades; i++) {
  //     // Calculate shades based on the base color
  //     const newShade = baseRGB.map(component => Math.max(0, component - i * 10).toString(16).padStart(2, '0'));

  //     const shade = `#${newShade.join('')}`;

  //     shades.push(shade);
  //   }

  //   return shades;
  // };

  const generateCustomShades = (
    baseColor,
    numShades,
    darken = true,
    step = 10
  ) => {
    const shades = [];

    // Convert the base color to HSL components
    const baseHSL = rgbToHsl(baseColor);

    for (let i = 0; i < numShades; i++) {
      // Adjust the lightness based on whether darken is true or false
      const newLightness = darken
        ? baseHSL[2] - i * step
        : baseHSL[2] + i * step;

      // Ensure lightness stays within valid range [0, 100]
      const adjustedLightness = Math.max(0, Math.min(100, newLightness));

      // Convert back to RGB
      const newShade = hslToRgb(baseHSL[0], baseHSL[1], adjustedLightness);

      const shade = `#${newShade
        .map((component) => component.toString(16).padStart(2, "0"))
        .join("")}`;

      shades.push(shade);
    }

    return shades;
  };

  // Convert RGB to HSL
  const rgbToHsl = (color) => {
    const [r, g, b] = color
      .match(/\w\w/g)
      .map((component) => parseInt(component, 16) / 255);

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          break;
      }

      h /= 6;
    }

    return [h * 360, s * 100, l * 100];
  };

  // Convert HSL to RGB
  const hslToRgb = (h, s, l) => {
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  // Example usage for generating darkened shades of #E4E398
  // const darkenedShades = generateCustomShades("#E4E398", 5, true, 10);
  // console.log(darkenedShades);

  // // Example usage for generating lightened shades of #E4E398
  // const lightenedShades = generateCustomShades("#E4E398", 5, false, 10);
  // console.log(lightenedShades);

  const PieChart = ({ chartData }) => {
    const config = {
      appendPadding: 10,
      data: chartData,
      angleField: "value",
      colorField: "type",
      radius: 1,
      // theme: {
      //   colors10: generateCustomShades(
      //     filter_graph_color_code[color_code].code,
      //     chartData.length,
      //     true,
      //     filter_graph_color_code[color_code].step
      //   ),
      //   colors20: generateCustomShades(
      //     filter_graph_color_code[color_code].code,
      //     chartData.length,
      //     true,
      //     filter_graph_color_code[color_code].step
      //   ),
      // },
      autofit: true,
      label: false,
      isZero: true,
      // legend: true,
      // legend: {
      //   position: "right", // Change this to 'left', 'bottom', or 'right' as needed
      // },

      interactions: [{ type: "element-selected" }, { type: "element-active" }],
    };
    return <Pie {...config} />;
  };

  const handleFilter = (e) => {
    const indexPos = filterOptions.findIndex((obj) => obj.value === e.value);
    color_code = indexPos;
    setShowFilter(e);
    return;
  };

  const CustomLegend = ({ data }) => {
    let shades = generatePinkShades(data.length);
    return (
      <div className="legend-box">
        {data.map((item, index) => (
          <div
            key={item.type}
            style={{
              marginBottom: 8,
            }}
            className="option-detail-box"
          >
            <div className="icon-option-name-box">
              <div
                style={{
                  backgroundColor: shades[index],
                }}
                className="color-icon"
              ></div>
              <div className="option-name">{item.type}</div>
            </div>
            <span className="option-value-box">{item.value}</span>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    let data = filtersItemList[showFilter?.value];
    let isEmpty = true;
    for (let i = 0; i < data.length; i++) {
      if (data[i].value !== 0) {
        isEmpty = false;
        break;
      }
    }
    setEmptyData(isEmpty);
  }, [showFilter]);

  return (
    <>
      <div className="filter-usage-header">
        <div className="card-header-box">
          <span className="filter-usage-heading">Filter Usage Frequency</span>
          <Tooltip
            placement="bottomLeft"
            title={
              <div className="card-tooltip-container">
                {/* <span className="card-container-heading">
                  Filter Usage Frequency:&nbsp;
                </span> */}
                {cards_description.filter_usage}
              </div>
            }
            color="#FFFFFF"
          >
            <img
              alt=""
              src="/assets/new-info.svg"
              className="card-info-icon-sizing"
            />
          </Tooltip>
        </div>
        <Select
          options={filterOptions}
          defaultValue={filterOptions[0]}
          name="filter"
          onChange={handleFilter}
          className="select-filter-box"
          styles={customStyles}
        />
      </div>
      <div className="filter-graph-box">
        {isEmptyData ? (
          <div className="empty-data-img-box">
            <img
              src="/assets/filter-usage.svg"
              className="trending-empty-image"
              alt=""
            />
            <span className="empty-text">{empty_text}</span>
          </div>
        ) : (
          <div className="pie-chart">
            <div className="filter-graph-filter-heading">
              {showFilter?.label}
            </div>
            <div className="pie-chart-graph-box">
              <PieChart chartData={filtersItemList[showFilter?.value]} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
