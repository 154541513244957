import React, { useState } from "react";
import "./FeedbackQuestion.css";
import {
  containsAlphabetsOrNumbers,
  ratingShades,
} from "../../../../constants/GlobalConstants";
export default function FeedbackQuestion({
  question,
  question_no,
  submitFeedback,
  questions,
  setQuestions,
}) {
  const [isHover, setHover] = useState(0);
  const [textInput, setTextInput] = useState("");
  const handletextInput = (e, id) => {
    let new_val = questions;
    let char_length = e.target.value.length;
    if (!question.is_long_answer) {
      if (char_length > 250) return;
    }
    if (e.target.value === "" || containsAlphabetsOrNumbers(e.target.value)) {
      new_val[question_no - 1].answer = e.target.value;
    }
    setTextInput(e.target.value);
    setQuestions(new_val);
    return;
  };
  const handleRating = (value, id) => {
    let new_value = questions;
    if (questions[question_no - 1]?.answer === value)
      new_value[question_no - 1].answer = null;
    else new_value[question_no - 1].answer = value;
    setQuestions(new_value);
    return;
  };
  return (
    <div className="feedback-question-box">
      <div className="question-no-box">
        <span className="center-align">
          {question?.is_required && <img src="./assets/_.svg" alt="" />}
          &nbsp;Question {question_no}
        </span>
        <span className="r-color">
          {" "}
          {!question?.is_rating &&
            !question?.is_long_answer &&
            "(Max 250 char)"}
        </span>
      </div>
      <div className="question-box">
        <span>{question?.question}</span>
        {question?.is_rating && (
          <span className="question-box-info">
            {"("}1 being most challenging and 10 being easiest{")"}
          </span>
        )}
      </div>
      {!question?.is_rating ? (
        <textarea
          className="comments-box"
          disabled={submitFeedback}
          value={textInput}
          onChange={(e) => handletextInput(e, question.question_id)}
        />
      ) : (
        <div className="rating-box-container">
          <div className="rating-box">
            {ratingShades
              .slice(0, ratingShades.length / 2)
              .map((item, index) => {
                return (
                  <div
                    className={
                      submitFeedback
                        ? "rating-no-box cursor-n"
                        : "rating-no-box"
                    }
                    style={
                      questions[question_no - 1]?.answer === index + 1
                        ? {
                            backgroundColor: item,
                            border: "3px solid #808080e0",
                          }
                        : isHover === index + 1
                        ? {
                            backgroundColor: item,
                            border: "2px solid #808080e0",
                          }
                        : { border: `2px solid  ${item}` }
                    }
                    onMouseOver={() =>
                      submitFeedback ? <></> : setHover(index + 1)
                    }
                    onMouseLeave={() => (submitFeedback ? <></> : setHover(0))}
                    onClick={() => {
                      submitFeedback ? (
                        <></>
                      ) : (
                        handleRating(index + 1, question?.question_id)
                      );
                    }}
                  >
                    <span className="rating-index">{index + 1}</span>
                  </div>
                );
              })}
          </div>
          <div className="rating-box">
            {ratingShades
              .slice(ratingShades.length / 2, ratingShades.length)
              .map((item, index) => {
                return (
                  <div
                    className={
                      submitFeedback
                        ? "rating-no-box cursor-n"
                        : "rating-no-box"
                    }
                    style={
                      questions[question_no - 1]?.answer ===
                      ratingShades.length / 2 + index + 1
                        ? {
                            backgroundColor: item,
                            border: "3px solid #808080e0",
                          }
                        : isHover === ratingShades.length / 2 + index + 1
                        ? {
                            backgroundColor: item,
                            border: "2px solid #808080e0",
                          }
                        : { border: `2px solid  ${item}` }
                    }
                    onMouseOver={() =>
                      submitFeedback ? (
                        <></>
                      ) : (
                        setHover(ratingShades.length / 2 + index + 1)
                      )
                    }
                    onMouseLeave={() => (submitFeedback ? <></> : setHover(0))}
                    onClick={() => {
                      submitFeedback ? (
                        <></>
                      ) : (
                        handleRating(
                          ratingShades.length / 2 + index + 1,
                          question?.question_id
                        )
                      );
                    }}
                  >
                    <span className="rating-index">
                      {ratingShades.length / 2 + index + 1}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
