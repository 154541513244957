import React, { useContext, useEffect, useState } from "react";
import "./Main.css";
import { Outlet } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { AuthContext } from "../../../../Context/AuthContext";
import { Base_Url_Admin } from "../../../../Utils/urls";
import { Button, Result, Spin } from "antd";

export default function Main() {
  const { authenticated, user, getToken, handleLoginPopup, handleLogout } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperUser, setSuperUser] = useState(false);
  useEffect(() => {
    localStorage.setItem("interactionMethod", "POPUP");
    handleLoginPopup();
  }, []);

  const checkIfAdmin = async () => {
    try {
      let accessToken = await getToken();
      setLoading(true);
      let response = await fetch(`${Base_Url_Admin}/health`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      });
      let result = await response.json();
      if (!response.ok) {
        console.log("Not admin");
        setIsAdmin(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      console.log("Is admin");
      setIsAdmin(true);
      setSuperUser(result?.is_superuser);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authenticated)
      checkIfAdmin();
  }, [authenticated]);

  return (
    authenticated &&
    <div className="layout-wrapper">
      <Sidebar isSuperUser={isSuperUser} />
      <div className="admin-page">
        <Navbar />
        {isAdmin ? <Outlet />
          : loading ? <Spin className="user-check-loader" />
            : <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={<Button type="primary" onClick={handleLogout}>Login with other account</Button>}
            />}
      </div>
    </div>
  );
}
