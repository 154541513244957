import React, { useContext, useEffect, useRef, useState } from "react";
import "./QuestionsGenAI.css";
import MessageBox from "../MessageBox/MessageBox";
import { Spin } from "antd";
import { default_content_dummy } from "../../../../constants/GlobalConstants";
import { TourContext } from "../../../../Context/TourContext";
import { ConversationContext } from "../../../../Context/ConversationContext";
import { AppStateContext } from "../../../../Context/AppStateContext";
import { useConversations } from "../../../../Hooks/useConversations";

export default function QuestionsGenAI() {
  const { tourOpen, smallTourRef4 } = useContext(TourContext);

  const { toggleTab } = useContext(AppStateContext);

  const {
    disabledQuestion,
    scrollActive,
    setScrollActive,
    defaultContent,
    isLoadingDefaultContent,
    responses,
    isLoadingChat,
    shouldAutoScrollRef,
  } = useContext(ConversationContext);

  const { handleSubmit } = useConversations();

  const scrollingDivRef = useRef(null);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  useEffect(() => {
    if (!tourOpen && (scrollActive || toggleTab)) {
      scrollToBottom();
    }
  }, [scrollActive, toggleTab, tourOpen]);

  useEffect(() => {
    if (
      !scrollActive &&
      shouldAutoScrollRef.current &&
      scrollingDivRef.current
    ) {
      scrollingDivRef.current.scrollTop =
        scrollingDivRef.current.scrollHeight -
        scrollingDivRef.current.clientHeight;
    }
  }, [responses]);

  useEffect(() => {
    if (!shouldAutoScrollRef.current) shouldAutoScrollRef.current = true;
    return () => {
      if (!disabledQuestion) setScrollActive(true);
    };
  }, []);

  const scrollToBottom = () => {
    const divElement = scrollingDivRef.current;
    if (divElement) {
      const scrollHeight = divElement.scrollHeight;
      const clientHeight = divElement.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      divElement.scrollTo({
        top: maxScrollTop,
        behavior: "smooth",
      });
      setScrollActive(false);
    }
  };

  const handleScroll = () => {
    if (scrollingDivRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = scrollingDivRef.current;
      shouldAutoScrollRef.current =
        scrollHeight - clientHeight <= scrollTop + 22;
    }
  };

  const changeQuestion = (item, e, defaultQuestion = false, index) => {
    if (disabledQuestion) {
      return;
    }
    setSelectedQuestion(index);
    handleSubmit(e, item, defaultQuestion);
  };

  let isLoading = !tourOpen && (isLoadingChat || isLoadingDefaultContent);
  let defaultContentVisible = tourOpen
    ? default_content_dummy
    : defaultContent ?? {};
  let responsesVisible = tourOpen ? [] : responses;

  return isLoading ? (
    <div className="loading-chat">
      <Spin />
    </div>
  ) : (
    <div
      className="questions-gen-ai-container"
      ref={scrollingDivRef}
      onScroll={handleScroll}
    >
      {defaultContentVisible.hasOwnProperty("message") && (
        <div className="generative-ai-questions">
          <MessageBox
            mObj={{ user: "chatbot", message: defaultContentVisible?.message }}
            style={{ marginTop: "0" }}
          />
          <div className="default-questions-container" ref={smallTourRef4}>
            {defaultContentVisible?.questions?.map((item, index) => {
              return (
                <div
                  className={`default-question ${
                    selectedQuestion === index + 1
                      ? "default-question-selected"
                      : ""
                  } ${disabledQuestion ? "cursor-n" : ""}`}
                  onClick={(e) => {
                    changeQuestion(item, e, false, index + 1);
                  }}
                  key={index}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {responsesVisible.map((mObj) => (
        <div
          style={mObj.user === "user" ? { justifyContent: "end" } : {}}
          className="aligning-message"
        >
          <MessageBox mObj={mObj} disabledQuestion={disabledQuestion} />
        </div>
      ))}
    </div>
  );
}
