import React, { useContext, useEffect, useState } from "react";
import "./RecTrainings.css";
import Trainings from "../QuesGenAI/Trainings/Trainings";
import UploadContent from "../../../Components/UploadTraining/UploadTraining";
import { Modal, Spin, message } from "antd";
import UploadTraining from "../../../Components/UploadTraining/UploadTraining";
import Configuration from "../Configuration/Configuration";
import { token } from "../../../../../constants/GlobalConstants";
import { Base_Url, Base_Url_Admin } from "../../../../../Utils/urls";
import EmptyScreen from "../../../Components/EmptyScreen/EmptyScreen";
import { AuthContext } from "../../../../../Context/AuthContext";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";
let filterAllOptions = {};
let typeKey = "";
export default function RecTrainings() {
  const {
    user,
    getToken,
    isViewSection,
    setViewSection,
    showTraining,
    setShowTraining,
    isEditContent,
    setEditContent,
  } = useContext(AuthContext);
  const [isDataManagement, setDataManagement] = useState(true);
  // const [isViewSection, setViewSection] = useState(false);
  // const [showTraining, setShowTraining] = useState(null);
  // const [isEditTraining, setEditTraining] = useState(false);
  const [trainings, setTrainings] = useState({});
  const [deleteTaining, setDeleteTraining] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterSubOptions, setFilterSubOptions] = useState([]);
  const getAllFilters = async () => {
    try {
      typeKey = "";
      filterAllOptions = {};
      let accessToken = await getToken();
      let filters = await fetch(`${Base_Url}/get_all_filters`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
      }).then((response) => response.json());
      let data = filters?.filters;
      filters?.data.map((item) => {
        if (item.settings?.isTrainingType) {
          typeKey = item.name;
          return;
        }
      });
      let filterTitles = [];
      for (const [key, value] of Object.entries(data)) {
        let z = data[key].map((item) => ({
          value: item?.value,
          label: item?.value,
          id: item?.id,
        }));
        filterTitles.push(key);
        filterAllOptions[key] = z;
      }
      setFilterSubOptions(filterAllOptions);
      setFilterOptions(filterTitles);
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const getRecommendedTrainings = async (value) => {
    try {
      if (loading) return;
      else setLoading(true);
      if (trainings?.data) setTrainings({});
      let payload = {
        action: "all",
      };
      const params = new URLSearchParams();
      params.append("page", value ?? page);
      params.append("admin", true);
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url}/list_trainings?${params}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
        body: JSON.stringify(payload),
      });
      let trainings_result = await response.json();
      setTrainings(trainings_result);
      setLoading(false);
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const handleDeleteTraining = async () => {
    try {
      let payload = {
        training_id: deleteTaining,
      };
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/trainings`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
        body: JSON.stringify(payload),
      });
      messageApi.open({
        type: "success",
        content: "Resource successfully deleted!",
      });
      setDeleteTraining("");
      if (page !== 1) setPage(1);
      getRecommendedTrainings(1);
      setViewSection(false);
    } catch (e) {
      console.log("Error:", e);
    }
  };
  useEffect(() => {
    if (isDataManagement) {
      getAllFilters();
      // getRecommendedTrainings(1);
    }
  }, [isDataManagement]);
  useEffect(() => {
    if (isDataManagement) {
      getRecommendedTrainings();
    }
  }, [page, isDataManagement]);
  // if (typeKey.length === 0) return <Spin className="dashboard-loader" />;
  return (
    <div className="sub-page">
      {contextHolder}
      <div className="heading-tab-pad">
        {isViewSection ? (
          <div className="heading-tab">
            <span
              className="sub-heading"
              onClick={() => {
                // setDataManagement(true);
                setViewSection(false);
                // setPage(1);
                setEditContent(false);
                setShowTraining(null);
              }}
            >
              Recommended Resources&nbsp;
            </span>
            <span className="subbed-heading">
              &nbsp;
              {" > "}&nbsp;
              {showTraining
                ? isEditContent
                  ? "Edit Resources"
                  : "View Resources"
                : "Add Resources"}
            </span>
          </div>
        ) : (
          <span className="content-heading-main">Recommended Resources</span>
        )}
      </div>
      {isViewSection ? (
        <></>
      ) : (
        <>
          <span className="sub-content">
            Manage the learning resources and configure the filters available to
            PPMDs through this section.
          </span>
          <div className="navigation-tab">
            <span
              className={isDataManagement ? "data-selected-tab" : "data-tab"}
              onClick={() => {
                setDataManagement(true);
              }}
            >
              Data management
            </span>
            <span
              className={
                isDataManagement ? "config-tab" : "config-selected-tab"
              }
              onClick={() => {
                setDataManagement(false);
              }}
            >
              Configuration
            </span>
          </div>
        </>
      )}

      {isDataManagement ? (
        isViewSection ? (
          <UploadTraining
            showTraining={showTraining}
            isEditTraining={isEditContent}
            setEditTraining={setEditContent}
            isViewSection={isViewSection}
            setViewSection={setViewSection}
            setDeleteTraining={setDeleteTraining}
            // setPage={setPage}
            getRecommendedTrainings={getRecommendedTrainings}
            setShowTraining={setShowTraining}
            filterOptions={filterSubOptions}
            page={page}
          />
        ) : trainings?.data ? (
          <Trainings
            setShowUploadSection={setViewSection}
            SubmitTitle="Add Resource"
            heading="Resource List"
            trainings={trainings}
            page={page}
            setPage={setPage}
            setEditTraining={setEditContent}
            setShowTraining={setShowTraining}
            setDeleteTraining={setDeleteTraining}
            Type={typeKey}
          />
        ) : trainings?.message ? (
          <EmptyScreen
            setShowUploadSection={setViewSection}
            SubmitTitle="Add Resource"
          />
        ) : (
          <Spin />
        )
      ) : (
        <Configuration />
      )}
      {/* {!isDataManagement && <Configuration/> } */}
      <DeleteModal
        deleteTaining={deleteTaining}
        setDeleteTraining={setDeleteTraining}
        content="Deleting this resource will also remove its mapping from
        Reflective Assessment based resources. Are you sure you want to
        delete it?"
        func={handleDeleteTraining}
      />
    </div>
  );
}
