import React, { useEffect, useState } from "react";
import "./Checkbox.css";

const Checkbox = ({ label, checked, onChange, heading = false }) => {
  return (
    <div
      className={heading ? "custom-checkbox heading-width" : "custom-checkbox"}
    >
      <input type="checkbox" id={label} checked={checked} onChange={onChange} />
      <label
        htmlFor={label}
        className={
          label === "Default"
            ? "heading-width"
            : label === "Recently Added"
            ? "heading-width"
            : ""
        }
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
