import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { ConversationContext } from "../Context/ConversationContext";
import { AppStateContext } from "../Context/AppStateContext";
import { AuthContext } from "../Context/AuthContext";
import { Base_Url } from "../Utils/urls";

let controller;
let fileContentBuffer;
export function useConversations() {
  const { getToken } = useContext(AuthContext);

  const { toggleTab, setToggleTab, inputValue, setInputValue } =
    useContext(AppStateContext);

  const {
    parentId,
    quesContext,
    conversationId,
    currentMessageId,
    disabledQuestionRef,
    disabledQuestion,
    setDisabledQuestion,
    setScrollActive,
    prevQuestion,
    setDefaultContent,
    setIsLoadingDefaultContent,
    responses,
    setResponses,
    setIsLoadingChat,
  } = useContext(ConversationContext);

  const handleSubmit = async (e, item, defaultQuestion = false) => {
    e.preventDefault();
    controller = new AbortController();
    const signal = controller.signal;
    if (disabledQuestion) return;
    setScrollActive(true);
    if (toggleTab !== 1) {
      setToggleTab(1);
    }

    const responsesNew = defaultQuestion
      ? responses
      : [...responses, { user: "user", message: `${item ?? inputValue}` }];
    if (inputValue.trim() !== "" || item?.length) {
      if (!defaultQuestion)
        setResponses([
          ...responses,
          { user: "user", message: `${item ?? inputValue}` },
        ]);
      if (!item?.length) {
        prevQuestion.current = inputValue;
        setInputValue("");
      } else {
        prevQuestion.current = item;
      }
    }
    // show loading until response is coming from api
    setResponses([
      ...responsesNew,
      { user: "chatbot", message: "Generating response ..." },
    ]);
    let timer = setTimeout(() => {
      setResponses([
        ...responsesNew,
        {
          user: "chatbot",
          message: "Almost there ...",
        },
      ]);
    }, 10000);
    setDisabledQuestion(true);
    disabledQuestionRef.current = true;
    fileContentBuffer = "";

    try {
      currentMessageId.current = uuidv4();
      let payload = {
        action: quesContext.current ? "next" : "new",
        message: {
          id: currentMessageId.current,
          author: {
            role: "user",
          },
          content: {
            content_type: "string",
            parts: item ?? inputValue,
          },
        },
        parent_message_id: parentId.current ?? "",
      };

      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url}/azure_chat_openai/${conversationId.current}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify(payload),
          signal: signal,
        }
      );

      clearTimeout(timer);

      if (!response.ok) {
        setResponses([
          ...responsesNew,
          {
            user: "chatbot",
            message: "Network error! Please refresh the page and try again.",
          },
        ]);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Use Response.body to get the ReadableStream
      const reader = response.body.getReader();

      // Process data as it arrives
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          console.log("Final Response:", fileContentBuffer);
          setResponses([
            ...responsesNew,
            {
              user: "chatbot",
              message: fileContentBuffer,
            },
          ]);
          setDisabledQuestion(false);
          disabledQuestionRef.current = false;
          if (!quesContext.current) quesContext.current = true;
          parentId.current = currentMessageId.current;
          localStorage.setItem("ParentId", currentMessageId.current);
          break; // Exit the loop if the stream is finished
        }

        // Process the data (value) as it arrives
        let newData = new TextDecoder("utf-8").decode(value);

        let parsedDataChunk;
        try {
          newData
            .trim()
            .split("\n\n")
            .map((chunk) => {
              parsedDataChunk = JSON.parse(chunk);
              fileContentBuffer = fileContentBuffer + parsedDataChunk?.message;
            });
        } catch (e) {
          // pass
          console.log("Type: ", typeof newData);
          console.log("Err: ", e);
          console.log("New Data:+", newData, "+");
        }
        setResponses([
          ...responsesNew,
          {
            user: "chatbot",
            message: fileContentBuffer,
          },
        ]);
      }
    } catch (error) {
      clearTimeout(timer);
      console.log(error);
      if (error.name === "AbortError") {
        setDisabledQuestion(false);
        disabledQuestionRef.current = false;
        if (fileContentBuffer.length === 0) {
          setResponses([
            ...responsesNew,
            {
              user: "chatbot",
              message: "Response generation has been stopped!",
            },
          ]);
        }
      } else {
        setResponses([
          ...responsesNew,
          {
            user: "chatbot",
            message: "Network error! Please refresh the page and try again.",
          },
        ]);
      }
    }
  };

  const handleStopGenerate = async (e) => {
    if (e) e.preventDefault();
    controller.abort();
    try {
      let payload = {
        conversation_id: conversationId.current,
        message_id: currentMessageId.current,
        response_generated: fileContentBuffer,
        action: quesContext.current ? "next" : "new",
        question: prevQuestion.current,
        parent_message_id: parentId.current ?? "",
      };
      if (!quesContext.current) quesContext.current = true;
      parentId.current = currentMessageId.current;
      localStorage.setItem("ParentId", currentMessageId.current);
      let accessToken = localStorage.getItem("accessToken") ?? getToken();
      let response = await fetch(`${Base_Url}/abort_response`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify(payload),
        keepalive: true,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      console.log("error:", err);
    }
  };

  const getDefaultContent = async () => {
    try {
      setIsLoadingDefaultContent(true);
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url}/ppmd_start_questions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      response = await response.json();
      setDefaultContent(response);
      setIsLoadingDefaultContent(false);
    } catch (err) {
      console.log("error:", err);
      setResponses([
        {
          user: "chatbot",
          message:
            "I'm currently unable to load the information. Please connect to the Deloitte VPN try again.",
        },
      ]);
      setIsLoadingDefaultContent(false);
      setIsLoadingChat(false);
    }
  };

  const getConversation = async () => {
    try {
      if (!parentId.current) return;
      setIsLoadingChat(true);
      setScrollActive(false);
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url}/conversation/${conversationId.current}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
        }
      );
      if (!response.ok) {
        setResponses([
          {
            user: "chatbot",
            message: "Network error! Please refresh the page and try again.",
          },
        ]);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      response = await response.json();
      let jsonObj = response?.data?.mapping;
      const chatHistory = [];
      for (const key in jsonObj) {
        if (jsonObj.hasOwnProperty(key)) {
          const user = jsonObj[key]?.message?.author?.role;
          const message = jsonObj[key]?.message?.content?.parts;
          chatHistory.push({ user: user, message: message });
        }
      }
      setResponses(chatHistory);
      setScrollActive(true);
      setIsLoadingChat(false);
    } catch (err) {
      console.log("error:", err);
      setIsLoadingChat(false);
      setResponses([
        {
          user: "chatbot",
          message: "Network error! Please refresh the page and try again.",
        },
      ]);
    }
  };

  return {
    handleSubmit,
    handleStopGenerate,
    getDefaultContent,
    getConversation,
  };
}
