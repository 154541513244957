import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { Base_Url } from "../Utils/urls";
import { AuthContext } from "./AuthContext";

export const TourContext = createContext();

// var isFirstUser = JSON.parse(localStorage.getItem("isFirstUser")) ?? false;
var tourFromSmView =
  JSON.parse(localStorage.getItem("tourFromSmView")) ?? false;
var tourFromExView =
  JSON.parse(localStorage.getItem("tourFromExView")) ?? false;

// Initialize state to true if either value1 or value2 is true, otherwise false
var initialState = tourFromSmView || tourFromExView;
export const TourContextProvider = ({ children }) => {
  const { user, getToken } = useContext(AuthContext);

  //Targets for Small view
  const smallTourRef1 = useRef(null);
  const smallTourRef2 = useRef(null);
  const smallTourRef3 = useRef(null);
  const smallTourRef4 = useRef(null);
  const smallTourRef5 = useRef(null);
  const smallTourRef6 = useRef(null);
  const smallTourRef7 = useRef(null);
  const smallTourRef8 = useRef(null);
  const smallTourRef9 = useRef(null);
  const smallTourRef10 = useRef(null);
  const smallTourRef11 = useRef(null);
  const smallTourRef12 = useRef(null);
  const smallTourRef13 = useRef(null);

  //Targets for Expanded view
  const expTourRef1 = useRef(null);
  const expTourRef2 = useRef(null);
  const expTourRef3 = useRef(null);
  const expTourRef4 = useRef(null);
  const expTourRef5 = useRef(null);
  const expTourRef6 = useRef(null);
  const expTourRef7 = useRef(null);
  const expTourRef8 = useRef(null);
  const expTourRef9 = useRef(null);
  const expTourRef10 = useRef(null);
  const expTourRef11 = useRef(null);
  const expTourRef12 = useRef(null);
  const expTourRef13 = useRef(null);
  const expTourRef14 = useRef(null);
  const expTourRef15 = useRef(null);
  const expTourRef16 = useRef(null);
  const expTourRef17 = useRef(null);
  const expTourRef18 = useRef(null);
  const expTourRef19 = useRef(null);

  const welcomeMsgRef = useRef(false);

  const checkFirstTimeUser = async () => {
    try {
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url}/health`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      response = await response.json();
      if (!response?.is_tour_enabled_smallview) {
        welcomeMsgRef.current = true;
        setTourOpen(true);
      }
    } catch (err) {
      console.log("error:", err);
    }
  };

  useEffect(() => {
    if (user && !localStorage.getItem("isFirstTourUser")) {
      checkFirstTimeUser();
    }
  }, [user]);

  const handleNext = () => {
    // Increment the step count
    setCurrentStep(currentStep + 1);

    // If we're transitioning to the 2nd step, and there's an action defined for it, trigger the action
    if (currentView === 0 && smallViewSteps[currentStep + 1]?.action) {
      smallViewSteps[currentStep + 1].action();
    } else if (
      currentView === 1 &&
      expandedViewSteps[currentStep + 1]?.action
    ) {
      expandedViewSteps[currentStep + 1].action();
    } else if (
      currentView === 2 &&
      expandedViewSteps2[currentStep + 1]?.action
    ) {
      expandedViewSteps2[currentStep + 1].action();
    }
  };

  const handlePrev = () => {
    // Increment the step count
    setCurrentStep(currentStep - 1);

    // If we're transitioning to the 2nd step, and there's an action defined for it, trigger the action
    if (currentView === 0 && smallViewSteps[currentStep - 1]?.action) {
      smallViewSteps[currentStep - 1].action();
    } else if (
      currentView === 1 &&
      expandedViewSteps[currentStep - 1]?.action
    ) {
      expandedViewSteps[currentStep - 1].action();
    } else if (
      currentView === 2 &&
      expandedViewSteps2[currentStep - 1]?.action
    ) {
      expandedViewSteps2[currentStep - 1].action();
    }
  };

  const handleNavigateTour = () => {
    localStorage.setItem("tourFromSmView", true);
    localStorage.setItem("Toggle", 1);
    if (smallTourRef10.current) {
      smallTourRef10.current.click();
    }
  };

  const handleNavigateTourPrev = () => {
    localStorage.setItem("tourFromExView", true);
    localStorage.setItem("Toggle", 2);
    if (expTourRef5.current) {
      expTourRef5.current.click();
    }
  };

  const welcomeTitle = (
    <div>
      <span style={{ fontWeight: "600" }}>
        Welcome to Super Tutor&nbsp;&nbsp;
      </span>
      <img src="/assets/supertutor-icon2.svg" alt="" className="tour-msg-img" />
      <div style={{ marginTop: "8px" }}>
        {welcomeMsgRef.current
          ? "Let's have a quick tour."
          : "Let's start the tour"}{" "}
        Click Next to continue.
      </div>
    </div>
  );

  const buttonProps = {
    nextButtonProps: {
      children: "Next",
      onClick: handleNext,
    },
    prevButtonProps: {
      children: "Previous",
      onClick: handlePrev,
    },
  };

  const smallViewSteps = [
    {
      description: welcomeTitle,
      target: null,
      ...buttonProps,
    },
    {
      description: "Click here to ask Super Tutor any queries about Gen AI.",
      placement: "bottomLeft",
      target: () => smallTourRef1.current,
      action: () => {
        if (smallTourRef1.current) {
          smallTourRef1.current.click();
        }
      },
      ...buttonProps,
    },
    {
      description:
        "Discover personalized questions curated for you. Click on each to receive answers.",
      placement: "bottomLeft",
      target: () => smallTourRef4.current,
      ...buttonProps,
    },
    {
      description: "Type your GenAI queries here.",
      placement: "topLeft",
      target: () => smallTourRef5.current,
      action: () => {
        if (smallTourRef1.current) {
          smallTourRef1.current.click();
        }
      },
      ...buttonProps,
    },
    {
      description: "Stop response generation using this button.",
      placement: "topRight",
      target: () => smallTourRef13.current,
      ...buttonProps,
    },
    {
      description:
        "Click here to identify trainings based on your learning profile.",
      placement: "bottomRight",
      target: () => smallTourRef2.current,
      action: () => {
        if (smallTourRef2.current) {
          smallTourRef2.current.click();
        }
      },
      ...buttonProps,
    },
    {
      description: "Discover personalized resources curated for you.",
      placement: "bottomLeft",
      target: () => smallTourRef6.current,
      ...buttonProps,
    },
    {
      description: "Use the filters to look for specific resources.",
      placement: "topLeft",
      target: () => smallTourRef7.current,
      ...buttonProps,
    },
    {
      description:
        "Select the required values for the filters. Click 'All Resources' to see all the available resources.",
      placement: "topLeft",
      target: () => smallTourRef12.current,
      ...buttonProps,
    },
    {
      description: "Or share your preferences using the chat field.",
      placement: "topRight",
      target: () => smallTourRef5.current,
      ...buttonProps,
    },
    {
      description:
        "Get personalized learning recommendations by answering reflective questions.",
      placement: "topRight",
      target: () => smallTourRef8.current,
      action: () => {
        if (smallTourRef2.current) {
          smallTourRef2.current.click();
        }
      },
      ...buttonProps,
    },
    // {
    //   description: "Take reflective assessment!",
    //   placement: "topRight",
    //   target: () => smallTourRef9.current,
    //   nextButtonProps: {
    //     children: "Next",
    //     onClick: handleNext,
    //   },
    //   prevButtonProps: {
    //     children: "Previous",
    //     onClick: handlePrev,
    //   },
    // },
    {
      description: "Share your experience and feedback for Super Tutor.",
      target: () => smallTourRef3.current,
      ...buttonProps,
    },
    {
      description: "Click here to close Super Tutor.",
      // placement: "bottomRight",
      target: () => smallTourRef11.current,
      ...buttonProps,
    },
    {
      description: "Click here to navigate to the maximized view.",
      // placement: "bottomRight",
      target: () => smallTourRef10.current,
      nextButtonProps: {
        children: "Next",
        onClick: handleNavigateTour,
      },
      prevButtonProps: {
        children: "Previous",
        onClick: handlePrev,
      },
    },
  ];

  const expandedViewSteps = [
    {
      description: "Dummy step.",
      placement: "topLeft",
      target: null,
      ...buttonProps,
    },
    {
      description:
        "Find the source of information here. Click on the link to view it.",
      placement: "topLeft",
      target: () => expTourRef1.current,
      nextButtonProps: {
        children: "Next",
        onClick: handleNext,
      },
      prevButtonProps: {
        children: "Previous",
        onClick: handleNavigateTourPrev,
      },
    },
    {
      description: "The source documents appear here.",
      placement: "right",
      target: () => expTourRef2.current,
      ...buttonProps,
    },
    {
      description: "Find a summary of each slide.",
      placement: "topLeft",
      target: () => expTourRef3.current,
      ...buttonProps,
    },
    {
      description:
        "Find questions suggested for each slide. Click on each to get answers.",
      placement: "topLeft",
      target: () => expTourRef4.current,
      action: () => {
        if (expTourRef8.current) {
          expTourRef8.current.click();
        }
      },
      ...buttonProps,
    },
    {
      description:
        "Navigate to resources based on your earlier preferences by clicking on the corresponding segment.",
      placement: "topLeft",
      target: () => expTourRef7.current,
      action: () => {
        if (expTourRef6.current) {
          expTourRef6.current.click();
        }
      },
      ...buttonProps,
    },
    {
      description:
        "Click on this button and use the filter/ chat field to modify your earlier preferences.",
      placement: "topLeft",
      target: () => expTourRef17.current,
      ...buttonProps,
    },
    {
      description: "Click here to navigate to the compact view.",
      placement: "bottomRight",
      target: () => expTourRef5.current,
      ...buttonProps,
    },
    {
      description: "Click here to take the tour again.",
      target: () => expTourRef16.current,
      prevButtonProps: {
        children: "Previous",
        onClick: handlePrev,
      },
    },
  ];

  const expandedViewSteps2 = [
    {
      description: welcomeTitle,
      target: null,
      ...buttonProps,
    },
    {
      description: "Click here to ask Super Tutor any queries about Gen AI.",
      placement: "bottomLeft",
      target: () => expTourRef8.current,
      action: () => {
        if (expTourRef8.current) {
          expTourRef8.current.click();
        }
      },
      ...buttonProps,
    },
    {
      description:
        "Discover personalized questions curated for you. Click on each to receive answers.",
      placement: "bottomLeft",
      target: () => expTourRef9.current,
      ...buttonProps,
    },
    {
      description: "Type your GenAI queries here.",
      placement: "topLeft",
      target: () => expTourRef10.current,
      action: () => {
        if (smallTourRef1.current) {
          smallTourRef1.current.click();
        }
      },
      ...buttonProps,
    },
    {
      description: "Stop response generation using this button.",
      placement: "topRight",
      target: () => expTourRef19.current,
      ...buttonProps,
    },
    {
      description:
        "Find the source of information here. Click on the link to view it.",
      placement: "topLeft",
      target: () => expTourRef1.current,
      ...buttonProps,
    },
    ...expandedViewSteps.slice(2, 5),
    {
      description:
        "Click here to identify trainings based on your learning profile.",
      placement: "bottomRight",
      target: () => expTourRef6.current,
      action: () => {
        if (expTourRef6.current) {
          expTourRef6.current.click();
        }
      },
      ...buttonProps,
    },
    {
      description: "Discover personalized resources curated for you.",
      placement: "right",
      target: () => expTourRef11.current,
      ...buttonProps,
    },
    {
      description: "Use the filters to look for specific resources.",
      placement: "topLeft",
      target: () => expTourRef12.current,
      ...buttonProps,
    },
    {
      description:
        "Select the required values for the filters. Click 'All Resources' to see all the available resources.",
      placement: "topLeft",
      target: () => expTourRef18.current,
      ...buttonProps,
    },
    {
      description: "Or share your preferences using the chat field.",
      placement: "topRight",
      target: () => expTourRef10.current,
      ...buttonProps,
    },
    ...expandedViewSteps.slice(5, 7),
    {
      description:
        "Get personalized learning recommendations by answering reflective questions.",
      placement: "topRight",
      target: () => expTourRef14.current,
      ...buttonProps,
    },
    {
      description: "Share your experience and feedback for Super Tutor.",
      target: () => expTourRef15.current,
      ...buttonProps,
    },
    ...expandedViewSteps.slice(expandedViewSteps.length - 2),
  ];

  const [currentStep, setCurrentStep] = useState(
    tourFromSmView ? 1 : tourFromExView ? smallViewSteps.length - 1 : 0
  );
  const [tourOpen, setTourOpen] = useState(initialState);
  const [currentView, setCurrentView] = useState(0); // 0 refers to small view, 1 refers to expanded view

  const authValues = {
    welcomeMsgRef,
    smallTourRef1,
    smallTourRef2,
    smallTourRef3,
    smallTourRef4,
    smallTourRef5,
    smallTourRef6,
    smallTourRef7,
    smallTourRef8,
    smallTourRef9,
    smallTourRef10,
    smallTourRef11,
    smallTourRef12,
    smallTourRef13,
    expTourRef1,
    expTourRef2,
    expTourRef3,
    expTourRef4,
    expTourRef5,
    expTourRef6,
    expTourRef7,
    expTourRef8,
    expTourRef9,
    expTourRef10,
    expTourRef11,
    expTourRef12,
    expTourRef13,
    expTourRef14,
    expTourRef15,
    expTourRef16,
    expTourRef17,
    expTourRef18,
    expTourRef19,
    currentStep,
    setCurrentStep,
    tourOpen,
    setTourOpen,
    handleNext,
    handlePrev,
    smallViewSteps,
    expandedViewSteps,
    expandedViewSteps2,
    currentView,
    setCurrentView,
  };
  return (
    <TourContext.Provider value={authValues}>{children}</TourContext.Provider>
  );
};
