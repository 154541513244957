import React, { useContext, useEffect, useRef } from "react";
import "./TrainingInputSection.css";
import ChatInput from "../ChatInput/ChatInput";
import FilterRow from "../FilterRow/FilterRow";
import { Spin, Tooltip } from "antd";
import TrainingCard from "../TrainingCard/TrainingCard";
import { training_history_dummy2 } from "../../../../constants/GlobalConstants";
import { TourContext } from "../../../../Context/TourContext";
import { AppStateContext } from "../../../../Context/AppStateContext";
import { TrainingsContext } from "../../../../Context/TrainingsContext";
import { useRecommendedTrainings } from "../../../../Hooks/useRecommendedTrainings";
export default function TrainingInputSection() {
  const { tourOpen, expTourRef14 } = useContext(TourContext);

  const { setToggleTab } = useContext(AppStateContext);

  const {
    retainContext,
    isLoadingRecTrainings,
    isLoadingFilterTrainings,
    isLoadingTrainingHistory,
    disabledTraining,
    trainingHistory,
    reflectTabOpen,
    setReflectTabOpen,
    scrollNearBottom,
    setScrollNearBottom,
    currentSelectedTraining,
  } = useContext(TrainingsContext);

  const { handleMessage } = useRecommendedTrainings();

  const scrollingDivRef = useRef(null);
  const shouldAutoScrollRef = useRef(false);
  const reflectiveTextRef = useRef(null);

  const scrollToBottom = () => {
    const divElement = scrollingDivRef.current;
    if (divElement) {
      const scrollHeight = divElement.scrollHeight;
      const clientHeight = divElement.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      divElement.scrollTo({
        top: maxScrollTop,
        behavior: "smooth",
      });
    }
    setScrollNearBottom(false);
  };

  useEffect(() => {
    let height = 0;
    if (reflectiveTextRef.current) {
      height = reflectiveTextRef.current.clientHeight;
    }
    if (scrollingDivRef.current) {
      scrollingDivRef.current.style.paddingBottom = `${height + 35}px`;
    }
  }, [reflectTabOpen]);

  useEffect(() => {
    if (!tourOpen) scrollToBottom();
  }, [retainContext, tourOpen]);

  useEffect(() => {
    if (shouldAutoScrollRef.current && scrollingDivRef.current) {
      scrollToBottom();
    }
  }, [trainingHistory]);

  const handleLittleScroll = () => {
    const scrollableDiv = scrollingDivRef.current;
    if (scrollableDiv) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;
      if (scrollHeight - scrollTop < clientHeight + 150) {
        shouldAutoScrollRef.current = true;
      } else {
        shouldAutoScrollRef.current = false;
      }
    }
  };

  useEffect(() => {
    if (scrollNearBottom) {
      scrollToBottom();
    }
  }, [scrollNearBottom]);

  let isLoading =
    !tourOpen && (isLoadingRecTrainings || isLoadingTrainingHistory);
  let trainingHistoryVisible = tourOpen
    ? training_history_dummy2
    : trainingHistory;

  return (
    <div className="training-right-section">
      <div
        className="training-conv-box"
        ref={scrollingDivRef}
        onScroll={handleLittleScroll}
        style={tourOpen ? { flexDirection: "column-reverse" } : {}}
      >
        {isLoading ? (
          <div className="training-right-spinner">
            <Spin />
          </div>
        ) : (
          <div className="trainings-container-expanded">
            {trainingHistoryVisible.length > 0 &&
              !trainingHistoryVisible[0].hasOwnProperty("msg") && (
                <div className="training-message">
                  Use the filters or type in the text field at the bottom to
                  share your preferences.
                  <br />
                  <br />
                  Click subsequent conversation blocks to see past
                  recommendations.
                </div>
              )}
            {trainingHistoryVisible?.map((item, i) => {
              if (item.user === "chatbot") {
                if (item.hasOwnProperty("msg")) {
                  return <div className="training-message">{item?.msg}</div>;
                } else if (item.hasOwnProperty("readOnlyText")) {
                  return (
                    <div
                      className="training-message"
                      dangerouslySetInnerHTML={{ __html: item?.readOnlyText }}
                    ></div>
                  );
                } else {
                  return (
                    <TrainingCard
                      data={item.hasOwnProperty("data") ? item?.data : null}
                      table_heading={
                        i === 0
                          ? "Tailored to your learning journey, here are some resources recommended for you."
                          : item.hasOwnProperty("isReflectiveAssessment")
                          ? "Here are the resources personalized for you based on your responses to the reflective questions."
                          : item?.message?.length === 0
                          ? "Here are the resources that match your preferences."
                          : item?.message
                      }
                      retainContext={
                        i !== trainingHistoryVisible.length - 1
                          ? item?.modifySearchStatus
                          : retainContext
                      }
                      modSearchButtonVisible={
                        i !== 0 && !item.invalid_context ? true : false
                      }
                      modSearchButtonDisabled={
                        i !== trainingHistoryVisible.length - 1
                      }
                      trainingKey={item?.key}
                      cardSelected={
                        item?.key ===
                        (tourOpen
                          ? training_history_dummy2[2]?.key
                          : currentSelectedTraining)
                      }
                      index={i}
                    />
                  );
                }
              } else {
                if (item.hasOwnProperty("filters")) {
                  return (
                    <div
                      style={{ justifyContent: "end" }}
                      className="training-message-wrapper"
                    >
                      <div
                        className="training-message"
                        style={{ background: "#B1D177" }}
                      >
                        Filters:&nbsp;
                        {Object.entries(item?.filters)
                          .filter(([key, value]) => value.length !== 0)
                          .map(([key, value], i) => {
                            return (
                              <span>
                                {i !== 0 && ", "}
                                <Tooltip
                                  overlayClassName="selected-filters-hover"
                                  title={
                                    <div className="selected-filters-hover-text">
                                      <div className="selected-filters-hover-key">
                                        {key}:{" "}
                                      </div>
                                      <div className="selected-filters-hover-value">
                                        {value
                                          .map((filter) => filter?.value)
                                          .join(", ")}
                                      </div>
                                    </div>
                                  }
                                  color="#444444"
                                >
                                  <span className="entered-filter-text">
                                    {key}&nbsp;
                                  </span>
                                  ({value.length})
                                </Tooltip>
                              </span>
                            );
                          })}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{ justifyContent: "end" }}
                      className="training-message-wrapper"
                    >
                      <div
                        className="training-message"
                        style={{ background: "#B1D177" }}
                      >
                        {item?.message}
                      </div>
                    </div>
                  );
                }
              }
            })}
            {!isLoadingFilterTrainings &&
              (retainContext ? (
                <div className="training-message" style={{ color: "#056B39" }}>
                  Use the filters or chat field to refine the preferences.
                </div>
              ) : null)}
          </div>
        )}
      </div>
      <div className="filter-input-box">
        {(tourOpen || !isLoadingRecTrainings) && (
          <div className="reflective-ques-bottom" ref={expTourRef14}>
            <div
              className="reflective-ques-tab"
              onClick={() => {
                setReflectTabOpen(!reflectTabOpen);
                handleLittleScroll();
                if (shouldAutoScrollRef) setScrollNearBottom(true);
              }}
            >
              <div className="reflective-icon">
                <img src="./assets/reflective.svg" alt="" />
              </div>
              <div>Personalize Your Recommendations</div>
              <div className="reflective-open-icon">
                {reflectTabOpen ? (
                  <img src="./assets/openDark.svg" alt="" />
                ) : (
                  <img src="./assets/closeDark.svg" alt="" />
                )}
              </div>
            </div>
            {reflectTabOpen && (
              <div
                className="reflective-ques-instructions"
                ref={reflectiveTextRef}
              >
                <div className="reflective-ques-instructions-text">
                  Answer reflective questions about your current skills and
                  needs to get personalized learning recommendations. Click
                  Begin to proceed.
                </div>
                <div
                  className="reflective-button"
                  onClick={() => {
                    setToggleTab(4);
                  }}
                >
                  Begin
                </div>
              </div>
            )}
          </div>
        )}
        <FilterRow />
        <ChatInput
          handleSubmit={handleMessage}
          disabledInput={isLoadingTrainingHistory}
          disabled={disabledTraining}
        />
      </div>
    </div>
  );
}
