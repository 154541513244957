import React, { useContext } from "react";
import "./TrainingTable.css";
import { TourContext } from "../../../../Context/TourContext";
import { FilterContext } from "../../../../Context/FilterContext";
import { useAppUtilities } from "../../../../Hooks/useAppUtilities";

export default function TrainingTable({ data }) {
  const { expTourRef11 } = useContext(TourContext);

  const { filterIdMapping } = useContext(FilterContext);

  const { user_interaction } = useAppUtilities();

  let trainingTypeKey = "Training Type",
    proficiencyKey = "Proficiency",
    regionKey = "Region";

  Object.entries(filterIdMapping).map(([key, value], index) => {
    if (value.hasOwnProperty("isTrainingType")) {
      trainingTypeKey = key;
    } else if (value.hasOwnProperty("isProficiency")) {
      proficiencyKey = key;
    } else if (value.hasOwnProperty("isRegion")) {
      regionKey = key;
    }
  });

  let trainingOtherInfo = data?.map((item, index) => {
    let trainingOtherInfoTemp = [];
    if (item?.duration.trim().length !== 0) {
      trainingOtherInfoTemp.push(item?.duration);
    }
    if (item?.filters[trainingTypeKey].length > 0) {
      trainingOtherInfoTemp.push(
        item?.filters[trainingTypeKey]?.map((obj) => obj.value).join(", ")
      );
    }
    if (item?.filters[proficiencyKey].length > 0) {
      trainingOtherInfoTemp.push(
        item?.filters[proficiencyKey]?.map((obj) => obj.value).join(", ")
      );
    }
    if (item?.filters[regionKey].length > 0) {
      trainingOtherInfoTemp.push(
        item?.filters[regionKey]?.map((obj) => obj.value).join(", ")
      );
    }
    return trainingOtherInfoTemp;
  });

  return (
    <div className="training-list-expanded" ref={expTourRef11}>
      {data.map((item, index) => (
        <div className="training-list-items-expanded">
          <a
            href={item?.link}
            target="_blank"
            onClick={() => user_interaction(item?.id, {})}
          >
            <span className="training-item-link-expanded">{item?.name}</span>
          </a>
          <div className="training-item-description">{item?.description}</div>
          <div className="training-item-other-info-expanded">
            {trainingOtherInfo[index]?.map((info, i) => (
              <>
                <div>{info}</div>
                {i !== trainingOtherInfo[index].length - 1 && (
                  <div className="training-items-vertical-separator-expanded"></div>
                )}
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
