import React, { useContext, useRef, useState } from "react";
import "./AddScenario.css";
import {
  containsAlphabetsOrNumbers,
  dummy_generated_options,
  generated_options,
  generated_scenario_questions,
} from "../../../../constants/GlobalConstants";
import { AuthContext } from "../../../../Context/AuthContext";
import { Base_Url_Admin } from "../../../../Utils/urls";

export default function AddScenario({ addScenario, setAddScenario }) {
  const dummy_template = {
    text: "",
    show: false,
    arearef: useRef(null),
  };
  const textareaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    scenario: dummy_template,
    questions: dummy_template,
    options: dummy_template,
    preview: dummy_template,
  });
  const [context, setContext] = useState("");
  const { getToken } = useContext(AuthContext);
  const [options, setOptions] = useState([]);
  const handleChange = (e) => {
    setContext(e.target.value);
  };

  const contextRef = useRef(null);
  const autoExpandTextarea = (Ref, event) => {
    const textarea = event ? event.target : Ref.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };
  const handleChangeText = (e, value) => {
    setFormData({
      ...formData,
      [value]: { ...formData[value], text: e.target.value },
    });
    // autoExpandTextarea(formData[value].arearef);
  };
  const generate_scenarios = async () => {
    if (!containsAlphabetsOrNumbers(context)) return;
    if (isLoading) return;
    else setIsLoading(true);
    const payload = { context: context };
    let accessToken = await getToken();
    let response = await fetch(`${Base_Url_Admin}/generate_scenario`, {
      method: "POST",
      headers: {
        "X-Api-Token": accessToken,
      },
      body: JSON.stringify(payload),
    });
    let result = await response.json();
    setFormData({
      ...formData,
      scenario: {
        ...formData.scenario,
        text: result?.scenario,
        show: true,
      },
      questions: {
        ...formData.questions,
        text: result?.questions.slice(0, 2).join("\n"),
      },
    });
    setIsLoading(false);
  };
  const generateOptions = async () => {
    if (
      !containsAlphabetsOrNumbers(formData.scenario.text) ||
      !containsAlphabetsOrNumbers(formData.questions.text)
    )
      return;
    if (isLoading) return;
    else setIsLoading(true);
    const payload = {
      scenario: formData.scenario.text,
      questions: formData.questions.text.split("\n").slice(0, 2),
    };
    let accessToken = await getToken();
    let response = await fetch(`${Base_Url_Admin}/generate_scenario_answers`, {
      method: "POST",
      headers: {
        "X-Api-Token": accessToken,
      },
      body: JSON.stringify(payload),
    });
    let result1 = await response.json();
    if (response.status !== 200) return;
    let result = Object.keys(result1.answers).map(
      (item) => dummy_generated_options[item]
    );
    result = result.map(
      (item) =>
        // let c =
        item.text +
        "\n\n" +
        "A: " +
        item.options["A"] +
        "\nB: " +
        item.options["B"] +
        "\nC: " +
        item.options["C"] +
        "\nD: " +
        item.options["D"] +
        "\nAnswer: " +
        item.answer +
        "\n\nExplanation: " +
        item.explanation.split(". ").join("\n")
    );
    console.log("result:", result, result1);
    setOptions(result);
    setFormData({
      ...formData,
      options: {
        ...formData.options,
        show: true,
      },
    });
    setIsLoading(false);
    return;
  };
  const handleChangeOptionText = (e, index) => {
    let current_options = [...options];
    current_options[index] = e.target.value;
    setOptions(current_options);
  };
  return (
    <>
      {/* {contextHolder} */}
      <div className="description-box-self-ass mt-8">
        <div className="self-ass-category-heading">
          Enter scenario details
          {/* <SelfAssessTooltip heading="Guidelines:" value={category_guidelines} /> */}
        </div>
      </div>

      <form>
        <div className="show-boxes-each">
          <div className="box-details-1">
            <span className="center-align">Add context</span>
            <div className="box-details-input-save">
              {/* <input
                className="input-box-form width-80"
                value={context}
                onChange={handleChange}
              /> */}
              <textarea
                ref={contextRef}
                onInput={(e) => {
                  autoExpandTextarea(contextRef, e);
                }}
                value={context}
                onChange={handleChange}
                className="dummy-textarea width-80 mh-2"
              />
              <div
                className="add-new-button whit-wrap"
                onClick={generate_scenarios}
              >
                Generate Scenario
              </div>
            </div>
          </div>
          {formData.scenario.show && (
            <>
              <div className="box-details-1">
                <span className="center-align">Scenario generated</span>
                <div className="box-details-input-save">
                  <textarea
                    value={formData.scenario.text}
                    onChange={(e) => {
                      handleChangeText(e, "scenario");
                    }}
                    className="dummy-textarea width-80"
                    // ref={formData?.scenario.arearef}
                    // ref={scenarioRef}
                    // onInput={(e) => {
                    //   autoExpandTextarea(formData?.scenario.arearef, e);
                    // }}
                    // onInput={autoExpandTextarea}
                  />
                  <div
                    className="add-new-button whit-wrap"
                    // onClick={() => {
                    //   setFormData({ ...formData, questions: true });
                    // }}
                    onClick={generateOptions}
                  >
                    Generate Questions
                  </div>
                </div>
              </div>
              {/* {!formData.options.show && (
                <div className="box-details-1">
                  <span className="center-align">Questions generated</span>
                  <div className="box-details-input-save">
                    <textarea
                      value={formData.questions.text}
                      onChange={(e) => {
                        handleChangeText(e, "questions");
                      }}
                      className="dummy-textarea width-80"
                    />
                    <div
                      className="add-new-button whit-wrap"
                      onClick={generateOptions}
                    >
                      Generate Options
                    </div>
                  </div>
                </div>
              )} */}
            </>
          )}
          {/* {formData.questions && (
            <div className="box-details-1">
              <span className="center-align">Questions generated</span>
              <div className="box-details-input-save">
                <input className="input-box-form width-80" />
                <div
                  className="add-new-button whit-wrap"
                  onClick={() => {
                    setFormData({ ...formData, options: true });
                  }}
                >
                  Generate Options
                </div>
              </div>
            </div>
          )} */}
          {formData.options.show && (
            <>
              <div className="box-details-1">
                <span className="center-align">
                  Questions, Options and Explanations generated
                </span>
                {/* <div className="box-details-input-save"> */}
                {options.length !== 0 &&
                  options.map((item, index) => {
                    return (
                      <textarea
                        value={item}
                        onChange={(e) => {
                          handleChangeOptionText(e, index);
                        }}
                        className="dummy-textarea width-80"
                      />
                    );
                  })}
                {/* </div> */}
              </div>
              <div className="box-details-1">
                {/* <span className="center-align">Scenario Preview</span> */}
                <div className="box-details-input-save">
                  {/* <input className="input-box-form width-80" /> */}
                  <span> </span>
                  <div className="add-category-button whit-wrap">Save</div>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
}
