import React, { useContext, useEffect, useState } from "react";
import "./QuesGenAI.css";
import Trainings from "./Trainings/Trainings";

import { Modal, Spin, message } from "antd";
import UploadContent from "../../../Components/UploadContent/UploadContent";
import { delete_text, token } from "../../../../../constants/GlobalConstants";
import { Base_Url_Admin } from "../../../../../Utils/urls";
import { AuthContext } from "../../../../../Context/AuthContext";
import DeleteModal from "../../../Components/DeleteModal/DeleteModal";

export default function QuesGenAI() {
  // const [isViewSection, setViewSection] = useState(false);
  // const [showContent, setShowContent] = useState("");
  // const [isEditContent, setEditContent] = useState(false);
  const [contents, setContents] = useState([]);
  const [deleteContent, setDeleteContent] = useState("");
  const [page, setPage] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    user,
    getToken,
    isViewSection,
    setViewSection,
    showContent,
    setShowContent,
    isEditContent,
    setEditContent,
  } = useContext(AuthContext);
  const handleDeleteContent = async () => {
    try {
      const params = new URLSearchParams();
      params.append("id", deleteContent);
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/content_details?${params}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + token,
            "X-Api-Token": accessToken,
            // Authorization: "Bearer " + deloitteChatToken,
          },
        }
      );
      messageApi.open({
        type: "success",
        content: "Content successfully deleted!",
      });
      setDeleteContent("");
      if (page !== 1) setPage(1);
      getContents(1);
      setViewSection(false);
      return;
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const getContents = async (value) => {
    // if (trainings?.data) setTrainings({});
    try {
      const params = new URLSearchParams();
      params.append("page", value ?? page);
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/content_list?${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
      });
      let content = await response.json();
      setContents(content);
    } catch (e) {
      console.log("Error:", e);
    }
  };
  useEffect(() => {
    getContents();
  }, [page]);
  return (
    <div className="sub-page">
      {contextHolder}
      <div className="heading-tab-pad">
        {isViewSection ? (
          <div className="heading-tab">
            <span
              className="sub-heading"
              onClick={() => {
                setViewSection(false);
                // setPage(1);
                setEditContent(false);
                setShowContent("");
              }}
            >
              Conversations
            </span>
            <span className="subbed-heading">
              &nbsp;
              {" > "}&nbsp;
              {showContent.length !== 0 ? "Content Details" : "Add Content"}
            </span>
          </div>
        ) : (
          <span className="content-heading-main">Conversations</span>
        )}
      </div>

      {isViewSection ? (
        <UploadContent
          showContent={showContent}
          isEditContent={isEditContent}
          setEditContent={setEditContent}
          isViewSection={isViewSection}
          setViewSection={setViewSection}
          setDeleteContent={setDeleteContent}
          getContents={getContents}
          setShowContent={setShowContent}
          page={page}
        />
      ) : contents.length === 0 ? (
        <Spin />
      ) : (
        <>
          <span className="sub-content">
            Manage the content repository used by Super Tutor to generate
            responses through this section.
          </span>
          <Trainings
            setShowUploadSection={setViewSection}
            SubmitTitle="Add Content"
            heading="Content List"
            trainings={contents}
            page={page}
            setPage={setPage}
            setEditTraining={setEditContent}
            setShowTraining={setShowContent}
            setDeleteTraining={setDeleteContent}
            Type=""
          />
        </>
      )}
      <DeleteModal
        deleteTaining={deleteContent}
        setDeleteTraining={setDeleteContent}
        content={delete_text + " content ?"}
        func={handleDeleteContent}
      />
    </div>
  );
}
