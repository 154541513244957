import { createContext, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const TrainingsContext = createContext();

export const TrainingsContextProvider = ({ children }) => {
  const [retainContext, setRetainContext] = useState(false);
  const [isLoadingRecTrainings, setIsLoadingRecTrainings] = useState(false);
  const [isLoadingFilterTrainings, setIsLoadingFilterTrainings] =
    useState(false);
  const [isLoadingTrainingHistory, setIsLoadingTrainingHistory] =
    useState(true);
  const [disabledTraining, setDisabledTraining] = useState(false);
  const [filteredTrainings, setFilteredTrainings] = useState([]);
  const [recTrainings, setRecTrainings] = useState([]);
  const [trainingHistory, setTrainingHistory] = useState([]);
  const trainingHistId = useRef(
    localStorage.getItem("TrainingHistId") ?? uuidv4()
  );
  localStorage.setItem("TrainingHistId", trainingHistId.current);
  const [reflectTabOpen, setReflectTabOpen] = useState(true);
  const [scrollNearBottom, setScrollNearBottom] = useState(false);

  //Specific to expanded view
  const [currentTrainings, setCurrentTrainings] = useState(null);
  const [currentSelectedTraining, setCurrentSelectedTraining] = useState(
    localStorage.getItem("trainingkey") ?? null
  );
  const [showCurrentTrainings, setShowCurrentTrainings] = useState(false);

  const trainingValues = {
    retainContext,
    setRetainContext,
    isLoadingRecTrainings,
    setIsLoadingRecTrainings,
    isLoadingFilterTrainings,
    setIsLoadingFilterTrainings,
    isLoadingTrainingHistory,
    setIsLoadingTrainingHistory,
    disabledTraining,
    setDisabledTraining,
    filteredTrainings,
    setFilteredTrainings,
    recTrainings,
    setRecTrainings,
    trainingHistory,
    setTrainingHistory,
    trainingHistId,
    reflectTabOpen,
    setReflectTabOpen,
    scrollNearBottom,
    setScrollNearBottom,
    currentTrainings,
    setCurrentTrainings,
    currentSelectedTraining,
    setCurrentSelectedTraining,
    showCurrentTrainings,
    setShowCurrentTrainings,
  };

  return (
    <TrainingsContext.Provider value={trainingValues}>
      {children}
    </TrainingsContext.Provider>
  );
};
