import React, { useContext } from "react";
import "./TrainingCard.css";
import { TourContext } from "../../../../Context/TourContext";
import { TrainingsContext } from "../../../../Context/TrainingsContext";

export default function TrainingCard({
  data,
  table_heading,
  retainContext,
  modSearchButtonVisible,
  modSearchButtonDisabled,
  trainingKey,
  cardSelected,
  index,
}) {
  const { tourOpen, currentStep, currentView, expTourRef7, expTourRef17 } =
    useContext(TourContext);

  const {
    setRetainContext,
    setCurrentTrainings,
    setCurrentSelectedTraining,
    setShowCurrentTrainings,
  } = useContext(TrainingsContext);

  const handleCardClick = () => {
    if (!data) return;
    setShowCurrentTrainings(true);
    setCurrentTrainings(data);
    setCurrentSelectedTraining(trainingKey);
    localStorage.setItem("trainingkey", trainingKey);
  };

  let tourContextVisible = tourOpen
    ? currentView === 1
      ? currentStep === 6
      : currentStep === 15
    : false;

  return (
    <>
      <div
        className={`training-card-container ${
          data && cardSelected
            ? "unselected-training-card-container selected-training-card-container"
            : data
            ? "unselected-training-card-container"
            : ""
        }`}
        onClick={handleCardClick}
        style={
          data && data.length !== 0
            ? { cursor: "pointer" }
            : { cursor: "default" }
        }
        ref={index === 2 ? expTourRef7 : null}
      >
        <div className="training-card-heading">{table_heading}</div>
        {modSearchButtonVisible && (
          <div
            className={
              (tourContextVisible || retainContext
                ? "retain-context retain-context-selected"
                : "retain-context") +
              (modSearchButtonDisabled ? " mod-search-disabled" : "")
            }
            onClick={() => {
              setRetainContext(!retainContext);
            }}
            ref={expTourRef17}
          >
            Click here to refine the current preferences
          </div>
        )}
      </div>
    </>
  );
}
